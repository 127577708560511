import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";

import Logo from "../../../../asset/GoUni Logo.jpg";
import {
  bioDataTableStyles,
  documentStyles,
  headerStyles,
  tableStyles,
} from "./styles";

//Renderer for PDF format of the Transcript
export const TranscriptDocument = ({ transcriptPreview, requestPreview }) => {
  const [levels, setLevels] = useState([]);

  useEffect(() => {
    //get number of levels available to the selected request
    const levels = transcriptPreview?.courseRegistrations?.map(
      (item) => item.levelId
    );
    const uniqueLevels = [...new Set(levels)].sort();
    setLevels(uniqueLevels);
  }, [transcriptPreview]);

  const yearMap = {
    1: "First Year",
    2: "Second Year",
    3: "Third Year",
    4: "Fourth Year",
    5: "Fifth Year",
  };
  const renderYear = (year) => {
    return yearMap[year] || "";
  };

  return (
    <Document>
      <Page size="A4" style={documentStyles.page} wrap>
        <View style={documentStyles.requestContainer}>
          <Text style={documentStyles.yearTitle}>
            Recipient: {requestPreview?.student.user.fullName}
          </Text>
          <Text style={documentStyles.yearTitle}>
            Posting Address: {requestPreview?.address1}
          </Text>
          <Text style={documentStyles.yearTitle}>
            Reference No: {requestPreview?.referenceNumber}
          </Text>
          <Text style={documentStyles.yearTitle}>
            Postcode: {requestPreview?.postalCode}
          </Text>
          <Text style={documentStyles.yearTitle}>
            Alumni Name: {requestPreview?.student.user.firstName}{" "}
            {requestPreview?.student.user.lastName}
          </Text>
          <Text style={documentStyles.yearTitle}>
            Matric Number: {requestPreview?.regNumber}
          </Text>
          <Text style={documentStyles.yearTitle}>
            Serial ID: {requestPreview?.requestOrderNumber}
          </Text>
          <Text style={documentStyles.yearTitle}>
            Email: {requestPreview?.destinationEmail}
          </Text>
          <Text style={documentStyles.yearTitle}>
            Phone: {requestPreview?.destinationContactNumber}
          </Text>
          <Text style={documentStyles.yearTitle}>
            {requestPreview?.courier}
          </Text>
        </View>
      </Page>
      <Page size="A4" style={documentStyles.page} wrap>
        <View style={documentStyles.container}>
          <View style={headerStyles.container}>
            <Image style={headerStyles.logo} src={Logo} />
            <Text style={headerStyles.titleOne}>GODFREY OKOYE UNIVERSITY</Text>
            <Text style={headerStyles.titleTwo}>(Enugu, Enugu)</Text>
            <Text style={headerStyles.titleThree}>OFFICE OF THE REGISTRAR</Text>
            <Text style={headerStyles.titleFour}>
              (Student Records and Institutional Research)
            </Text>
          </View>
          <View style={documentStyles.section}>
            <BioDataTable data={transcriptPreview} />
          </View>

          {/* For each level map the course to first and second semester table accordingly */}
          {levels.map((level) => (
            <>
              {/* First Semester Course Table */}
              <View style={documentStyles.section}>
                <Text style={documentStyles.yearTitle}>
                  {renderYear(level)}
                </Text>
                <Text style={documentStyles.semester}>First Semester</Text>
                <Table>
                  {transcriptPreview?.courseRegistrations
                    .filter((v) => level === v.levelId)
                    .filter((v) => v.semesterId === 1)
                    .map((v) => (
                      <View style={tableStyles.row} wrap={false}>
                        <Text style={tableStyles.rowCell}>{v?.courseCode}</Text>
                        <Text
                          style={[tableStyles.rowCell, tableStyles.spanTwo]}
                        >
                          {v?.course}
                        </Text>
                        <Text style={tableStyles.rowCell}>{v?.courseUnit}</Text>
                        <Text style={tableStyles.rowCell}>{v?.grade}</Text>
                        <Text style={tableStyles.rowCell}>{v?.gradePoint}</Text>
                      </View>
                    ))}
                </Table>
              </View>

              {/* Second Semester Course Table */}
              <View style={documentStyles.section}>
                <Text style={documentStyles.semester}>Second Semester</Text>
                <Table>
                  {transcriptPreview?.courseRegistrations
                    .filter((v) => level === v.levelId)
                    .filter((v) => v.semesterId === 2)
                    .map((v) => (
                      <View style={tableStyles.row} wrap={false}>
                        <Text style={tableStyles.rowCell}>{v?.courseCode}</Text>
                        <Text
                          style={[tableStyles.rowCell, tableStyles.spanTwo]}
                        >
                          {v?.course}
                        </Text>
                        <Text style={tableStyles.rowCell}>{v?.courseUnit}</Text>
                        <Text style={tableStyles.rowCell}>{v?.grade}</Text>
                        <Text style={tableStyles.rowCell}>{v?.gradePoint}</Text>
                      </View>
                    ))}
                </Table>
              </View>
            </>
          ))}
        </View>
      </Page>
    </Document>
  );
};

const Table = ({ children }) => {
  return (
    <View style={tableStyles.table}>
      <View
        style={[tableStyles.headerRow, tableStyles.bold, tableStyles.header]}
      >
        <Text style={tableStyles.headerRowCell}>Course Code</Text>

        <Text style={[tableStyles.headerRowCell, tableStyles.spanTwo]}>
          Course Name
        </Text>
        <Text style={tableStyles.headerRowCell}>Units</Text>
        <Text style={tableStyles.headerRowCell}>Grade</Text>
        <Text style={tableStyles.headerRowCell}>Grade Point</Text>
      </View>
      {children}
    </View>
  );
};

const BioDataTable = ({ data }) => {
  return (
    <View style={bioDataTableStyles.table}>
      <View
        style={[
          bioDataTableStyles.headerRow,
          bioDataTableStyles.bold,
          bioDataTableStyles.header,
        ]}
      >
        <Text style={bioDataTableStyles.headerRowCell}>
          ACADEMIC RECORDS OF:
        </Text>
      </View>
      <View style={bioDataTableStyles.row} wrap={false}>
        <Text style={bioDataTableStyles.rowCell}>
          NAME : {`${data?.firstName} ${data?.lastName} ${data?.otherName}`}
        </Text>
        <Text style={bioDataTableStyles.rowCell}>SEX : Male</Text>
      </View>

      <View style={bioDataTableStyles.row} wrap={false}>
        <Text style={bioDataTableStyles.rowCell}>
          STUDENT NO : {data?.regNumber}
        </Text>
        <Text style={bioDataTableStyles.rowCell}>
          DATE OF BIRTH : {data?.dateOfBirth?.split("T")[0]}
        </Text>
      </View>

      <View style={bioDataTableStyles.row} wrap={false}>
        <Text style={bioDataTableStyles.rowCell}>ADMITTED : 2000/2001</Text>
        <Text style={bioDataTableStyles.rowCell}>
          NATIONALITY : {data?.country}
        </Text>
      </View>

      <View style={bioDataTableStyles.row} wrap={false}>
        <Text style={bioDataTableStyles.rowCell}>
          FACULTY : {data?.faculty}
        </Text>
        <Text style={bioDataTableStyles.rowCell}>
          DEPARTMENT : {data?.department}
        </Text>
      </View>
    </View>
  );
};
