// import { PDFDownloadLink } from "@react-pdf/renderer";
import { CircularProgress } from "@mui/material";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import React, { useContext, useEffect, useState } from "react";
import { HiOutlineX, HiX } from "react-icons/hi";

import { Button, TextInput } from "../../../../UI";
import { TranscriptManagementContext } from "../../../../contexts/transcriptManagement.context";
import classes from "../PendingRequest.module.css";
import { TranscriptDocument } from "./TranscriptDocument";

const PreviewModal = ({
  setPreviewModalIsShown,
  data,
  orderNumber,
  dispatchCopy,
  setOrderNumber,
}) => {
  const [transcriptPreview, setTranscriptPreview] = useState({});
  const [requestPreview, setRequestPreview] = useState();
  const [flagModalIsShown, setFlagModalIsShown] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const {
    resultPreview,
    dispatchSoftCopy,
    getReadyForDispatchTranscripts,
    markTranscriptAsRequireSigning,
    getRequireReviewTranscripts,
  } = useContext(TranscriptManagementContext);

  //function to handle flag button click
  const handleFlagButtonClick = () => {
    setFlagModalIsShown(true);
  };

  //function to handle push to signing button click
  const handleConfirmButtonClick = async () => {
    setIsPending(true);
    await markTranscriptAsRequireSigning(orderNumber);
    await getRequireReviewTranscripts();
    setIsPending(false);
    setOrderNumber("");
    setPreviewModalIsShown(false);
  };

  //function to handle dispatch soft copy button click
  const handleExport = async () => {
    const pdfBlob = pdf(TranscriptDocument).toBlob();
    const data = new FormData();
    data.append("RequestOrderNumber", `${orderNumber}`);
    data.append("File", pdfBlob);
    await dispatchSoftCopy(data);
    getReadyForDispatchTranscripts();
    setPreviewModalIsShown(false);
  };

  useEffect(() => {
    setTranscriptPreview(resultPreview);
  }, [resultPreview]);

  useEffect(() => {
    const request = data.filter(
      (item) => item.requestOrderNumber === orderNumber
    );
    setRequestPreview(request[0]);
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className={classes.preview_modal}>
        {flagModalIsShown && (
          <FlagRequestModal
            setModal={setFlagModalIsShown}
            setPreviewModalIsShown={setPreviewModalIsShown}
            orderNo={orderNumber}
          />
        )}
        <div className={classes.title_container}>
          <span>
            <p>Serial ID</p>
            <p>{orderNumber}</p>
          </span>
          <HiOutlineX
            className={classes.close_btn}
            onClick={() => {
              setPreviewModalIsShown(false);
              setOrderNumber("");
            }}
          />
        </div>

        <div className={classes.tab_content}>
          <PDFViewer width={"100%"} height={"100%"}>
            <TranscriptDocument
              transcriptPreview={transcriptPreview}
              requestPreview={requestPreview}
            />
          </PDFViewer>
        </div>

        <div className={classes.button_container}>
          {dispatchCopy ? (
            <Button onClick={handleExport}>Dispatch Soft Copy</Button>
          ) : (
            <>
              <Button onClick={handleConfirmButtonClick}>
                {isPending ? (
                  <CircularProgress />
                ) : (
                  <span>Push for signing</span>
                )}
              </Button>
              <Button onClick={handleFlagButtonClick}>Flag</Button>
            </>
          )}
        </div>
      </div>
      <div className={classes.overlay}></div>
    </>
  );
};

export default PreviewModal;

const FlagRequestModal = ({ setModal, orderNo, setPreviewModalIsShown }) => {
  const [message, setMessage] = useState("");
  const [isPending, setIsPending] = useState(false);
  const { flagRequest, getRequireReviewTranscripts } = useContext(
    TranscriptManagementContext
  );

  const handleFlagRequest = async (e) => {
    e.preventDefault();
    setIsPending(true);
    await flagRequest({
      requestOrderNumber: orderNo,
      message: message,
    });
    setIsPending(false);
    getRequireReviewTranscripts();

    setModal(false);
    setPreviewModalIsShown(false);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setMessage(value);
  };

  return (
    <>
      <div className={classes.overlay}></div>
      <div className={classes.modal}>
        <button
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            background: "none",
            border: "none",
            outline: "none",
            cursor: "pointer",
          }}
          onClick={() => {
            setModal(false);
          }}
        >
          <HiX />
        </button>

        <form onSubmit={handleFlagRequest}>
          <TextInput
            placeholder="Enter Message"
            required
            name="message"
            onChange={handleChange}
            value={message}
          />
          <Button type="submit">
            {isPending ? <CircularProgress /> : <span>Flag</span>}
          </Button>
        </form>
      </div>
    </>
  );
};
