import axios from "axios";

import { getItemFromLocalStorage } from "../../utils/helper";

export const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
  async ({ url, method, data, params }) => {
    let headers;
    const accessToken = getItemFromLocalStorage("accessToken");
    if (accessToken) headers = { Authorization: `Bearer ${accessToken}` };

    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        headers: headers,
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
