import { combineReducers, configureStore } from "@reduxjs/toolkit";

import generalReducer from "./features/generalSlice";
import modalsReducer from "./features/modalsSlice";
import userReducer from "./features/userSlice";
import { transcriptApi } from "./services/transcriptCore";

const rootReducer = combineReducers({
  user: userReducer,
  general: generalReducer,
  modals: modalsReducer,
  [transcriptApi.reducerPath]: transcriptApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [],
      },
    }).concat(transcriptApi.middleware),
});
