import { CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { HiX } from "react-icons/hi";

import { Button, Card, TextInput } from "../../../UI";
import { TabPage, Table } from "../../../components";
import { TranscriptManagementContext } from "../../../contexts/transcriptManagement.context";
import classes from "./AwaitingSigning.module.css";

const AwaitingSigning = () => {
  const [modalIsShown, setModalIsShown] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPending, setIsPending] = useState({});
  const [orderNumber, setOrderNumber] = useState();

  const {
    requireSigningTranscripts,
    getRequireSigningTranscripts,
    markTranscriptAsReadyForDispatch,
  } = useContext(TranscriptManagementContext);

  useEffect(() => {
    getRequireSigningTranscripts();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (requireSigningTranscripts) setIsLoading(false);

    requireSigningTranscripts?.forEach((item) =>
      setIsPending((prev) => ({ ...prev, [item.requestOrderNumber]: false }))
    );
  }, [requireSigningTranscripts]);

  const handleChange = () => {};

  const handleConfirmButtonClick = async (orderNo) => {
    setIsPending((prev) => ({ ...prev, [orderNo]: true }));
    await markTranscriptAsReadyForDispatch(orderNo);
    await getRequireSigningTranscripts();
    setIsPending((prev) => ({ ...prev, [orderNo]: false }));
  };

  const handleFlagButtonClick = (oredrNo) => {
    setOrderNumber(oredrNo);
    setModalIsShown(true);
  };

  const columns = [
    { value: "Serial ID" },
    { value: "Full Name" },
    { value: "Request Date" },
    { value: "Matric No." },
    { value: "Shipping Address" },
    { value: "Destination Email" },
    { value: "" },
    { value: "" },
  ];

  const data = requireSigningTranscripts?.map((item) => ({
    "Serial ID": item.requestOrderNumber,
    "Full Name": item.student.user.fullName,
    "Request Date": item.createdAt.split("T")[0],
    "Matric No.": item.regNumber,
    "Shipping Address": item.address1,
    "Destination Email": item.destinationEmail,

    Flag: (
      <button
        onClick={() => handleFlagButtonClick(item.requestOrderNumber)}
        title="Flag"
      >
        Flag
      </button>
    ),
    Confirm: (
      <button
        onClick={() => handleConfirmButtonClick(item.requestOrderNumber)}
        title="Confirm"
      >
        {isPending[item.requestOrderNumber] ? (
          <CircularProgress />
        ) : (
          <span>Confirm</span>
        )}
      </button>
    ),
  }));

  return (
    <TabPage pageTitle="Transcript Management/ Awaiting Signing">
      {modalIsShown && (
        <FlagRequestModal
          setModal={setModalIsShown}
          orderNo={orderNumber}
          setOrderNumber={setOrderNumber}
        />
      )}
      <Card className={classes.card}>
        {isLoading ? (
          <p>
            Loading Requests <CircularProgress />
          </p>
        ) : (
          <Table columns={columns} data={data} />
        )}
      </Card>
    </TabPage>
  );
};

export default AwaitingSigning;

const FlagRequestModal = ({ setModal, orderNo, setOrderNumber }) => {
  const [message, setMessage] = useState("");
  const [isPending, setIsPending] = useState(false);
  const { flagRequest, getRequireSigningTranscripts } = useContext(
    TranscriptManagementContext
  );

  const handleFlagRequest = async (e) => {
    e.preventDefault();
    setIsPending(true);
    await flagRequest({
      requestOrderNumber: orderNo,
      message: message,
    });
    setIsPending(false);
    getRequireSigningTranscripts();
    setOrderNumber("");
    setModal(false);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setMessage(value);
  };

  return (
    <>
      <div className={classes.overlay}></div>
      <div className={classes.modal}>
        <button
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            background: "none",
            border: "none",
            outline: "none",
            cursor: "pointer",
          }}
          onClick={() => {
            setModal(false);
            setOrderNumber("");
          }}
        >
          <HiX />
        </button>
        <form onSubmit={handleFlagRequest}>
          <TextInput
            placeholder="Enter Message"
            required
            name="message"
            onChange={handleChange}
            value={message}
          />
          <Button type="submit">
            {isPending ? <CircularProgress /> : <span>Flag</span>}
          </Button>
        </form>
      </div>
    </>
  );
};
