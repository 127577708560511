import { useContext, useState } from "react";

import { CurrencyModal } from "../../../components";
import { PaymentModal } from "./PaymentModal";
import { ShippingInfo } from "./ShippingInfo";
import { EnglishProficiencyContext } from "../../../contexts/englishProficiency";

const ShippingOption = ({ handleBack }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const { copyType } = useContext(EnglishProficiencyContext);

  const content = {
    soft_copy: (
      <>
        {currentStep === 0 && (
          <CurrencyModal handleBack={handleBack} handleNext={setCurrentStep} />
        )}
        {currentStep === 1 && (
          <PaymentModal onClose={setCurrentStep} modal={false} />
        )}
      </>
    ),
    hard_copy: (
      <>
        {currentStep === 0 && (
          <CurrencyModal handleBack={handleBack} handleNext={setCurrentStep} />
        )}
        {currentStep === 1 && (
          <ShippingInfo onClick={setCurrentStep} handleBack={setCurrentStep} />
        )}
        {currentStep >= 2 && (
          <PaymentModal onClose={setCurrentStep} modal={true} />
        )}
      </>
    ),
  };

  const renderContent = content[copyType];

  return renderContent;
};

export default ShippingOption;
