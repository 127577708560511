import { StyleSheet } from "@react-pdf/renderer";

export const tableStyles = StyleSheet.create({
  table: {
    width: "100%",
    fontSize: "14px",
  },
  header: {
    borderTop: "none",
  },
  bold: {
    fontWeight: "bold",
  },
  headerRow: {
    backgroundColor: "yellow",
    display: "flex",
    flexDirection: "row",
  },
  headerRowCell: {
    padding: "10px",
    border: "1px solid black",
    width: "50%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  rowCell: {
    padding: "10px",
    border: "1px solid black",
    width: "50%",
  },
  spanTwo: {
    width: "100%",
  },
});

export const bioDataTableStyles = StyleSheet.create({
  table: {
    width: "100%",
    fontSize: "14px",
  },
  header: {
    borderTop: "none",
  },
  bold: {
    fontWeight: "bold",
  },
  headerRow: {
    backgroundColor: "yellow",
    display: "flex",
    flexDirection: "row",
  },
  headerRowCell: {
    padding: "10px",
    border: "1px solid black",
    width: "100%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  rowCell: {
    padding: "10px",
    border: "1px solid black",
    width: "100%",
  },
});

export const documentStyles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFF",
    width: "100%",
    // fontFamily: "Poppins",
  },
  section: {
    padding: 10,
  },
  semester: {
    marginVertical: "10px",
  },
  requestContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    padding: 20,
  },
  container: {
    padding: 20,
  },
  yearTitle: {
    marginTop: "10px",
    fontSize: "20px",
  },
});
export const headerStyles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFF",
    textAlign: "center",
    padding: 10,
  },

  logo: {
    position: "absolute",
    right: "20px",
    top: "16px",
    width: "50px",
    height: "50px",
  },

  titleOne: {
    fontSize: "20px",
    fontWeight: "black",
  },
  titleTwo: {
    fontSize: "12px",
  },
  titleThree: {
    fontWeight: "bold",
    fontSize: "14px",
  },
  titleFour: {
    fontSize: "12px",
  },
});
