import { CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { HiX } from "react-icons/hi";
import { toast } from "react-toastify";

import { Button, Select, TextInput } from "../../../UI";
import { GeneralContext } from "../../../contexts/general.context";
import { TranscriptManagementContext } from "../../../contexts/transcriptManagement.context";
import classes from "./AwaitingDispatch.module.css";

export const HardCopyModal = ({ setModal, orderNo, setOrderNumber }) => {
  const [inputData, setInputData] = useState({});
  const [isPending, setIsPending] = useState(false);
  const { dispatchHardCopy, getReadyForDispatchTranscripts } = useContext(
    TranscriptManagementContext
  );
  const { getCourier, couriers } = useContext(GeneralContext);

  useEffect(() => {
    getCourier();
    //eslint-disable-next-line
  }, []);

  const validateInput = () => {
    if (!inputData.courier) {
      toast.error("Please select a valid option for each field ");
      return false;
    }
    return true;
  };
  const handleDispatch = async (e) => {
    e.preventDefault();
    if (validateInput()) {
      setIsPending(true);
      inputData.courier = 1;
      await dispatchHardCopy({
        requestOrderNumber: orderNo,
        ...inputData,
      });
      setIsPending(false);
      getReadyForDispatchTranscripts();
      setModal(false);
      setOrderNumber("");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <div className={classes.overlay}></div>
      <div className={classes.modal}>
        <button
          style={{
            float: "right",
            background: "none",
            border: "none",
            outline: "none",
            cursor: "pointer",
          }}
        >
          <HiX
            onClick={() => {
              setModal(false);
              setOrderNumber("");
            }}
            className="absolute top-6 right-6"
          />
        </button>
        <form onSubmit={handleDispatch} className="grid gap-4 my-4">
          <TextInput
            placeholder="tracking number"
            required
            name="trackingNumber"
            onChange={handleChange}
            value={inputData.trackingNumber ? inputData.trackingNumber : ""}
          />
          <Select
            placeholder="Courier"
            options={[
              { key: 0, value: "select courier", disabled: true },
              ...couriers,
            ]}
            name="courier"
            onChange={handleChange}
            required
            value={inputData.courier ? inputData.courier : 0}
          />
          <Button type="submit">
            {isPending ? <CircularProgress /> : <span>Confirm</span>}
          </Button>
        </form>
      </div>
    </>
  );
};
