import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import { toast } from "react-toastify";

import { Button } from "../../UI";
import { GeneralContext } from "../../contexts/general.context";
import { formatCurrency } from "../../utils/currency";
import { FETCH_STATUS } from "../../utils/fetchStatus";
import classes from "./PaymentGateway.module.css";

const PaymentGateway = ({
  onPrevious,
  onPayment,
  modal,
  initializePaymentObject,
}) => {
  const [redirected, setRedirected] = useState(false);
  const [data, setData] = useState();
  const [status, setStatus] = useState(FETCH_STATUS.UNINITIALIZED);

  const { initializePayment } = useContext(GeneralContext);

  useEffect(() => {
    setStatus(FETCH_STATUS.LOADING);
    try {
      (async () => {
        const breakdown = await initializePayment(initializePaymentObject);
        setData(breakdown);
        setStatus(FETCH_STATUS.SUCCESS);
      })();
    } catch (err) {
      setStatus(FETCH_STATUS.ERROR);
      toast.error(
        err?.data?.message || "Something went wrong, please try again"
      );
    }
  }, []);

  const closeHandler = () => {
    onPrevious((prevStep) => prevStep - 1);
    setData();
  };

  const handlePay = () => {
    if (status !== FETCH_STATUS.SUCCESS) return;
    window.open(data?.paymentUrl, "_blank");
    setRedirected(true);
  };

  return (
    <div>
      <div className={modal ? classes.modal : classes.container}>
        {modal && (
          <HighlightOffIcon
            className="ab absolute top-5 right-5 text-[#146d17]"
            onClick={closeHandler}
          />
        )}
        <div>
          <p className={classes.title}>Payment Breakdown</p>
          {status === FETCH_STATUS.LOADING && (
            <p className="text-center">
              <CircularProgress />
            </p>
          )}
          {data?.paymentBreakdown?.length &&
            data?.paymentBreakdown.map((item) => (
              <p key={item.name} className={classes.subTitle}>
                {item.name}: {formatCurrency(item.amount)}
              </p>
            ))}
          {data?.totalAmount && (
            <p className={classes.subTitle}>
              Total: {formatCurrency(data.totalAmount, "NGN")}
            </p>
          )}
        </div>

        <div className={classes.buttonContainer}>
          {!modal && (
            <Button
              className={classes.backButton}
              onClick={onPrevious}
              variant="invert"
            >
              <HiOutlineArrowSmLeft size={16} className="mx mx-2" />
              Back
            </Button>
          )}
          {redirected ? (
            <Button onClick={onPayment}>Done</Button>
          ) : (
            <Button
              onClick={handlePay}
              disabled={status !== FETCH_STATUS.SUCCESS}
            >
              Pay now
            </Button>
          )}
        </div>
      </div>
      {modal && <div className={classes.overlay}></div>}
    </div>
  );
};

export default PaymentGateway;
