import React, { useContext, useState } from "react";
import classes from "./DestinationInfo.module.css";
import EditIcon from "@mui/icons-material/Edit";
import { HiOutlineArrowSmLeft, HiOutlineArrowSmRight } from "react-icons/hi";
import Button from "../../../UI/Button/Button";
import { EnglishProficiencyContext } from "../../../contexts/englishProficiency";
import { GeneralContext } from "../../../contexts/general.context";
import { toast } from "react-toastify";
import { ImSpinner3 } from "react-icons/im";
import { createEnglishProficiencyApi } from "../../../api/Writes";
import { ResultRequestType } from "../../../utils/helper";

const ProvidedInformation = ({ onClick, setCurrentStep }) => {
  const {
    englishProficiencyInfo,
    copyType,
    setProficiencyOrderNo,
    requestType,
  } = useContext(EnglishProficiencyContext);
  const [isPending, setIsPending] = useState(false);
  const { countries, states, cities, initializePaymentSchema } =
    useContext(GeneralContext);

  const handleNext = async () => {
    setIsPending(true);
    try {
      //   update values of transcript data
      englishProficiencyInfo.regNumber = localStorage.regNumber;
      englishProficiencyInfo.studentId = localStorage.studentId;
      englishProficiencyInfo.status = 1;
      englishProficiencyInfo.requestType = 2;
      englishProficiencyInfo.resultType = copyType === "hard_copy" ? 1 : 2;
      englishProficiencyInfo.resultRequestType = ResultRequestType[requestType];
      englishProficiencyInfo.includeProficiency = true;
      englishProficiencyInfo.isProficiency = true;
      englishProficiencyInfo.courier = " ";
      englishProficiencyInfo.organisation = " ";

      const res = await createEnglishProficiencyApi(englishProficiencyInfo);
      setProficiencyOrderNo(res?.data?.requestOrderNumber);
      toast.success("");

      // update initilaize payment data
      initializePaymentSchema.RequestOrderNumber =
        res?.data?.requestOrderNumber;
      initializePaymentSchema.feeType = 1;
      initializePaymentSchema.isProficiency = true;
      initializePaymentSchema.resultType = copyType === "hard_copy" ? 1 : 2;
      setIsPending(false);
      onClick();
    } catch (err) {
      toast.error("Something went wrong, please try again");
      setIsPending(false);
    }
  };

  const country = countries.filter(
    (p) => p.key === Number(englishProficiencyInfo.countryId)
  )[0]?.value;
  const state = states.filter(
    (p) => p.key === Number(englishProficiencyInfo.stateId)
  )[0]?.value;
  const city = cities.filter(
    (p) => p.key === Number(englishProficiencyInfo.cityId)
  )[0]?.value;

  return (
    <>
      <p className={classes.providedInformation__heading}>
        Destination Information
      </p>
      <p className={classes.providedInformation__subHeading}>
        Let's be sure... review your information
      </p>
      <div className={classes.providedInformation__container}>
        <>
          {copyType === "hard_copy" ? (
            <>
              <ul className={classes.providedInformation__unorderedList}>
                <li>
                  <p>Address</p>
                  <h4>{englishProficiencyInfo?.address1}</h4>
                </li>
                <li>
                  <p>Country</p>
                  <h4>{country}</h4>
                </li>
                <li>
                  <p>State/Province</p>
                  <h4>{state}</h4>
                </li>
              </ul>
              <ul className={classes.providedInformation__unorderedList}>
                <li>
                  <p>City</p>
                  <h4>{city}</h4>
                </li>
                <li>
                  <p>Zip/PostCode</p>
                  <h4>{englishProficiencyInfo?.postalCode}</h4>
                </li>
                <li>
                  <p>Destination Email</p>
                  <h4>{englishProficiencyInfo?.destinationEmail}</h4>
                </li>
                <li>
                  <p>Contact Phone Number</p>
                  <h4>{englishProficiencyInfo?.destinationContactNumber}</h4>
                </li>
                <li>
                  <p>Copy</p>
                  <h4>Hard Copy</h4>
                </li>
              </ul>
            </>
          ) : (
            <>
              <ul className={classes.providedInformation__unorderedList}>
                <li>
                  <p>Destination Email</p>
                  <h4>{englishProficiencyInfo?.destinationEmail}</h4>
                </li>
                <li>
                  <p>Copy</p>
                  <h4>Soft Copy</h4>
                </li>
              </ul>
            </>
          )}
        </>
        <div
          onClick={setCurrentStep}
          className={classes.providedInformation__editButton}
        >
          <EditIcon
            style={{
              color: "white",
              fontSize: "20px",
            }}
          />
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <Button className={classes.backButton} onClick={setCurrentStep}>
          <HiOutlineArrowSmLeft
            style={{
              fontSize: "16px",
              marginInline: "5px",
            }}
          />
          Back
        </Button>
        <Button onClick={handleNext}>
          {isPending ? (
            <ImSpinner3 className="animate-spin" size={22} />
          ) : (
            <>
              Next <HiOutlineArrowSmRight size={20} />
            </>
          )}
        </Button>
      </div>
    </>
  );
};

export default ProvidedInformation;
