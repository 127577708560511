import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import { mapCourseApi, reMapCourseApi } from "../api/Writes";
import { getMappedCoursesApi } from "../api/Reads";

export const MapCourseContext = createContext(null);

const MapCourseProvider = ({ children }) => {
    const [mappedCourses, setMappedCourses] = useState([]);
    const [searchedMappedCourses, setSearchedMappedCourses] = useState([]);

    //get already mapped course
    const getMappedCourses = async () => {
        try {
            let response = await getMappedCoursesApi();
            if (response.success) {
                let items = response.data.map((item) => {
                    item.key = item.id;
                    item.value = item.course.courseName;
                    return item;
                });
                setMappedCourses(items);
            } else {
                toast.error(response.message || "Oops, Something went wrong");
            }
        } catch ({ response }) {
            const { title } = response?.data;
            toast.error(title || "Oops, Something went wrong");
        }
    };
    //map new course
    const mapCourse = async (param) => {
        try {
            let response = await mapCourseApi(param);

            if (response.success) {
                toast.success("Course Mapped successfully");
            } else {
                toast.error(response.message || "Oops, Something went wrong");
            }
        } catch ({ response }) {
            const { message } = response?.data;
            toast.error(message || "Oops, Something went wrong");
        }
    };

    //remap course
    const reMapCourse = async (param) => {
        try {
            let response = await reMapCourseApi(param);

            if (response.success) {
                toast.success("Course Re-Mapped successfully");
            } else {
                toast.error(response.message || "Oops, Something went wrong");
            }
        } catch ({ response }) {
            const { message } = response?.data;
            toast.error(message || "Oops, Something went wrong");
        }
    };

    //search mapped courses
    const searchMappedCourses = async (mappedCourseName) => {
        // try {
        //     let response = await searchMappedCourseApi(mappedCourseName);
        //     if (response.success) {
        //         setSearchedMappedCourses(response);
        //     } else {
        //         toast.error("Oops, Something went wrong");
        //     }
        // } catch ({ response }) {
        //     const { title } = response?.data;
        //     toast.error(title || "Oops, Something went wrong");
        // }
    };

    return (
        <MapCourseContext.Provider
            value={{
                mappedCourses,
                mapCourse,
                reMapCourse,
                getMappedCourses,
                searchMappedCourses,
                searchedMappedCourses,
            }}
        >
            {children}
        </MapCourseContext.Provider>
    );
};
export default MapCourseProvider;
