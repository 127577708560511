import React, { useState, useContext, useEffect } from "react";
import { Button, Select, TextInput } from "../../../../UI";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import classes from "./VerificationBody.module.css";
import { GeneralContext } from "../../../../contexts/general.context";
import { toast } from "react-toastify";
import { VerificationRequestContext } from "../../../../contexts/verificationRequest";

const VerificationBody = ({ handleNext }) => {
    const [selected, setSelected] = useState(false);
    const [refRequired, setRefRequired] = useState(false);

    const {
        verificationBody,
        setVerificationBody,
        verificationRequest,
        setVerificationRequest,
        getVerificationBody,
    } = useContext(VerificationRequestContext);

    useEffect(() => {
        setVerificationRequest({});
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        getVerificationBody();

        //eslint-disable-next-line
    }, [selected]);

    const handleSelection = (e) => {
        const { name, value } = e.target;
        setVerificationRequest((prev) => ({
            ...prev,
            [name]: parseInt(value),
        }));

        let filter = verificationBody.filter((p) => p.key === parseInt(value));
        if (filter.length > 0) {
            setRefRequired(filter[0].refRequired);
        }
        setSelected(true);
    };

    const handleInput = (e) => {
        const { name, value } = e.target;
        setVerificationRequest((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!verificationRequest.verificationBodyId) {
            toast.error("Please select a valid option");
        } else {
            handleNext();
        }
    };
    return (
        <form onSubmit={handleSubmit} className={classes.container}>
            <p className={classes.title}> Verification Body</p>
            <p className={classes.subTitle}>
                Request for verification on behalf of a verification body
            </p>
            <Select
                options={verificationBody}
                onChange={handleSelection}
                name="verificationBodyId"
                value={verificationRequest.verificationBodyId || 0}
            />
            {refRequired ? (
                <TextInput
                    placeholder="Reference Number"
                    name="referenceNumber"
                    onChange={handleInput}
                    required={true}
                />
            ) : null}

            <Button
                className={classes.button}
                disabled={!selected}
                type="submit"
            >
                Proceed
                <HiOutlineArrowSmRight
                    style={{ fontSize: "16px", marginInline: "5px" }}
                />
            </Button>
            <p className={classes.anchorText}>
                If the verification body is not found <br />
                <a href="###">please contact us</a>
            </p>
        </form>
    );
};

export default VerificationBody;
