import axios from "axios";
import { secretKey } from "../../utils/globalConstant";
import { toast } from "react-toastify";

const verifyPaymentApi = async (reference) => {
  const url = `https://api.paystack.co/transaction/verify/${reference}`;
  const headers = {
    Authorization: `Bearer ${secretKey}`,
  };
  const { data } = await axios.get(url, { headers });
  return data;
};

export const verifyPayment = async (reference) => {
  try {
    const { data } = await verifyPaymentApi(reference);
    if (data.status === "success") {
      const { reference } = data;
      return reference;
    }
  } catch ({ response }) {
    const { message } = response?.data;
    toast.error(message || "Payment could not be verified");
  }
};
