import React, { useState } from "react";

import { Card } from "../../../UI";
import { StepperComponent, TabPage } from "../../../components";
import AlumniDetails from "./AlumniDetails/AlumniDetails";
import classes from "./AlumniVerification.module.css";
import PaymentOption from "./PaymentOption/PaymentOption";
import SelectDocument from "./SelectDocument/SelectDocument";
import VerificationBody from "./VerificationBody/VerificationBody";

function AlumniVerification() {
  const steps = ["one", "two", "three", "four"];
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <TabPage pageTitle="Verification">
      <StepperComponent steps={steps} width={"60%"} activeStep={activeStep} />
      <Card className={classes.card}>
        {activeStep === 0 && (
          <VerificationBody handleNext={handleNext} key={0} />
        )}
        {activeStep === 1 && (
          <SelectDocument
            key={1}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        )}
        {activeStep === 2 && (
          <AlumniDetails
            key={2}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        )}
        {activeStep === 3 && (
          <PaymentOption
            key={3}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        )}
      </Card>
    </TabPage>
  );
}

export default AlumniVerification;
