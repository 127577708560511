import React, { createContext, useState } from "react";
import { toast } from "react-toastify";

import {
  dispatchSoftCopyApi,
  getReadyForDispatchTranscriptsApi,
  getRequireApprovalTranscriptsApi,
  getRequireDigitizationTranscriptsApi,
  getRequireReviewTranscriptsApi,
  getRequireSigningTranscriptsApi,
  markTranscriptAsReadyForDispatchApi,
  markTranscriptAsRequireApprovalApi,
  markTranscriptAsRequireReviewApi,
  markTranscriptAsRequireSigningApi,
  previewResultApi,
} from "../api/Reads";
import { dispatchHardCopyApi, flagTranscriptApi } from "../api/Writes";

export const TranscriptManagementContext = createContext();

const TranscriptManagementProvider = ({ children }) => {
  const [requireDigitizationTranscripts, setRequireDigitizationTranscripts] =
    useState();
  const [requireReviewTranscripts, setRequireReviewTranscripts] = useState();
  const [requireApprovalTranscripts, setRequireApprovalTranscripts] =
    useState();
  const [requireSigningTranscripts, setRequireSigningTranscripts] = useState();
  const [readyForDispatchTranscripts, setReadyForDispatchTranscripts] =
    useState();

  const [regNoFromRedirect, setRegNoFromRedirect] = useState("");
  const [resultPreview, setResultPreview] = useState({});

  //get information for result preview
  const previewResult = async (regNo) => {
    try {
      let response = await previewResultApi(regNo);
      if (response.success) {
        setResultPreview(response.data);
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { title } = response?.data;
      toast.error(title || "Student not found");
    }
  };
  //flag a request
  const flagRequest = async (param) => {
    try {
      let response = await flagTranscriptApi(param);
      if (response.success) {
        if (param?.isProficiency) {
          toast.success("Proficiency in english flagged");
        } else {
          toast.success("Transcript flagged");
        }
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { title } = response?.data;
      toast.error(title || "Oops, Something went wrong");
    }
  };

  //UNDIGITIZED REQUESTS
  //get all transcripts that require digitization
  const getRequireDigitizationTranscripts = async () => {
    try {
      let response = await getRequireDigitizationTranscriptsApi();
      if (response.success) {
        setRequireDigitizationTranscripts(response.data);
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { title } = response?.data;
      toast.error(title || "Oops, Something went wrong");
    }
  };
  //mark digitized transcript as ready fro review
  const markTranscriptAsRequireReview = async (orderNo) => {
    try {
      let response = await markTranscriptAsRequireReviewApi(orderNo);
      if (response.success) {
        toast.success("Transcript successfully sent for Reviewal");
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { title } = response?.data;
      toast.error(title || "Oops, Something went wrong");
    }
  };

  //DIGITIZED REQUESTS
  //get all transcripts that require review
  const getRequireReviewTranscripts = async (isProficiency) => {
    try {
      let response = await getRequireReviewTranscriptsApi(isProficiency);
      if (response.success) {
        setRequireReviewTranscripts(response.data);
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { title } = response?.data;
      toast.error(title || "Oops, Something went wrong");
    }
  };
  //mark digitized transcript as ready fro approval
  const markTranscriptAsRequireApproval = async (orderNo) => {
    try {
      let response = await markTranscriptAsRequireApprovalApi(orderNo);
      if (response.success) {
        toast.success("Transcript successfully sent for Approval");
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { title } = response?.data;
      toast.error(title || "Oops, Something went wrong");
    }
  };

  //AWAITING SIGNING REQUESTS
  //get all transcripts that require signing
  const getRequireSigningTranscripts = async () => {
    try {
      let response = await getRequireSigningTranscriptsApi();
      if (response.success) {
        setRequireSigningTranscripts(response.data);
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { title } = response?.data;
      toast.error(title || "Oops, Something went wrong");
    }
  };
  //mark digitized transcript as require signing
  const markTranscriptAsRequireSigning = async (orderNo) => {
    try {
      let response = await markTranscriptAsRequireSigningApi(orderNo);
      if (response.success) {
        toast.success("Transcript successfully sent for Signing");
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { title } = response?.data;
      toast.error(title || "Oops, Something went wrong");
    }
  };

  //AWAITING DISPATCH
  //get all transcripts that require approval
  const getRequireApprovalTranscripts = async () => {
    try {
      let response = await getRequireApprovalTranscriptsApi();
      if (response.success) {
        setRequireApprovalTranscripts(response.data);
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { title } = response?.data;
      toast.error(title || "Oops, Something went wrong");
    }
  };
  //mark digitized transcript as ready fro approval
  const markTranscriptAsReadyForDispatch = async (orderNo) => {
    try {
      let response = await markTranscriptAsReadyForDispatchApi(orderNo);
      if (response.success) {
        toast.success("Transcript successfully sent for Disptach");
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { title } = response?.data;
      toast.error(title || "Oops, Something went wrong");
    }
  };

  //DISPATCHED TRANSCRIPTS
  //get all transcripts that are ready for dispatch
  const getReadyForDispatchTranscripts = async (isProficiency) => {
    try {
      let response = await getReadyForDispatchTranscriptsApi(isProficiency);
      if (response.success) {
        setReadyForDispatchTranscripts(response.data);
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { message } = response?.data;
      toast.error(message || "Oops, Something went wrong");
    }
  };

  //dispatch hard copy
  const dispatchHardCopy = async (param) => {
    try {
      let response = await dispatchHardCopyApi(param);
      if (response.success) {
        if (param?.isProficiency) {
          toast.success("Hard Copy Proficiency in english Dispatched Successfully");
        } else {
          toast.success("Hard Copy Transcript Dispatched Successfully");
        }
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { message } = response?.data;
      toast.error(message || "Oops, Something went wrong");
    }
  };

  //dispatch Soft copy
  const dispatchSoftCopy = async (orderNo, isProficiency) => {
    try {
      let response = await dispatchSoftCopyApi(orderNo, isProficiency);
      if (response.success) {
        if (isProficiency) {
          toast.success(
            "Soft Copy Proficiency in english Dispatched Successfully"
          );
        } else {
          toast.success("Soft Copy Transcript Dispatched Successfully");
        }
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { message } = response?.data;
      toast.error(message || "Oops, Something went wrong");
    }
  };

  return (
    <TranscriptManagementContext.Provider
      value={{
        requireDigitizationTranscripts,
        getRequireDigitizationTranscripts,
        markTranscriptAsRequireReview,
        requireReviewTranscripts,
        getRequireReviewTranscripts,
        markTranscriptAsRequireApproval,
        requireApprovalTranscripts,
        getRequireApprovalTranscripts,
        markTranscriptAsReadyForDispatch,
        requireSigningTranscripts,
        getRequireSigningTranscripts,
        markTranscriptAsRequireSigning,
        readyForDispatchTranscripts,
        getReadyForDispatchTranscripts,
        dispatchHardCopy,
        dispatchSoftCopy,
        regNoFromRedirect,
        setRegNoFromRedirect,
        resultPreview,
        previewResult,
        flagRequest,
      }}
    >
      {children}
    </TranscriptManagementContext.Provider>
  );
};
export default TranscriptManagementProvider;
