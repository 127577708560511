import React, { useState } from "react";
import ClassNames from "classnames";
import styles from "./styles.module.css";
import { TabPage } from "../../../components";
import TranscriptFlaggedRequest from "./TranscriptFlaggedRequestTable";
import ProficiencyFlaggedRequestTable from "./ProficiencyFlaggedRequest";

const FlaggedRequest = () => {
  const [currentTab, setCurrentTab] = useState(1);

  return (
    <TabPage pageTitle="Digitizer/ Flagged Requests">
      <div className="grid w-full h-full">
        <div className={styles.tab_container}>
          <div
            className={ClassNames(
              styles.tab_item,
              currentTab === 1 && styles.active
            )}
            onClick={() => setCurrentTab(1)}
          >
            Transcripts
          </div>
          <div
            className={ClassNames(
              styles.tab_item,
              currentTab === 2 && styles.active
            )}
            onClick={() => setCurrentTab(2)}
          >
            Proficiency
          </div>
        </div>
        {currentTab === 1 && <TranscriptFlaggedRequest />}
        {currentTab === 2 && <ProficiencyFlaggedRequestTable />}
      </div>
    </TabPage>
  );
};
export default FlaggedRequest;
