import axiosInstance from "../utils/request";

export const searchStudentApi = async (param) => {
  const { data } = await axiosInstance.post("api/v1/Auth/Search", param);
  return data;
};

export const createProfileApi = async (param) => {
  const { data } = await axiosInstance.post("api/v1/Auth/createProfile", param);
  return data;
};

export const saveVerificationRequestApi = async (param) => {
  const { data } = await axiosInstance.post(
    "api/v1/Auth/createVerificationRequest",
    param
  );
  return data;
};

export const createTranscriptApi = async (param) => {
  const { data } = await axiosInstance.post(
    "api/v1/Auth/createTranscript",
    param
  );
  return data;
};

export const createEnglishProficiencyApi = async (param) => {
  const { data } = await axiosInstance.post(
    "api/v1/Auth/createTranscript",
    param
  );
  return data;
};

export const loginApi = async (param) => {
  const { data } = await axiosInstance.post("api/v1/Auth/Login", param);
  return data;
};

//DIGITIZER
export const updateStudentApi = async (param) => {
  const { data } = await axiosInstance.post(
    "api/v1/Transcript/UpdateStudent",
    param
  );
  return data;
};

export const updateCourseApi = async (param) => {
  const { data } = await axiosInstance.post(
    "api/v1/Transcript/UpdateCourseRegisteration",
    param
  );
  return data;
};

export const deleteCourseApi = async (courseRegistrationId) => {
  const { data } = await axiosInstance.put(
    `api/v1/Transcript/CourseRegisteration/Delete?CourseRegistrationId=${courseRegistrationId}`
  );
  return data;
};

export const saveCGPApi = async ({
  studentId,
  totalUnit,
  weightedgpa,
  cummulativeGpa,
  studentdegree,
  studentdegreeClass,
}) => {
  const { data } = await axiosInstance.post(
    "/api/v1/Transcript/SaveCGPA",
    studentId,
    totalUnit,
    weightedgpa,
    cummulativeGpa,
    studentdegree,
    studentdegreeClass
  );
  return data;
};
// ==========

//UTILTY
export const uploadFileApi = async (param) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const { data } = await axiosInstance.post(
    "api/v1/Util/UploadWithCloudinary",
    param,
    config
  );
  return data;
};
// ==========

//CREATE COURSE
export const registerCourseApi = async (param) => {
  const { data } = await axiosInstance.post("api/v1/Admin/CreateCourse", param);
  return data;
};
export const editCourseApi = async (param) => {
  const { data } = await axiosInstance.post("api/v1/Admin/EditCourses", param);
  return data;
};
// ==========

//MAP COURSE
export const mapCourseApi = async (param) => {
  const { data } = await axiosInstance.post("/api/v1/Admin/MapCourse", param);
  return data;
};
export const reMapCourseApi = async (param) => {
  const { data } = await axiosInstance.post(
    "/api/v1/Admin/ReMapCourseMapping",
    param
  );
  return data;
};

//PAYMENTS
export const confirmPaymentApi = async (param) => {
  const { data } = await axiosInstance.post(
    "/api/v1/Auth/ConfirmPayment",
    param
  );
  return data;
};

//TRANSCRIPT MANAGEMENT
//Dispatch hard copy
export const dispatchHardCopyApi = async (param) => {
  const { data } = await axiosInstance.post(
    "/api/v1/Transcript/DispatchHardCopyTranscript",
    param
  );
  return data;
};
// //Dispatch soft copy
// export const dispatchSoftCopyApi = async (param) => {
//   const { data } = await axiosInstance.post(
//     "/api/v1/Transcript/DispatchSoftCopyTranscript",
//     param
//   );
//   return data;
// };
//Flag Transcript
export const flagTranscriptApi = async (param) => {
  const { data } = await axiosInstance.post(
    "/api/v1/Transcript/FlagTranscript",
    param
  );
  return data;
};
