import axios from "axios";

import { baseURL } from "./globalConstant";
import { getItemFromLocalStorage } from "./helper";

const axiosInstance = axios.create({
  baseURL,
});

// Set the AUTH token for any request
axiosInstance.interceptors.request.use(
  async (config) => {
    let accessToken = getItemFromLocalStorage("accessToken");

    if (accessToken) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${accessToken}`,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Refresh Json Web Tokens (JWT)
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response, config } = error;

    let payload = {
      token: getItemFromLocalStorage("accessToken"),
      refreshToken: getItemFromLocalStorage("refreshToken"),
    };

    let accessToken = getItemFromLocalStorage("accessToken");

    if (response?.status === 401) {
      // Use a 'clean' instance of axios without the interceptor to refresh the token. No more infinite refresh loop.
      return axios
        .post("api/Auth/RefreshJWT", payload, {
          baseURL,
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((data) => {
          //Update the token and Authorization header
          if (data?.data?.accessToken) {
            config.headers = {
              ...config.headers,
              authorization: `Bearer ${data?.data?.accessToken}`,
            };
          }

          sessionStorage.setItem(
            "accessToken",
            JSON.stringify(data?.data?.accessToken)
          );
          sessionStorage.setItem(
            "refreshToken",
            JSON.stringify(data?.data?.refreshToken)
          );
          sessionStorage.setItem(
            "tokenExpires",
            JSON.stringify(data?.data?.expires)
          );

          return axiosInstance(config);
        })
        .catch(() => {
          return Promise.reject(error);
        });
    }

    return Promise.reject(error);
  }
);
export default axiosInstance;
