import React, { useContext, useEffect, useState } from "react";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import { toast } from "react-toastify";

import { Button, Select, TextInput } from "../../../../UI";
import { DigitizerContext } from "../../../../contexts/digitizer.context";
import { GeneralContext } from "../../../../contexts/general.context";
import classes from "./ResultSummary.module.css";

const ResultSummary = ({ handleNext, handleBack }) => {
  const [calculatedData, setCalcultedData] = useState({});

  const { degrees, getDegree, degreeClasses, getDegreeClass } =
    useContext(GeneralContext);

  const { saveCGP, userId, calculateCGP, resultSummary } =
    useContext(DigitizerContext);

  useEffect(() => {
    calculateCGP(userId);
    getDegree();
    getDegreeClass();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const {
      totalUnit,
      gpa: weightedgpa,
      cgpa: cummulativeGpa,
      degreeClassId: studentdegreeClass,
      degreeClassName: studentdegreeClassName,
    } = resultSummary;

    setCalcultedData({
      studentId: userId,
      totalUnit,
      weightedgpa,
      cummulativeGpa,
      studentdegreeClass,
      studentdegreeClassName,
    });
    //eslint-disable-next-line
  }, [resultSummary]);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setCalcultedData((prev) => ({ ...prev, [name]: value }));
  };

  const validateInput = () => {
    if (!calculatedData.studentdegree) {
      toast.error("Please select a valid option for each field ");
      return false;
    }
    return true;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validateInput()) {
      delete calculatedData.studentdegreeClassName;
      saveCGP(calculatedData);
    } else {
      return;
    }
  };

  return (
    <div>
      <h2>Result Summary</h2>
      <form className={classes.form} onSubmit={submitHandler}>
        <div className={classes.container}>
          <ul>
            <li className={classes.listItem}>
              <label htmlFor="totalUnits">Total Units Offerred :</label>
              <TextInput
                name="totalUnit"
                className={classes.textInput}
                value={calculatedData.totalUnit ? calculatedData.totalUnit : ""}
                onChange={changeHandler}
              />
            </li>

            <li className={classes.listItem}>
              <label htmlFor="weightedGrade">
                Weighted Grade Point Average :
              </label>
              <TextInput
                name="weightedgpa"
                className={classes.textInput}
                value={
                  calculatedData.weightedgpa
                    ? calculatedData.weightedgpa.toFixed(2)
                    : ""
                }
                onChange={changeHandler}
              />
            </li>

            <li className={classes.listItem}>
              <label htmlFor="cummulativeGrade">
                Cumulative Grade Point Average :
              </label>
              <TextInput
                name="cummulativeGpa"
                className={classes.textInput}
                value={
                  calculatedData.cummulativeGpa
                    ? calculatedData.cummulativeGpa.toFixed(2)
                    : ""
                }
                onChange={changeHandler}
              />
            </li>
            <li className={classes.listItem}>
              <label htmlFor="degreeClass">Degree Class :</label>
              <Select
                name="studentdegreeClass"
                options={[
                  {
                    key: 0,
                    value: "Select Degree Class",
                    disabled: true,
                  },
                  ...degreeClasses,
                ]}
                value={
                  calculatedData.studentdegreeClass
                    ? calculatedData.studentdegreeClass
                    : 0
                }
                onChange={changeHandler}
                placeholder="Select Degree Class"
              />
            </li>
            <li className={classes.listItem}>
              <label htmlFor="degree">Degree :</label>
              <Select
                name="studentdegree"
                options={degrees}
                value={
                  calculatedData.studentdegree
                    ? calculatedData.studentdegree
                    : 0
                }
                onChange={changeHandler}
                required
                placeholder="Select Degree Class"
              />
            </li>
          </ul>
          <ul>
            <li className={classes.listItem}>
              <label htmlFor="totalUnits">
                Total Units Offerred :
                {calculatedData.totalUnit ? calculatedData.totalUnit : ""}
              </label>
            </li>
            <li className={classes.listItem}>
              <label htmlFor="weightedGrade">
                Weighted Grade Point Average :
                {calculatedData.weightedgpa
                  ? calculatedData.weightedgpa.toFixed(2)
                  : ""}
              </label>
            </li>
            <li className={classes.listItem}>
              <label htmlFor="cumulativeGrade">
                Cumulative Grade Point Average :
                {calculatedData.cummulativeGpa
                  ? calculatedData.cummulativeGpa.toFixed(2)
                  : ""}
              </label>
            </li>
            <li className={classes.listItem}>
              <label htmlFor="degreeClass">
                Degree Class :
                {calculatedData.studentdegreeClassName
                  ? calculatedData.studentdegreeClassName
                  : ""}
              </label>
            </li>
            {/* <li className={classes.listItem}>
                            <label htmlFor="degree">
                                Degree : */}
            {/* {calculatedData.degreeName
                                    ? calculatedData.degreeName
                                    : ""} */}
            {/* </label>
                        </li> */}
          </ul>
        </div>
        <div className={classes.buttonContainer}>
          <Button className={classes.backButton} onClick={handleBack}>
            <HiOutlineArrowSmLeft
              style={{
                fontSize: "16px",
                marginInline: "5px",
              }}
            />
            Back
          </Button>
          <Button type="submit">Finish</Button>
        </div>
      </form>
    </div>
  );
};

export default ResultSummary;
