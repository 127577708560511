import React, { createContext, useState } from "react";
import { toast } from "react-toastify";

import { createTranscriptApi } from "../api/Writes";

export const TranscriptRequestContext = createContext(null);

const TranscriptRequestProvider = ({ children }) => {
  const [transcriptInfo, setTranscriptInfo] = useState({});
  const [transcriptOption, setTranscriptOption] = useState();
  const [copyType, setCopyType] = useState();
  const [requestType, setRequestType] = useState();
  const [transcriptOrderNo, setTranscriptOrderNo] = useState("");

  //  create transcript
  const createTranscript = async (param) => {
    try {
      let response = await createTranscriptApi(param);
      if (response.success) {
        toast.success("Transcript saved successfully");
        return response.data.requestOrderNumber;
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { message } = response?.data;
      toast.error(message || "Oops, Something went wrong");
    }
  };

  return (
    <TranscriptRequestContext.Provider
      value={{
        createTranscript,
        transcriptInfo,
        setTranscriptInfo,
        transcriptOption,
        setTranscriptOption,
        copyType,
        setCopyType,
        requestType,
        setRequestType,
        transcriptOrderNo,
        setTranscriptOrderNo,
      }}
    >
      {children}
    </TranscriptRequestContext.Provider>
  );
};
export default TranscriptRequestProvider;
