import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import App from "./App";
import { store } from "./Redux/store";
import CreateCourseProvider from "./contexts/createCourse.context";
import DigitizerProvider from "./contexts/digitizer.context";
import GeneralProvider from "./contexts/general.context";
import HomeProvider from "./contexts/home.context";
import MapCourseProvider from "./contexts/mapCourse.context";
import TranscriptManagementProvider from "./contexts/transcriptManagement.context";
import TranscriptRequestProvider from "./contexts/transcriptRequest";
import VerificationRequestProvider from "./contexts/verificationRequest";
import EnglishProficiencyProvider from "./contexts/englishProficiency";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <GeneralProvider>
          <TranscriptManagementProvider>
            <VerificationRequestProvider>
              <TranscriptRequestProvider>
                <EnglishProficiencyProvider>
                  <MapCourseProvider>
                    <CreateCourseProvider>
                      <DigitizerProvider>
                        <HomeProvider>
                          <App />
                        </HomeProvider>
                      </DigitizerProvider>
                    </CreateCourseProvider>
                  </MapCourseProvider>
                </EnglishProficiencyProvider>
              </TranscriptRequestProvider>
            </VerificationRequestProvider>
          </TranscriptManagementProvider>
        </GeneralProvider>
      </BrowserRouter>
    </Provider>

    <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      draggable
    />
  </StrictMode>
);
