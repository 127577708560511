import React, { useContext, useEffect, useState } from "react";
import { HiOutlineUpload } from "react-icons/hi";
import { ImSpinner3 } from "react-icons/im";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setGenericModalId } from "../../Redux/features/modalsSlice";
import { useLazyUploadPaymentReceiptQuery } from "../../Redux/services/endpoints/transcriptRequest";
import { Button } from "../../UI";
import { Table } from "../../components";
import GenericModal from "../../components/Modals/GenericModal";
import { GeneralContext } from "../../contexts/general.context";
import { HomeContext } from "../../contexts/home.context";
import { ResultStatus } from "../../utils/helper";
import styles from "./styles.module.css";
import classNames from "classnames";

const StudentDashboard = () => {
  const regNo = localStorage.studentId;
  const [isLoading, setIsLoading] = useState(true);
  const [flagged, setFlagged] = useState(false);
  const [orderNo, setOrderNo] = useState("");
  const [reasonInfo, setReasonInfo] = useState([]);
  const [currentTab, setCurrentTab] = useState(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { getMyTranscripts, myTranscripts, getMyProficiency } =
    useContext(HomeContext);
  useEffect(() => {
    if (currentTab === 1) {
      getMyTranscripts(regNo, false);
    } else {
      getMyTranscripts(regNo, true);
    }
    //eslint-disable-next-line
  }, [currentTab]);

  useEffect(() => {
    if (myTranscripts) {
      setIsLoading(false);
      const isFlagged = myTranscripts.some((item) => item.resultStatus === 9);

      setFlagged(isFlagged);
    }
  }, [myTranscripts]);

  const handleViewReason = (arg) => {
    const temp = arg.map((item) => ({
      date: item.createdAt,
      reason: item.reason,
    }));
    setReasonInfo(temp);
    dispatch(setGenericModalId("StudentTranscriptReason"));
  };

  const handleUploadReceipt = (arg) => {
    setOrderNo(arg);
    dispatch(setGenericModalId("StudentTranscriptUploadReceipt"));
  };

  const handlePay = (orderNo, resultType) => {
    if (resultType === 1) {
      navigate(
        `/dashboard/transcript-request/pay?order-no=${orderNo}&fee-type=1&result-type=1`
      );
    } else {
      navigate(
        `/dashboard/transcript-request/pay?order-no=${orderNo}&fee-type=1&result-type=2`
      );
    }
  };

  const columns = [
    { value: "Serial ID" },
    { value: "Order Number" },
    { value: "Full Name" },
    { value: "Request Date" },
    { value: "Matric No." },
    { value: "Shipping Address" },
    { value: "Destination Email" },
    { value: "Status" },
    { value: "Ready in" },
    { value: "Pay" },

    { value: flagged ? "Reason" : null },
    { value: flagged ? "Action" : null },
  ];

  const data = myTranscripts?.map((item, index) => ({
    "Serial ID": index + 1,
    "Order Number": item.requestOrderNumber,
    "Full Name": item.student.user.fullName,
    "Request Date": item.createdAt.split("T")[0],
    "Matric No.": item.regNumber,
    "Shipping Address": item.address1,
    "Destination Email": item.destinationEmail,
    Status: ResultStatus[item.resultStatus],
    "Ready in": item.expectationDate + " working days",
    Pay:
      item.resultStatus === 0 ? (
        <button
          onClick={() => handlePay(item.requestOrderNumber, item.resultType)}
        >
          Pay Now
        </button>
      ) : null,
    Reason:
      item.resultStatus === 9 ? (
        <p
          className="text-blue-600 underline cursor-pointer text-sm"
          onClick={() => handleViewReason(item?.audits)}
        >
          View reason
        </p>
      ) : null,
    Upload:
      item.resultStatus === 9 ? (
        <button onClick={() => handleUploadReceipt(item.requestOrderNumber)}>
          Upload receipt
        </button>
      ) : null,
  }));

  return (
    <div className="grid w-full h-full">
      <div className={styles.tab_container}>
        <div
          className={classNames(
            styles.tab_item,
            currentTab === 1 && styles.active
          )}
          onClick={() => setCurrentTab(1)}
        >
          Transcripts
        </div>
        <div
          className={classNames(
            styles.tab_item,
            currentTab === 2 && styles.active
          )}
          onClick={() => setCurrentTab(2)}
        >
          Proficiency
        </div>
      </div>

      {isLoading ? (
        <ImSpinner3 className="animate-spin place-self-center" size={32} />
      ) : (
        <>
          {currentTab === 1 && (
            <Table columns={columns} data={data} title="My Transcripts" />
          )}
          {currentTab === 2 && (
            <Table
              columns={columns}
              data={data}
              title="My Proficiency in English"
            />
          )}
        </>
      )}
      <ReasonModal reason={reasonInfo} />
      <UploadModal orderNo={orderNo} />
    </div>
  );
};

export default StudentDashboard;

const ReasonModal = ({ reason }) => {
  return (
    <GenericModal id="StudentTranscriptReason">
      <div className="my-4 max-w-[400px]">
        {/* {reason?.map((item) => (
          <div className="my-4">
            <p>
              {item.date.split("T")[0]}: {item.reason}
            </p>
          </div>
        ))} */}
        <div className="my-4">
          <p>
            {reason[0]?.date.split("T")[0]}: {reason[0]?.reason}
          </p>
        </div>
      </div>
    </GenericModal>
  );
};

const UploadModal = ({ orderNo }) => {
  const { uploadFile } = useContext(GeneralContext);
  const [receiptUrl, setReceiptUrl] = useState("");
  const [documentName, setDocumentName] = useState("");

  const dispatch = useDispatch();

  const queryParams = {
    ClearanceORpaymentRecieptsUrl: receiptUrl,
    RequestOrderNumber: orderNo,
  };
  const [uploadReceipt] = useLazyUploadPaymentReceiptQuery();

  const onFileChange = async (e) => {
    const { files } = e.target;
    const documentUrl = await uploadFile(files[0]);
    setReceiptUrl(documentUrl);
    setDocumentName(files[0].name);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    uploadReceipt(queryParams);
    dispatch(setGenericModalId(null));
    setReceiptUrl();
    setDocumentName();
  };

  return (
    <GenericModal id="StudentTranscriptUploadReceipt">
      <div className="my-4 max-w-[400px]">
        <form onSubmit={handleSubmit} className="flex flex-col gap-6">
          <div className="flex flex-col gap-3 w-full relative">
            <div>
              <p className="text-sm">Upload Payment Receipt:</p>
              <p className="text-xs text-red-700">
                *Please name your receipt properly eg: acceptance fee receipt.
              </p>
            </div>
            <label
              htmlFor="transcriptDocument"
              className="bg-primary p-2 rounded-md flex items-center text-white gap-3 w-fit"
            >
              <HiOutlineUpload /> Upload file
            </label>
            <input
              type="file"
              id="transcriptDocument"
              onChange={onFileChange}
              name="resultUrl"
              required
              className="absolute bottom-0 -z-[1] opacity-0"
            />
            <p className="text-xs">{documentName}</p>
          </div>
          <div>
            <Button type="submit">Save</Button>
          </div>
        </form>
      </div>
    </GenericModal>
  );
};
