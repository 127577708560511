import React, { useState } from "react";

import { Card } from "../../../UI";
import { TabPage } from "../../../components";
import StepperComponent from "../../../components/Stepper/Stepper";
import AlumniData from "./AlumniData/AlumniData";
import CheckAlumni from "./CheckAlumni/CheckAlumni";
import Courses from "./Courses/Courses";
import styles from "./Digitize.module.css";
import ResultSummary from "./ResultSummary/ResultSummary";

const Digitize = () => {
  const steps = ["one", "two", "three", "four"];
  const [activeStep, setActiveStep] = useState(0);

  const [courseData, setCourseData] = useState({});

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <TabPage pageTitle="Digitizer/ Digitize">
      <StepperComponent steps={steps} activeStep={activeStep} width={"50%"} />
      <Card className={styles.card}>
        {activeStep === 0 && <CheckAlumni onClick={handleNext} />}
        {activeStep === 1 && (
          <AlumniData handleBack={handleBack} handleNext={handleNext} />
        )}
        {activeStep === 2 && (
          <Courses
            handleBack={handleBack}
            handleNext={handleNext}
            courseData={courseData}
            setCourseData={setCourseData}
          />
        )}
        {activeStep === 3 && <ResultSummary handleBack={handleBack} />}
      </Card>
    </TabPage>
  );
};

export default Digitize;
