import { Step, StepLabel, Stepper } from "@mui/material";
import React, { useState } from "react";

import { Card } from "../../UI";
import styles from "./CertificateRequest.module.css";
import DestinationInfo from "./DestinationInfo/DestinationInfo";
import ShippingOption from "./ShippingOption/ShippingOption";

const CertificateRequest = () => {
  const steps = ["one", "two"];
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <div className={styles.mainBodyContainer}>
      <div className={styles.pageTitle}>
        <h2>Certificate</h2>
      </div>
      <div className={styles.container}>
        <div className={styles.stepper__container}>
          <Stepper
            sx={{
              width: "40%",
              margin: "25px auto",
              "& .MuiStepIcon-text ,.MuiStepLabel-label": {
                fontSize: "14px",
              },
              "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root": {
                fontSize: "20px",
              },
              "& .css-z7uhs0-MuiStepConnector-line": {
                borderTopWidth: "3px",
              },

              "& .css-1bw0nnu-MuiStep-root": {
                paddingInline: "3px",
              },
              "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed,.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active":
                {
                  color: "#146d17",
                },
              "& .css-vnkopk-MuiStepLabel-iconContainer": {
                paddingInline: "2px",
              },
              "& .MuiStepLabel-label": {
                display: "none",
              },
            }}
            activeStep={activeStep}
          >
            {steps.map((label) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>

        <Card className={styles.card}>
          {activeStep === 0 && <DestinationInfo handleNext={handleNext} />}
          {activeStep === 1 && (
            <ShippingOption
              // handleNext={handleNext}
              handleBack={handleBack}
            />
          )}
        </Card>
      </div>
    </div>
  );
};

export default CertificateRequest;
