import React from "react";

const TabPage = ({ children, pageTitle }) => {
  return (
    <div className="w-full h-[90vh] flex flex-col relative">
      <div className="w-full px-5 py-3 bg-white sticky top-0 z-[1] border-t-[1px] border-slate-950 border-solid shadow-sm">
        <h2 className="font-medium text-lg">{pageTitle}</h2>
      </div>
      <div className="w-full flex-grow p-4 md:p-8 flex flex-col overflow-auto">
        {children}
      </div>
    </div>
  );
};

export default TabPage;
