import React, { createContext, useState } from "react";
import { toast } from "react-toastify";

import { getMyProficiencyApi, getMyTranscriptsApi } from "../api/Reads";

export const HomeContext = createContext();

const HomeProvider = ({ children }) => {
  const [myTranscripts, setMyTranscripts] = useState();
  const [myProficiency, setMyProficiency] = useState();
  const [regNoFromRedirect, setRegNoFromRedirect] = useState("");

  //MY REQUESTS
  const getMyTranscripts = async (studentId, isProficiency) => {
    try {
      let response = await getMyTranscriptsApi(studentId, isProficiency);
      if (response.success) {
        setMyTranscripts(response.data);
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { title } = response?.data;
      toast.error(title || "Oops, Something went wrong");
    }
  };

  const getMyProficiency = async (studentId) => {
    try {
      let response = await getMyProficiencyApi(studentId);
      if (response.success) {
        setMyProficiency(response.data);
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { title } = response?.data;
      toast.error(title || "Oops, Something went wrong");
    }
  };

  return (
    <HomeContext.Provider
      value={{
        myTranscripts,
        myProficiency,
        getMyTranscripts,
        regNoFromRedirect,
        setRegNoFromRedirect,
        getMyProficiency,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};
export default HomeProvider;
