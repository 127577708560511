import { isValidPhoneNumber } from "libphonenumber-js";
import React, { useContext, useEffect, useState } from "react";
import {
  HiOutlineArrowNarrowLeft,
  HiOutlineArrowNarrowRight,
} from "react-icons/hi";
import { ImSpinner3 } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Button, Select, TextInput } from "../../UI";
import InputPhone from "../../UI/PhoneInput/PhoneInput";
import Image from "../../asset/SignupImage.webp";
import StepperComponent from "../../components/Stepper/Stepper";
import { GeneralContext } from "../../contexts/general.context";

const CreateProfilePage = () => {
  const steps = ["one", "two"];
  const [activeStep, setActiveStep] = useState(0);

  const {
    userProfile,
    setUserProfile,
    getFaculty,
    getDegree,
    programme,
    getDepartment,
    getCampuses,
    getSession,
    sessions,
    setSessions,
    getProgramme,
  } = useContext(GeneralContext);

  useEffect(() => {
    getProgramme();
    programme?.key && getFaculty(programme.key);
    getDegree();
    getCampuses();
    getSession();
    //eslint-disable-next-line
  }, []);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setUserProfile((prev) => ({ ...prev, [name]: value }));

    if (name === "programmeId") getFaculty(value);
    if (name === "facultyId") getDepartment(value);
  };

  const changeSessionDescription = () => {
    let newSession = sessions;
    newSession[0].value = "Select admission year";
    setSessions(newSession);
  };

  const validatePhoneNumber = (phoneNumber) => {
    if (!userProfile.phoneNo || !isValidPhoneNumber(phoneNumber)) {
      toast.error("Please enter a valid number");
      return false;
    }
    return true;
  };

  const validateEmail = (email) => {
    return /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(email);
  };

  const handleNext = () => {
    if (!validatePhoneNumber(userProfile.phoneNo)) return;
    validateEmail(userProfile.emailAddress);
    if (!validateEmail(userProfile.emailAddress)) {
      toast.error("Please enter a valid email");
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    changeSessionDescription();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <main className="grid grid-cols-1 md:grid-cols-2 h-screen">
      <div className="hidden md:block">
        <img src={Image} alt="Students" className="h-full object-cover" />
      </div>
      <div className="grid p-8 overflow-auto">
        <span className="grid gap-4 mb-4">
          <h2 className="font-semibold text-2xl text-center">
            Please Complete this form to create your profile
          </h2>
          <StepperComponent
            steps={steps}
            activeStep={activeStep}
            width={"50%"}
          />
        </span>

        {activeStep === 0 ? (
          <PersonalInfo
            handleInput={handleInput}
            handleNext={handleNext}
            userProfile={userProfile}
          />
        ) : (
          <AcademicInfo
            handleBack={handleBack}
            handleInput={handleInput}
            userProfile={userProfile}
          />
        )}
      </div>
    </main>
  );
};

export default CreateProfilePage;

const PersonalInfo = ({ handleNext: onClick, handleInput, userProfile }) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();

  const handleTogglePassword = () => {
    setPasswordShown((prev) => !prev);
  };

  const handleNext = (e) => {
    e.preventDefault();
    onClick();
  };

  const handleBack = () => {
    navigate("/request");
  };
  return (
    <form
      className="max-w-screen-sm w-full sm:w-4/5 mx-auto grid gap-4"
      onSubmit={handleNext}
    >
      <p>Personal Information</p>
      <TextInput
        name="lastName"
        helperText="Please maintain surname as at registration"
        required
        placeholder="Surname"
        onChange={handleInput}
        value={userProfile.lastName ? userProfile.lastName : ""}
      />
      <TextInput
        name="firstName"
        required
        placeholder="First Name"
        onChange={handleInput}
        value={userProfile.firstName ? userProfile.firstName : ""}
      />
      <TextInput
        name="otherName"
        placeholder="Middle Name"
        onChange={handleInput}
        value={userProfile.otherName ? userProfile.otherName : ""}
      />
      <InputPhone
        name="phoneNo"
        onChange={handleInput}
        value={userProfile.phoneNo ? userProfile.phoneNo : ""}
      />
      <TextInput
        name="emailAddress"
        helperText="Please ensure this is a valid email"
        required
        placeholder="Email"
        type="email"
        onChange={handleInput}
        value={userProfile.emailAddress ? userProfile.emailAddress : ""}
      />
      <TextInput
        name="password"
        placeholder="Password"
        helperText="Password must contain an alphabet, number and not less than 6 digits"
        pattern="(?=.*\d)(?=.*[a-zA-Z]).{6,}"
        required
        placeh-older="password"
        type={passwordShown ? "text" : "password"}
        onChange={handleInput}
        value={userProfile.password ? userProfile.password : ""}
      />
      <div className="flex gap-2">
        <input
          type="checkbox"
          name="show-password"
          onChange={handleTogglePassword}
        />
        <p className="text-sm">Show Password</p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <Button className="flex gap-4" onClick={handleBack} variant="invert">
          <HiOutlineArrowNarrowLeft />
          Back
        </Button>
        <Button type="submit" className="flex gap-4">
          Next
          <HiOutlineArrowNarrowRight />
        </Button>
      </div>
    </form>
  );
};

const AcademicInfo = ({ handleBack, userProfile, handleInput }) => {
  const [checked, setChecked] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const navigate = useNavigate();

  const {
    createProfile,
    setUserProfile,
    faculties,
    getFaculty,
    getDegree,
    degrees,
    regNo,
    programme,
    departments,
    getCampuses,
    campuses,
    getSession,
    sessions,
    programmes,
    getProgramme,
  } = useContext(GeneralContext);

  useEffect(() => {
    getProgramme();
    programme?.key && getFaculty(programme.key);
    getDegree();
    getCampuses();
    getSession();
    //eslint-disable-next-line
  }, []);

  const saveProfile = async (e) => {
    e.preventDefault();
    try {
      programme?.key && (userProfile.programmeId = programme.key);
      if (!userProfile.regNumber) userProfile.regNumber = regNo;

      if (checked && validateInput()) {
        setIsPending(true);
        const redirect = await createProfile(userProfile);
        setIsPending(false);
        setUserProfile({});
        redirect && navigate("/login");
      } else {
        toast.error("Please agree to terms");
        return;
      }
    } catch (err) {
      setIsPending(false);
    }
  };

  const handleCheckBox = () => {
    setChecked((current) => !current);
  };

  const validateInput = () => {
    if (
      !userProfile.admissionSession ||
      !userProfile.degreeId ||
      !userProfile.facultyId ||
      !userProfile.departmentId ||
      !userProfile.campusId ||
      !userProfile.programmeId
    ) {
      toast.error("Please select a valid option for each Dropdown ");
      return false;
    }
    return true;
  };

  return (
    <form
      onSubmit={saveProfile}
      className="max-w-screen-sm w-full sm:w-4/5 mx-auto grid gap-4"
    >
      <p>Academic Information</p>
      <TextInput
        name="regNumber"
        value={
          userProfile.regNumber ?? regNo ? userProfile.regNumber ?? regNo : ""
        }
        required={true}
        placeholder="Matriculation Number"
        onChange={handleInput}
      />
      {!programme ? (
        <Select
          name="programmeId"
          placeholder="Programme"
          value={userProfile.programmeId ? userProfile.programmeId : ""}
          options={programmes}
          onChange={handleInput}
          required
        />
      ) : null}
      <Select
        name="admissionSession"
        placeholder="Admission Year"
        required={true}
        options={sessions}
        onChange={handleInput}
        value={userProfile.admissionSession ? userProfile.admissionSession : ""}
      />
      <Select
        name="degreeId"
        placeholder="Degree"
        required
        options={degrees}
        onChange={handleInput}
        value={userProfile.degreeId ? userProfile.degreeId : ""}
      />
      <Select
        name="facultyId"
        placeholder="Faculty"
        required
        options={faculties}
        onChange={handleInput}
        value={userProfile.facultyId ? userProfile.facultyId : ""}
      />
      <Select
        name="departmentId"
        placeholder="Department"
        required
        options={departments}
        onChange={handleInput}
        value={userProfile.departmentId ? userProfile.departmentId : ""}
      />
      <Select
        name="campusId"
        placeholder="Campus"
        required
        options={campuses}
        onChange={handleInput}
        value={userProfile.campusId ? userProfile.campusId : ""}
      />
      <div className="flex gap-2">
        <input type="checkbox" required onChange={handleCheckBox} />
        <p className="text-sm">Agree to terms</p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <Button className="flex gap-2" onClick={handleBack} variant="invert">
          <HiOutlineArrowNarrowLeft />
          Back
        </Button>
        <Button type="submit">
          {isPending ? (
            <ImSpinner3 className="animate-spin" size={22} />
          ) : (
            <span>Create Profile</span>
          )}
        </Button>
      </div>
    </form>
  );
};
