import CircularProgress from "@mui/material/CircularProgress";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { Card } from "../../../UI";
import { TabPage, Table } from "../../../components";
import { DigitizerContext } from "../../../contexts/digitizer.context";
import { TranscriptManagementContext } from "../../../contexts/transcriptManagement.context";
import classes from "./UndigitizedRequest.module.css";

const UndigitizedRequest = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isPending, setIsPending] = useState({});

  const {
    requireDigitizationTranscripts,
    getRequireDigitizationTranscripts,
    markTranscriptAsRequireReview,
  } = useContext(TranscriptManagementContext);

  const { setRegNoFromRedirect } = useContext(DigitizerContext);

  const navigate = useNavigate();

  useEffect(() => {
    getRequireDigitizationTranscripts();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (requireDigitizationTranscripts) setIsLoading(false);

    requireDigitizationTranscripts?.forEach((item) =>
      setIsPending((prev) => ({ ...prev, [item.requestOrderNumber]: false }))
    );
  }, [requireDigitizationTranscripts]);

  const handleChange = () => {};

  const handleDigitizeButtonClick = async (regNo) => {
    await setRegNoFromRedirect(regNo);
    navigate("/dashboard/digitizer-digitize");
  };

  const handleConfirmButtonClick = async (orderNo) => {
    setIsPending((prev) => ({ ...prev, [orderNo]: true }));
    await markTranscriptAsRequireReview(orderNo);
    await getRequireDigitizationTranscripts();
    setIsPending((prev) => ({ ...prev, [orderNo]: false }));
  };

  const columns = [
    {
      value: (
        <>
          <input type="checkbox" name="checkbox" id="checkbox" />
          <span>Serial ID</span>
        </>
      ),
    },
    { value: "Full Name" },
    { value: "Request Date" },
    { value: "Matric No." },
    { value: "Shipping Address" },
    { value: "Destination Email" },
    { value: "" },
    { value: "" },
  ];

  const data = requireDigitizationTranscripts?.map((item) => ({
    "Serial ID": (
      <>
        <input type="checkbox" onChange={handleChange} />
        <span>3889974638</span>
      </>
    ),
    "Full Name": item.student.user.fullName,
    "Request Date": item.createdAt.split("T")[0],
    "Matric No.": item.regNumber,
    "Shipping Address": item.address1,
    "Destination Email": item.destinationEmail,
    Digitize: (
      <button
        onClick={() => handleDigitizeButtonClick(item.regNumber)}
        title="Digitize"
      >
        Digitize
      </button>
    ),
    Confirm: (
      <button
        onClick={() => handleConfirmButtonClick(item.requestOrderNumber)}
        title="Confirm"
      >
        {isPending[item.requestOrderNumber] ? (
          <CircularProgress />
        ) : (
          <span>Confirm</span>
        )}
      </button>
    ),
  }));

  return (
    <TabPage pageTitle="Digitizer/ Undigitized Requests">
      <Card className={classes.card}>
        {isLoading ? (
          <p>
            Loading Requests <CircularProgress />
          </p>
        ) : (
          <Table columns={columns} data={data} />
        )}
      </Card>
    </TabPage>
  );
};

export default UndigitizedRequest;
