import React from "react";
import Button from "../../../UI/Button/Button";
import styles from "./ShippingOption.module.css";
import { HiOutlineArrowSmLeft, HiOutlineArrowSmRight } from "react-icons/hi";

const ShippingOption = ({ handleBack }) => {
    return (
        <div className={styles.container}>
            <p className={styles.heading}>Shipping Options</p>
            <p className={styles.subHeading}>
                Please select a shipping company for each request.
            </p>
            <div className={styles.optionsContainer}>
                <div>
                    <img src="/images/dhl-logo.png" alt="dhl-logo" />
                    <p>
                        <di></di> N6000
                    </p>
                </div>
                <div>
                    <img src="/images/fedx-logo.png" alt="" />
                    <p> N5000</p>
                </div>
                <div>
                    <img src="/images/UPS-logo.png" alt="" />
                    <p> N6500</p>
                </div>
                <div>
                    <img src="/images/courierp-logo.png" alt="" />
                    <p> N4000</p>
                </div>
            </div>
            <div className={styles.buttonContainer}>
                <Button className={styles.backButton} onClick={handleBack}>
                    <HiOutlineArrowSmLeft
                        style={{
                            fontSize: "16px",
                            marginInline: "5px",
                        }}
                    />
                    Back
                </Button>
                <Button>
                    Proceed{" "}
                    <HiOutlineArrowSmRight
                        style={{ fontSize: "16px", marginInline: "5px" }}
                    />
                </Button>
            </div>
        </div>
    );
};

export default ShippingOption;
