import React, { useContext } from "react";
import { HiMenu, HiOutlineLogout } from "react-icons/hi";
import { useDispatch } from "react-redux";

import {
  setConfirmationModalId,
  setSidebarShown,
} from "../../../Redux/features/modalsSlice";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import { GeneralContext } from "../../../contexts/general.context";
import classes from "./Header.module.css";

const Header = () => {
  const dispatch = useDispatch();
  const { logoutCustomer } = useContext(GeneralContext);

  const handleLogout = () => {
    logoutCustomer();
    dispatch(setConfirmationModalId(null));
  };

  const showSidebar = () => {
    dispatch(setSidebarShown(true));
  };

  return (
    <nav className={classes.container}>
      <ul className={classes.nav}>
        <li className="hidden md:block">Welcome User!</li>
        <li className="block md:hidden" onClick={showSidebar}>
          <HiMenu size={20} />
        </li>
        <li
          className={classes.link}
          onClick={() => dispatch(setConfirmationModalId("Logout"))}
        >
          <HiOutlineLogout size={20} /> Sign out
        </li>
      </ul>
      <ConfirmationModal
        title="Logout"
        description="Are you sure you want to logout?"
        onClick={handleLogout}
        id="Logout"
      />
    </nav>
  );
};

export default Header;
