import React, { useState } from "react";
import { Card } from "../../UI";
import DestinationInfo from "./DestinationInfo/DestinationInfo";
import styles from "./ProficiencyInEnglish.module.css";
import RequestTypeForm from "./RequestTypeForm/RequestTypeForm";
import ShippingOption from "./ShippingOption/ShippingOption";
import StepperContainer from "./StepperContainer";

const ProficiencyInEnglish = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className={styles.mainBodyContainer}>
      <div className={styles.pageTitle}>
        <h2>Proficiency</h2>
      </div>
      <div className={styles.container}>
        <StepperContainer activeStep={activeStep} />
        <Card className={styles.card}>
          {activeStep === 0 && <RequestTypeForm handleNext={handleNext} />}
          {activeStep === 1 && (
            <DestinationInfo handleNext={handleNext} handleBack={handleBack} />
          )}
          {activeStep === 2 && (
            <ShippingOption handleNext={handleNext} handleBack={handleBack} />
          )}
        </Card>
      </div>
    </div>
  );
};

export default ProficiencyInEnglish;
