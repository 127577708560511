import CircularProgress from "@mui/material/CircularProgress";
import React, { useContext, useEffect, useState } from "react";

import { Card } from "../../../UI";
import { TabPage, Table } from "../../../components";
import { TranscriptManagementContext } from "../../../contexts/transcriptManagement.context";
import classes from "./AwaitingDigitization.module.css";

const AwaitingDigitization = () => {
  const [isLoading, setIsLoading] = useState(true);

  const { requireDigitizationTranscripts, getRequireDigitizationTranscripts } =
    useContext(TranscriptManagementContext);

  useEffect(() => {
    getRequireDigitizationTranscripts();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (requireDigitizationTranscripts) setIsLoading(false);
  }, [requireDigitizationTranscripts]);

  const columns = [
    { value: "Serial ID" },
    { value: "Full Name" },
    { value: "Request Date" },
    { value: "Matric No." },
    { value: "Shipping Address" },
    { value: "Destination Email" },
  ];

  const data = requireDigitizationTranscripts?.map((item) => ({
    "Serial ID": item.requestOrderNumber,
    "Full Name": item.student.user.fullName,
    "Request Date": item.createdAt.split("T")[0],
    "Matric No.": item.regNumber,
    "Shipping Address": item.address1,
    "Destination Email": item.destinationEmail,
  }));

  return (
    <TabPage pageTitle="Transcript Management/ Awaiting Digitization">
      <Card className={classes.card}>
        {isLoading ? (
          <p>
            Loading Requests <CircularProgress />
          </p>
        ) : (
          <Table columns={columns} data={data} />
        )}
      </Card>
    </TabPage>
  );
};

export default AwaitingDigitization;
