import React, { useState } from "react";
import { HiOutlineArrowSmRight } from "react-icons/hi";

import { DocumentSelect } from "./DocumentSelect";
import { Form } from "./Form";
import { ProvidedInformation } from "./ProvidedInformation";

export const forwardIcon = (
  <HiOutlineArrowSmRight style={{ fontSize: "20px", marginInline: "5px" }} />
);

const DestinationInfo = ({ handleNext, handleBack }) => {
  const [currentStep, setCurrentStep] = useState(0);

  return (
    <>
      {currentStep === 0 && (
        <Form onSubmit={setCurrentStep} handleBack={handleBack} />
      )}
      {currentStep === 1 && <DocumentSelect setCurrentStep={setCurrentStep} />}
      {currentStep >= 2 && (
        <ProvidedInformation onClick={handleNext} handleBack={setCurrentStep} />
      )}
    </>
  );
};

export default DestinationInfo;
