import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React, { useContext, useEffect } from "react";
import { toast } from "react-toastify";

import { Button, Select, TextInput } from "../../../../UI";
import { DigitizerContext } from "../../../../contexts/digitizer.context";
import { GeneralContext } from "../../../../contexts/general.context";
import { MapCourseContext } from "../../../../contexts/mapCourse.context";
import classes from "./Courses.module.css";

export const CourseForm = ({ setModalShown, courseData, setCourseData }) => {
  //getting global states and api functions from the context
  const {
    levels,
    getLevel,
    sessions,
    getSession,
    gradeSystems,
    getGradeSystem,
    semesters,
    getSemester,
    grades,
  } = useContext(GeneralContext);
  const { mappedCourses, getMappedCourses } = useContext(MapCourseContext);

  const {
    updateCourse,
    userId,
    studentGrade,
    getGradesByScore,
    getExistingCourses,
    getGrade,
  } = useContext(DigitizerContext);

  //calling api functions when component mounts to get dropdown options from context
  useEffect(() => {
    getSemester();
    getLevel();
    getSession();
    getMappedCourses();
    getGradeSystem();
    // eslint-disable-next-line
  }, []);

  //handle calling getGrade when score and gradeysytem provided
  useEffect(() => {
    if (courseData.gradeSystemId) {
      getGrade(courseData.gradeSystemId);
    }
    if (
      courseData.gradeSystemId === undefined ||
      courseData.score === undefined
    )
      return;
    const timer = setTimeout(() => {
      getGradesByScore(courseData.gradeSystemId, courseData.score);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [courseData.gradeSystemId, courseData.score]);

  //handle updating student grade to coursedata
  useEffect(() => {
    setCourseData((prev) => ({ ...prev, gradeId: studentGrade }));
    // eslint-disable-next-line
  }, [studentGrade]);

  //modal close handler
  const closeHandler = () => {
    setCourseData({});
    setModalShown(false);
  };

  //valiadte fields
  const validateInput = () => {
    if (
      !courseData.sessionId ||
      !courseData.levelId ||
      !courseData.semesterId ||
      !courseData.courseMappingId ||
      !courseData.gradeSystemId ||
      !courseData.gradeId
    ) {
      toast.error("Please select a valid option for each field");
      return false;
    }
    return true;
  };

  //form submit handler
  const submitHandler = async (e) => {
    e.preventDefault();
    //validate input
    if (validateInput()) {
      //adding a new course / updating course
      await updateCourse({ ...courseData, userId: userId });

      //updating the existingCourses state in the parent component
      getExistingCourses(userId);

      //restting logic
      setCourseData({});

      //closing modal
      setModalShown(false);
    } else {
      return;
    }
  };

  //Input change handler
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setCourseData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <div className={classes.modal}>
        <HighlightOffIcon
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            color: "#146d17",
            cursor: "pointer",
            fontSize: "20px",
          }}
          onClick={closeHandler}
        />
        <h2>Enter course details</h2>
        <form onSubmit={submitHandler}>
          <Select
            options={[
              {
                key: "0",
                // selected: true,
                disabled: true,
                value: "Session",
              },
              ...sessions,
            ]}
            name={"sessionId"}
            onChange={changeHandler}
            required
            value={courseData.sessionId ? courseData.sessionId : 0}
            placeholder="Select session"
          />
          <Select
            options={[
              {
                key: "0",
                // selected: true,
                disabled: true,
                value: "Level",
              },
              ...levels,
            ]}
            name={"levelId"}
            onChange={changeHandler}
            required
            value={courseData.levelId ? courseData.levelId : 0}
            placeholder="select level"
          />
          <Select
            options={[
              {
                key: "0",
                // selected: true,
                disabled: true,
                value: "Semester",
              },
              ...semesters,
            ]}
            name={"semesterId"}
            onChange={changeHandler}
            required
            value={courseData.semesterId ? courseData.semesterId : 0}
            placeholder="select semester"
          />
          <Select
            options={[
              {
                key: "0",
                // selected: true,
                disabled: true,
                value: "Course",
              },
              ...mappedCourses,
            ]}
            name={"courseMappingId"}
            onChange={changeHandler}
            required
            value={courseData.courseMappingId ? courseData.courseMappingId : 0}
            placeholder="select course"
          />

          <Select
            options={[
              {
                key: "0",
                // selected: true,
                disabled: true,
                value: "Grade Point",
              },
              ...gradeSystems,
            ]}
            name={"gradeSystemId"}
            onChange={changeHandler}
            required
            value={courseData.gradeSystemId ? courseData.gradeSystemId : 0}
            placeholder="select grade point"
          />
          <TextInput
            placeholder={"score"}
            type="number"
            name={"score"}
            onChange={changeHandler}
            required
            value={courseData.score ? courseData.score : ""}
            // readOnly={true}
          />
          <Select
            options={[
              {
                key: "0",
                // selected: true,
                disabled: true,
                value: "Grade ",
              },
              ...grades,
            ]}
            name={"gradeId"}
            onChange={changeHandler}
            required
            value={courseData.gradeId ? courseData.gradeId : 0}
            placeholder="select grade"
            readOnly={true}
          />
          <Button type="submit">Save</Button>
        </form>
      </div>
      <div className={classes.overlay}></div>
    </>
  );
};
