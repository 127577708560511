import React, { useContext, useRef } from "react";
import { useEffect, useState } from "react";

import { Button, TextInput } from "../../../../UI";
import { DigitizerContext } from "../../../../contexts/digitizer.context";

const CheckAlumni = ({ onClick }) => {
  const { verifyStudent, regNoFromRedirect } = useContext(DigitizerContext);

  const inputRef = useRef();
  const controlRedirect = useRef(false);

  useEffect(() => {
    if (controlRedirect.current === false) {
      if (regNoFromRedirect) {
        verifyStudent(regNoFromRedirect);
        onClick();
      }
      return () => (controlRedirect.current = true);
    }
    //eslint-disable-next-line
  }, []);

  const submitHandler = () => {
    verifyStudent(inputRef.current.value);
    onClick();
  };
  return (
    <form>
      <p>check alumni</p>
      <TextInput
        placeholder="Matric Number"
        required
        ref={inputRef}
        name="regNo"
      />
      <Button onClick={submitHandler}>next</Button>
    </form>
  );
};

export default CheckAlumni;
