import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React, { useContext } from "react";

import { Button, TextInput } from "../../../UI";
import { CreateCourseContext } from "../../../contexts/createCourse.context";
import { GeneralContext } from "../../../contexts/general.context";
import classes from "../CreateCourse.module.css";

export function CreateCourseForm({ setModalShown, courseData, setCourseData }) {
  const { getCourse } = useContext(GeneralContext);
  const { registerCourse, editCourse } = useContext(CreateCourseContext);

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setCourseData((prev) => ({ ...prev, [name]: value }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (courseData.courseId) {
      await editCourse(courseData);
    } else {
      await registerCourse(courseData);
    }
    getCourse();
    setCourseData({});
    setModalShown(false);
  };

  const closeHandler = () => {
    setCourseData({});
    setModalShown(false);
  };

  return (
    <>
      <div className={classes.modal}>
        <HighlightOffIcon
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            color: "#146d17",
            cursor: "pointer",
            fontSize: "20px",
          }}
          onClick={closeHandler}
        />
        <h2>Enter course details</h2>
        <form onSubmit={submitHandler}>
          <TextInput
            name="courseName"
            placeholder="name"
            onChange={inputChangeHandler}
            value={courseData.courseName || ""}
          />
          <TextInput
            name="courseCode"
            placeholder="course Code"
            onChange={inputChangeHandler}
            value={courseData.courseCode || ""}
          />
          <TextInput
            name="unitLoad"
            placeholder="unit"
            onChange={inputChangeHandler}
            value={courseData.unitLoad || ""}
            type="number"
          />
          <Button type="submit">Create Course</Button>
        </form>
      </div>
      <div className={classes.overlay}></div>
    </>
  );
}
