import { NavLink, useNavigate } from "react-router-dom";

import Logo from "../../../asset/GoUni Logo.jpg";
import { SidebarLinks } from "../../../utils/helper";

const SideBar = () => {
  const navigate = useNavigate();

  const userRole = JSON.parse(localStorage.userRoles);

  return (
    <aside className="md:flex flex-col flex-shrink-0 gap-3 py-6 w-[220px] bg-primary hidden">
      <div
        onClick={() => navigate("/")}
        className="flex items-center gap-3 px-5"
      >
        <img src={Logo} alt="logo" className="w-10 h-10 rounded-full" />
        <div className="text-sm text-white">
          <p>Godfrey Okoye</p>
          <p>University</p>
        </div>
      </div>
      {SidebarLinks.map((item) =>
        item.role.some((role) => userRole.includes(role)) ? (
          <div key={item.title} className="flex flex-col gap-3 mt-5">
            <p className="text-white/80 px-5 text-xs">{item.title}</p>
            {item.links.map((link) =>
              link.role.some((role) => userRole.includes(role)) ? (
                <NavLink
                  key={link.name}
                  to={`/dashboard/${link.to}`}
                  onClick={() => {}}
                  className={({ isActive }) =>
                    isActive
                      ? "px-5 py-3 text-white bg-accent/20 border-l-[5px] border-solid border-accent"
                      : "px-5 py-3 text-white/60"
                  }
                >
                  {/* <img src={link.icon} alt="icon" /> */}
                  <p>{link.name}</p>
                </NavLink>
              ) : null
            )}
          </div>
        ) : null
      )}
      <p className="mt-auto text-white text-center">Powered by Meksoft</p>
    </aside>
  );
};

export default SideBar;
