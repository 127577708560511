import React, { useContext, useEffect, useState } from "react";

import { Button, Card, TextInput } from "../../UI";
import { TabPage, Table } from "../../components";
import { GeneralContext } from "../../contexts/general.context";
import { MapCourseContext } from "../../contexts/mapCourse.context";
import classes from "./MapCourse.module.css";
import { CreateCourseForm } from "./MapCourseForm/MapCourseForm";

const CreateCourse = () => {
  const [modalShown, setModalShown] = useState(false);
  const [courseData, setCourseData] = useState({});
  const [searchData, setSearchData] = useState("");
  const [tableCourses, setTableCourses] = useState([]);

  const { getLevel, levels } = useContext(GeneralContext);
  const { mappedCourses, getMappedCourses } = useContext(MapCourseContext);

  useEffect(() => {
    getMappedCourses();
    getLevel();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTableCourses(mappedCourses);
  }, [mappedCourses]);

  useEffect(() => {
    (async () => {
      if (searchData === "") {
        await getMappedCourses();
        setTableCourses(mappedCourses);
      }
    })();
    //eslint-disable-next-line
  }, [searchData]);

  // useEffect(() => {
  //     setTableCourses(searchedMappedCourses);
  // }, [searchedMappedCourses]);

  //handle edit
  const handleEdit = (id) => {
    setModalShown(true);
    const filter = mappedCourses.filter((item) => item.id === id);
    setCourseData({
      id: filter[0].id,
      courseId: filter[0].course.courseId,
      departmentId: filter[0].department.departmentId,
      levelId: filter[0].levelId,
      facultyId: filter[0].faculty.facultyId,
      unitLoad: filter[0].unitLoad,
      programmeId: filter[0].faculty.programId,
    });
  };

  //handle search
  const handleSearch = () => {
    // if (searchData === "") return;
    // searchMappedCourse(searchData);
  };

  //handle input change
  const handleChange = (e) => {
    setSearchData(e.target.value);
    let newCourses = tableCourses.filter((course) =>
      course.course.courseName
        .toLowerCase()
        .includes(e.target.value.toLowerCase())
    );
    setTableCourses(newCourses);
  };

  //table data
  const columns = [
    { value: "Course Name" },
    { value: "Unit Load" },
    { value: "Faculty" },
    { value: "Department" },
    { value: "Level" },
    { value: "" },
  ];
  const data = tableCourses?.map((item) => ({
    "Course Name": item.course?.courseName,
    "Unit Load": item?.unitLoad,
    Faculty: item.faculty?.faculty_Name,
    Department: item.department?.department_Name,
    Level: levels.filter((level) => level.key === item.levelId)[0]?.value,
    Edit: <button onClick={() => handleEdit(item.id)}>Edit</button>,
  }));

  return (
    <TabPage pageTitle="Map Course">
      {modalShown && (
        <CreateCourseForm
          setModalShown={setModalShown}
          courseData={courseData}
          setCourseData={setCourseData}
        />
      )}
      <Card className={classes.card}>
        <div className={classes.searchBar}>
          <TextInput
            placeholder="search course name"
            className={classes.textInput}
            onChange={handleChange}
            value={searchData}
            name="search"
          />
          <Button onClick={handleSearch}>Search</Button>
          <Button
            onClick={() => {
              setModalShown(true);
            }}
          >
            Add
          </Button>
        </div>
        <Table columns={columns} data={data} />
      </Card>
    </TabPage>
  );
};

export default CreateCourse;
