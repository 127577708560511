import React, { useEffect, useState } from "react";

import DHL from "../../asset/dhl-logo.png";
import FedEx from "../../asset/fedx-logo.png";

export const ShippingCourier = ({
  courier,
  selected,
  currency,
  handleInput,
}) => {
  const [src, setSrc] = useState(null);
  const border = selected === courier.name ? "1px solid green" : "none";

  const price = currency === "naira" ? courier.price : courier.dollarPrice;

  const switchImage = () => {
    if (courier.name === "DHL") return DHL;
    if (courier.name === "FedEx") return FedEx;
  };

  useEffect(() => {
    setSrc(() => switchImage());
    //eslint-disable-next-line
  }, []);
  return (
    <div
      className="w-fit cursor-pointer flex-grow-0"
      onClick={() =>
        handleInput({ name: "courier", value: courier.name }, price)
      }
    >
      <div style={{ border }} className="p-2">
        <img src={src} alt={courier.name} />
      </div>
    </div>
  );
};
