import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";

import { setConfirmationModalId } from "../../Redux/features/modalsSlice";
import { Button } from "../../UI";

const ConfirmationModal = ({ title, description, onClick, id }) => {
  const element = document.getElementById("confirmation-modal");

  const dispatch = useDispatch();
  const { confirmationModalId } = useSelector((state) => state.modals);

  const handleCancel = () => {
    dispatch(setConfirmationModalId(null));
  };

  return (
    <>
      {createPortal(
        <>
          {confirmationModalId === id ? (
            <>
              <div
                className="fixed inset-0 bg-slate-900/50 z-20 grid place-content-center p-8"
                onClick={handleCancel}
              >
                <div
                  className="flex flex-col justify-between gap-2 bg-white overflow-hidden transition-all duration-[0.6s] rounded-md p-8"
                  onClick={(e) => e.stopPropagation()}
                >
                  <h4 className="text-lg font-semibold capitalize">{title}</h4>
                  <p className="text-base">{description}</p>
                  <div className="flex gap-8 justify-end mt-2">
                    <Button onClick={onClick}>Confirm</Button>
                    <Button onClick={handleCancel} variant="invert">
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </>,
        element
      )}
    </>
  );
};

export default ConfirmationModal;
