import { createSlice } from "@reduxjs/toolkit";

export const generalSlice = createSlice({
  name: "general",
  initialState: {
    modalId: 0,
  },
  reducers: {
    handleModal: (state, action) => {
      state.modalId = action.payload;
    },
  },
});

export const { handleModal } = generalSlice.actions;
export default generalSlice.reducer;
