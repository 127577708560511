import React from "react";
import { createPortal } from "react-dom";
import { HiX } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import { setSidebarShown } from "../../../Redux/features/modalsSlice";
import { SidebarLinks } from "../../../utils/helper";

export const ToggleSidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebar = document.getElementById("sidebar");

  const userRole = JSON.parse(localStorage.userRoles);

  const { sidebarShown } = useSelector((state) => state.modals);

  const handleCloseBtnClick = () => {
    dispatch(setSidebarShown(false));
  };

  return (
    <>
      {createPortal(
        <>
          <aside
            className={`flex md:hidden flex-col flex-shrink-0 gap-3 py-4 w-[220px] bg-primary fixed top-0 left-0 h-screen overflow-auto z-30 transition-all duration-[0.6s] ${
              sidebarShown
                ? "translate-x-0 opacity-100"
                : "translate-x-[-100%] opacity-0"
            }`}
          >
            <div className="flex justify-between gap-3 px-5">
              <img
                onClick={() => navigate("/")}
                src="/images/logo.jpg"
                alt="logo"
                className="w-10 h-10 rounded-full"
              />
              <HiX
                size={20}
                onClick={handleCloseBtnClick}
                className="text-white"
              />
            </div>
            {SidebarLinks.map((item) =>
              item.role.some((role) => userRole.includes(role)) ? (
                <div key={item.title} className="flex flex-col gap-3 mt-5">
                  <p className="text-white/80 px-5 text-xs">{item.title}</p>
                  {item.links.map((link) =>
                    link.role.some((role) => userRole.includes(role)) ? (
                      <NavLink
                        key={link.name}
                        to={`/dashboard/${link.to}`}
                        onClick={handleCloseBtnClick}
                        className={({ isActive }) =>
                          isActive
                            ? "px-5 py-3 text-white bg-accent/20 border-l-[5px] border-solid border-accent"
                            : "px-5 py-3 text-white/60"
                        }
                      >
                        {/* <img src={link.icon} alt="icon" /> */}
                        <p>{link.name}</p>
                      </NavLink>
                    ) : null
                  )}
                </div>
              ) : null
            )}
            <p className="mt-auto text-white text-center">Powered by Meksoft</p>
          </aside>

          {sidebarShown && (
            <div
              className="fixed inset-0 bg-slate-900 opacity-70 z-20 md:hidden block"
              onClick={handleCloseBtnClick}
            ></div>
          )}
        </>,
        sidebar
      )}
    </>
  );
};
