import { CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { Card } from "../../../UI";
import { TabPage, Table } from "../../../components";
import { TranscriptManagementContext } from "../../../contexts/transcriptManagement.context";
import classes from "./PendingRequest.module.css";
import PreviewModal from "./PreviewModal/PreviewModal";

const PendingRequest = () => {
  const [previewModalIsShown, setPreviewModalIsShown] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [orderNumber, setOrderNumber] = useState();

  const {
    requireReviewTranscripts,
    getRequireReviewTranscripts,
    previewResult,
  } = useContext(TranscriptManagementContext);

  useEffect(() => {
    getRequireReviewTranscripts();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (requireReviewTranscripts) setIsLoading(false);
  }, [requireReviewTranscripts]);

  const handlePreviewButtonClick = (oredrNo) => {
    setOrderNumber(oredrNo);
    previewResult(oredrNo);
    setPreviewModalIsShown(true);
  };

  const columns = [
    {
      value: "Serial ID",
    },

    { value: "Full Name" },
    { value: "Request Date" },
    { value: "Matric No." },
    { value: "Shipping Address" },
    { value: "Destination Email" },
    { value: "" },
  ];

  const data = requireReviewTranscripts?.map((item) => ({
    "Serial ID": item.requestOrderNumber,
    "Full Name": item.student.user.fullName,
    "Request Date": item.createdAt.split("T")[0],
    "Matric No.": item.regNumber,
    "Shipping Address": item.address1,
    "Destination Email": item.destinationEmail,
    Preview: (
      <button
        onClick={() => handlePreviewButtonClick(item.requestOrderNumber)}
        title="Preview"
      >
        Preview
      </button>
    ),
  }));

  return (
    <TabPage pageTitle="Transcript Management/ Digitized Requests">
      {previewModalIsShown && (
        <PreviewModal
          setPreviewModalIsShown={setPreviewModalIsShown}
          orderNumber={orderNumber}
          setOrderNumber={setOrderNumber}
          data={requireReviewTranscripts}
        />
      )}

      <Card className={classes.card}>
        {isLoading ? (
          <p>
            Loading Requests <CircularProgress />
          </p>
        ) : (
          <Table columns={columns} data={data} />
        )}
      </Card>
    </TabPage>
  );
};

export default PendingRequest;
