import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React, { useContext, useEffect, useState } from "react";
import Button from "../../../UI/Button/Button";
import { GeneralContext } from "../../../contexts/general.context";
import { TranscriptRequestContext } from "../../../contexts/transcriptRequest";
import { ResultRequestType } from "../../../utils/helper";
import classes from "./RequestTypeForm.module.css";

const style = { fontSize: "16px", marginInline: "5px" };

const RequestTypeForm = ({ handleNext, handleBack }) => {
  const [option, setOption] = useState(false);
  const { setSelectedFile, isReprinting } = useContext(GeneralContext);
  const {
    transcriptOption,
    setTranscriptOption,
    copyType,
    setCopyType,
    requestType,
    setRequestType,
    transcriptInfo,
  } = useContext(TranscriptRequestContext);

  const inputChangeHandler = (e) => {
    const { id, name } = e.target;
    switch (name) {
      case "option":
        setTranscriptOption(id);
        setOption(true);
        setCopyType();
        break;
      case "optionv2":
        setCopyType(id);
        break;
      case "optionv3":
        setRequestType(id);
        transcriptInfo.resultRequestType = ResultRequestType[id];
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setSelectedFile(null);
    if (transcriptOption && transcriptOption === "Official Use")
      setOption(true);

    if (isReprinting) {
      setRequestType("Reprint");
      transcriptInfo.resultRequestType = ResultRequestType["Reprint"];
    }
    //eslint-disable-next-line
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleNext();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <p className={classes.heading}>Request Type</p>
        <p className={classes.subHeading}>
          What purpose do you need this transcript sent for?
        </p>
      </div>
      <ul>
        <li className={classes.listItem}>
          <input
            required
            id="Official Use"
            type="radio"
            name="option"
            checked={transcriptOption === "Official Use"}
            onChange={inputChangeHandler}
            className={classes.input}
          />
          <label htmlFor="Official Use">
            Official Use (This will be sent to an organization with official
            stamp from our university)
          </label>
        </li>
        <li className={classes.listItem}>
          <input
            required
            id="Student Use"
            type="radio"
            name="option"
            checked={transcriptOption === "Student Use"}
            onChange={inputChangeHandler}
            className={classes.input}
          />
          <label htmlFor="Student Use">
            Student Use (This copy will be marked unofficial and sent to your
            Email)
          </label>
        </li>
      </ul>
      {option && (
        <>
          <h3 className={classes.heading}>
            This transcript should be sent as a...
          </h3>
          <ul>
            <li className={classes.listItem}>
              <input
                required
                id="Hard Copy"
                type="radio"
                name="optionv2"
                checked={copyType === "Hard Copy"}
                className={classes.input}
                onChange={inputChangeHandler}
              />
              <label htmlFor="Hard Copy">Hard Copy</label>
            </li>
            <li className={classes.listItem}>
              <input
                required
                id="Soft Copy"
                type="radio"
                name="optionv2"
                checked={copyType === "Soft Copy"}
                className={classes.input}
                onChange={inputChangeHandler}
              />
              <label htmlFor="Soft Copy">Soft Copy</label>
            </li>
          </ul>
        </>
      )}
      {!isReprinting ? (
        <>
          <h3 className={classes.heading}>
            How do you want to request for this transcript...
          </h3>
          <ul>
            <li className={classes.listItem}>
              <input
                required
                id="First Time"
                name="optionv3"
                type="radio"
                checked={requestType === "First Time"}
                className={classes.input}
                onChange={inputChangeHandler}
              />
              <label htmlFor="First Time">First time</label>
            </li>
            <li className={classes.listItem}>
              <input
                required
                id="Reprint"
                name="optionv3"
                type="radio"
                checked={requestType === "Reprint"}
                className={classes.input}
                onChange={inputChangeHandler}
              />
              <label htmlFor="Reprint">Reprint</label>
            </li>
          </ul>
        </>
      ) : null}
      <div className={classes.buttonContainer}>
        <Button type="submit">
          Next
          <ArrowForwardIcon style={style} />
        </Button>
      </div>
    </form>
  );
};

export default RequestTypeForm;
