import React, { useContext, useEffect, useState } from "react";
import TextInputNew from "../../../UI/TextInput/TextInput";
import classes from "./DestinationInfo.module.css";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { HiOutlineArrowSmLeft, HiOutlineArrowSmRight } from "react-icons/hi";

import Button from "../../../UI/Button/Button";
import { GeneralContext } from "../../../contexts/general.context";

const forwardIcon = (
    <HiOutlineArrowSmRight style={{ fontSize: "16px", marginInline: "5px" }} />
);

const Form = ({ onSubmit, handleBack }) => {
    const { countries, getCountries } = useContext(GeneralContext);

    useEffect(() => {
        getCountries();
        // eslint-disable-next-line
    }, []);

    const submitHandler = (e) => {
        e.preventDefault();
        onSubmit((prevStep) => prevStep + 1);
    };

    return (
        <>
            <p className={classes.form__heading}>Destination Information</p>
            <form
                onSubmit={submitHandler}
                className={classes.form__doubleColumn}
            >
                <div>
                    <TextInputNew
                        placeholder={"Instituition/Organization"}
                        inputClassName={classes.form__fullfWidth}
                        type={"text"}
                    />
                    <TextInputNew
                        placeholder={"Address"}
                        inputClassName={classes.form__fullWidth}
                        type={"address"}
                    />
                    <TextInputNew
                        placeholder={"Destination Email"}
                        inputClassName={classes.form__fullWidth}
                        type={"email"}
                    />

                    <div className={classes.form__doubleColumn}>
                        <div className={classes.form__columnDirection}>
                            <TextInputNew
                                type={"text"}
                                placeholder={"Continent"}
                                inputClassName={classes.form__halfWidth}
                                listName={"continent"}
                                dataList={[
                                    { key: 1, value: "Africa" },
                                    { key: 2, value: "Asia" },
                                ]}
                            />
                            <TextInputNew
                                type={"text"}
                                placeholder={"State/Province"}
                                inputClassName={classes.form__halfWidth}
                                listName={"state"}
                                dataList={[
                                    { key: 1, value: "Enugu" },
                                    { key: 2, value: "Anambra" },
                                ]}
                            />
                        </div>
                        <div className={classes.form__columnDirection}>
                            <TextInputNew
                                type={"text"}
                                placeholder={"Country"}
                                inputClassName={classes.form__halfWidth}
                                listName={"countries"}
                                dataList={countries}
                            />
                            <TextInputNew
                                type={"text"}
                                placeholder={"City"}
                                inputClassName={classes.form__halfWidth}
                                listName={"cities"}
                                dataList={[
                                    { key: 1, value: "Enugu" },
                                    { key: 2, value: "Nsukka" },
                                ]}
                            />
                        </div>
                    </div>
                    <div className={classes.buttonContainer}>
                        <Button type={"submit"}>Next {forwardIcon}</Button>
                    </div>
                </div>
                <div>
                    <div className={classes.form__doubleColumn}>
                        <TextInputNew
                            type={"text"}
                            placeholder={"Refrence Number"}
                            inputClassName={classes.form__halfWidth}
                        />

                        <TextInputNew
                            type={"number"}
                            placeholder={"ZIP/Postal Code"}
                            inputClassName={classes.form__halfWidth}
                            listName={"post-code"}
                            dataList={[
                                { key: 1, value: "41109" },
                                { key: 2, value: "40089" },
                            ]}
                        />
                    </div>
                    <div className={classes.form__uploadPhoto}>
                        <p>
                            Include other documents to be sent (JPEG & PDF
                            format only)
                        </p>
                        <label htmlFor="upload-photo">
                            <AddIcon />
                            <span>Upload Files</span>
                        </label>
                        <input
                            type="file"
                            name="upload-photo"
                            id="upload-photo"
                            accept="image/png, image/jpeg"
                        />
                    </div>
                </div>
            </form>
        </>
    );
};

const ProvidedInformation = ({ onClick }) => {
    const handleNext = () => {
        onClick((prevStep) => prevStep + 1);
    };
    const handleBack = () => {
        onClick((prevStep) => prevStep - 1);
    };

    return (
        <>
            <p className={classes.providedInformation__heading}>
                Destination Information
            </p>
            <p className={classes.providedInformation__subHeading}>
                Let's be sure... review your information
            </p>
            <div className={classes.providedInformation__container}>
                <ul className={classes.providedInformation__unorderedList}>
                    <li>
                        <p>Institution/Organisation</p>
                        <h4>Seamfix Nigeria LTD</h4>
                    </li>
                    <li>
                        <p>Address</p>
                        <h4>
                            1st Floor, Leasing House, <br />
                            Off Bisola Durosinmi Etti Drive, <br />
                            Off Admiralty Way, Lekki Phase 1, <br />
                            Lagos, Nigeria
                        </h4>
                    </li>
                    <li>
                        <p>Continent</p>
                        <h4>Africa</h4>
                    </li>
                    <li>
                        <p>Country</p>
                        <h4>Nigeria</h4>
                    </li>
                    <li>
                        <p>State/Province</p>
                        <h4>Lagos</h4>
                    </li>
                </ul>
                <ul className={classes.providedInformation__unorderedList}>
                    <li>
                        <p>City</p>
                        <h4>Lagos</h4>
                    </li>
                    <li>
                        <p>Zip/PostCode</p>
                        <h4>Zip/PostCode</h4>
                    </li>
                    <li>
                        <p>Destination Email</p>
                        <h4>admission@yale.edu</h4>
                    </li>
                    <li>
                        <p>Reference Number</p>
                        <h4>Reference Number</h4>
                    </li>
                    <li>
                        <p>Purpose</p>
                        <h4>Official use</h4>
                    </li>
                    <li>
                        <p>Copy</p>
                        <h4>Hard Copy</h4>
                    </li>
                </ul>
                <div>
                    <div
                        onClick={handleBack}
                        className={classes.providedInformation__editButton}
                    >
                        <EditIcon
                            style={{
                                color: "white",
                                fontSize: "20px",
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className={classes.buttonContainer}>
                <Button className={classes.backButton} onClick={handleBack}>
                    <HiOutlineArrowSmLeft
                        style={{
                            fontSize: "16px",
                            marginInline: "5px",
                        }}
                    />
                    Back
                </Button>
                <Button onClick={handleNext}>
                    Next
                    {forwardIcon}
                </Button>
            </div>
        </>
    );
};

const Modal = ({ onClick, onClose }) => {
    const closeHandler = () => {
        onClose((prevStep) => prevStep - 1);
    };

    return (
        <>
            <div className={classes.modal}>
                <HighlightOffIcon
                    style={{
                        position: "absolute",
                        top: "20px",
                        right: "20px",
                        color: "#146d17",
                    }}
                    onClick={closeHandler}
                />
                <h3>What currency are you paying in?</h3>
                <ul>
                    <li className={classes.listItem}>
                        <input
                            type="radio"
                            name="option"
                            id="option1"
                            className={classes.input}
                        />
                        <label htmlFor="option1">Naira</label>
                    </li>
                    <li className={classes.listItem}>
                        <input
                            type="radio"
                            name="option"
                            id="option2"
                            className={classes.input}
                        />
                        <label htmlFor="option2">US Dollar</label>
                    </li>
                </ul>
                <Button onClick={onClick}>Proceed {forwardIcon}</Button>
            </div>
            <div className={classes.overlay}></div>
        </>
    );
};

const DestinationInfo = ({ handleNext }) => {
    const [currentStep, setCurrentStep] = useState(0);

    return (
        <>
            {currentStep === 0 && <Form onSubmit={setCurrentStep} />}
            {currentStep >= 1 && (
                <ProvidedInformation onClick={setCurrentStep} />
            )}

            {currentStep === 2 && (
                <Modal onClick={handleNext} onClose={setCurrentStep} />
            )}
        </>
    );
};

export default DestinationInfo;
