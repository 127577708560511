import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React, { useContext, useEffect, useState } from "react";
import { HiOutlineArrowSmLeft, HiOutlineArrowSmRight } from "react-icons/hi";

import { Button } from "../../../../UI";
import { GeneralContext } from "../../../../contexts/general.context";
import { VerificationRequestContext } from "../../../../contexts/verificationRequest";
import classes from "./AlumniDetails.module.css";

const forwardIcon = (
  <HiOutlineArrowSmRight style={{ fontSize: "16px", marginInline: "5px" }} />
);

const ProvidedInformation = ({ onClick, handleBack }) => {
  const [program, setProgram] = useState({});
  const [user] = useState(JSON.parse(localStorage.user));

  const { programmes, getProgramme } = useContext(GeneralContext);
  const { verificationRequest } = useContext(VerificationRequestContext);

  useEffect(() => {
    getProgramme();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    let proges = programmes.filter((p) => p.key === user.student?.programme);
    if (proges.length > 0) {
      setProgram(proges[0]);
    }
    //eslint-disable-next-line
  }, [programmes]);

  const handleNext = (e) => {
    e.preventDefault();
    onClick((prevStep) => prevStep + 1);
  };

  return (
    <>
      <h3 className={classes.providedInformation__heading}>
        Let's be sure... review your information
      </h3>
      <div className={classes.providedInformation__container}>
        <ul className={classes.providedInformation__unorderedList}>
          <li>
            <p className={classes.providedInformation__title}>
              Student Verification Category
            </p>
            <p className={classes.providedInformation__text}>{program.value}</p>
          </li>
          <li>
            <p className={classes.providedInformation__title}>
              Reference Number
            </p>
            <p className={classes.providedInformation__text}>
              {verificationRequest?.referenceNumber}
            </p>
          </li>
          <li>
            <p className={classes.providedInformation__title}>
              Matriculation Number
            </p>
            <p className={classes.providedInformation__text}>
              {user.student != null ? user.student.matricNumber : null}
            </p>
          </li>
          <li>
            <p className={classes.providedInformation__title}>First Name</p>
            <p className={classes.providedInformation__text}>
              {user.firstName}
            </p>
          </li>
          <li>
            <p className={classes.providedInformation__title}>Surname</p>
            <p className={classes.providedInformation__text}>{user.lastName}</p>
          </li>
        </ul>
        <ul className={classes.providedInformation__unorderedList}>
          <li>
            <p className={classes.providedInformation__title}>Department</p>
            <p className={classes.providedInformation__text}>
              {user.student != null ? user.student.departmentName : null}
            </p>
          </li>
          <li>
            <p className={classes.providedInformation__title}>
              Alumni's Phone Number
            </p>
            <p className={classes.providedInformation__text}>
              {user.phoneNumber}
            </p>
          </li>
        </ul>
      </div>
      <div className={classes.buttonContainer}>
        <Button className={classes.backButton} onClick={handleBack}>
          <HiOutlineArrowSmLeft
            style={{ fontSize: "16px", marginInline: "5px" }}
          />
          Back
        </Button>
        <Button onClick={handleNext}>
          Next
          {forwardIcon}
        </Button>
      </div>
    </>
  );
};

const Modal = ({ onClick, onClose }) => {
  const closeHandler = () => {
    onClose((prevStep) => prevStep - 1);
  };
  const { setCurrency } = useContext(GeneralContext);

  return (
    <>
      <div className={classes.modal}>
        <HighlightOffIcon
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            color: "#146d17",
            cursor: "pointer",
            fontSize: "20px",
          }}
          onClick={closeHandler}
        />
        <h3 className={classes.modal__title}>
          What currency are you paying in?
        </h3>
        <ul>
          <li className={classes.listItem}>
            <input
              type="radio"
              name="option"
              id="option1"
              className={classes.input}
              onChange={() => setCurrency("naira")}
            />
            <label htmlFor="option1">Naira</label>
          </li>
          <li className={classes.listItem}>
            <input
              type="radio"
              name="option"
              id="option2"
              className={classes.input}
              onChange={() => setCurrency("dollar")}
            />
            <label htmlFor="option2">US Dollar</label>
          </li>
        </ul>
        <Button onClick={onClick}>Proceed {forwardIcon}</Button>
      </div>
      <div className={classes.overlay}></div>
    </>
  );
};

const AlumniDeatils = ({ handleBack, handleNext }) => {
  const [currentStep, setCurrentStep] = useState(0);

  return (
    <>
      {currentStep >= 0 && (
        <ProvidedInformation onClick={setCurrentStep} handleBack={handleBack} />
      )}

      {currentStep === 1 && (
        <Modal onClick={handleNext} onClose={setCurrentStep} />
      )}
    </>
  );
};

export default AlumniDeatils;
