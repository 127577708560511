import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import { createEnglishProficiencyApi } from "../api/Writes";

export const EnglishProficiencyContext = createContext();

const EnglishProficiencyProvider = ({ children }) => {
  const [englishProficiencyInfo, setEnglishProficiencyInfo] = useState({});
  const [copyType, setCopyType] = useState();
  const [requestType, setRequestType] = useState()
  const [destinationEmail, setDestinationEmail] = useState("");
  const [proficiencyOrderNo, setProficiencyOrderNo] = useState();

  const createEnglishProficiency = async (param) => {
    try {
      let response = await createEnglishProficiencyApi(param);
      if (response.success) {
        toast.success("Your english proficiency copy is being processed");
        return response.data.requestOrderNumber;
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { message } = response?.data;
      toast.error(message || "Oops, Something went wrong");
    }
  };

  return (
    <EnglishProficiencyContext.Provider
      value={{
        createEnglishProficiency,
        copyType,
        setCopyType,
        destinationEmail,
        setDestinationEmail,
        englishProficiencyInfo,
        setEnglishProficiencyInfo,
        proficiencyOrderNo,
        setProficiencyOrderNo,
        requestType,
        setRequestType
      }}
    >
      {children}
    </EnglishProficiencyContext.Provider>
  );
};
export default EnglishProficiencyProvider;
