import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import React, { useContext, useEffect, useState } from "react";
import { FiBookOpen, FiFile } from "react-icons/fi";
import { HiOutlineArrowSmLeft, HiOutlineArrowSmRight } from "react-icons/hi";

import { Button } from "../../../../UI";
import { VerificationRequestContext } from "../../../../contexts/verificationRequest";
import classes from "./SelectDocument.module.css";

const SelectDocument = ({ handleNext, handleBack }) => {
  const iconFontSize = { fontSize: "50px" };
  const [selected, setSelected] = useState(false);
  const [checked, setChecked] = useState({
    Transcript: false,
    SupportingDocs: false,
    Certificate: false,
  });

  const { setVerificationRequest, verificationRequest } = useContext(
    VerificationRequestContext
  );

  useEffect(() => {
    setVerificationRequest((prev) => ({
      ...prev,
      verificationType: [],
    }));
    //eslint-disable-next-line
  }, []);

  const handleSelection = (type) => {
    if (verificationRequest.verificationType.includes(type)) {
      let arr = verificationRequest.verificationType.filter((e) => e !== type);
      setVerificationRequest((prev) => ({
        ...prev,
        verificationType: arr,
      }));
      setChecked((prev) => ({ ...prev, [type]: false }));
    } else {
      verificationRequest.verificationType.push(type);
      setChecked((prev) => ({ ...prev, [type]: true }));
    }
    // setVerificationRequest((prev) => ({
    //     ...prev,
    //     verificationType: type,
    // }));
    setSelected(true);
  };

  return (
    <div>
      <p className={classes.title}>Select documents you'll like to verify</p>
      <div key={1} className={classes.documentContainer}>
        <div
          key={1}
          className={classes.document}
          onClick={() => {
            handleSelection("Transcript");
          }}
        >
          <div key={1}>
            <input
              key={1}
              type="checkbox"
              name="document1"
              checked={checked.Transcript}
              id="document1"
              readOnly
            />
          </div>
          <FiBookOpen style={iconFontSize} />
          <p className={classes.document__title}>Transcript</p>
          <p className={classes.document__text}>
            Verify General Transcript Information
          </p>
        </div>
        <div
          key={2}
          className={classes.document}
          onClick={() => {
            handleSelection("Certificate");
          }}
        >
          <div key={2}>
            <input
              key={2}
              type="checkbox"
              name="document1"
              checked={checked.Certificate}
              id="document2"
              readOnly

              // onChange={() => handleSelection("Certificate")}
            />
          </div>
          <WorkspacePremiumIcon style={iconFontSize} />
          <p className={classes.document__title}>Certificate</p>
          <p className={classes.document__text}>
            Verification of Alumni Certificate Information
          </p>
        </div>
        <div
          key={3}
          className={classes.document}
          onClick={() => {
            handleSelection("SupportingDocs");
          }}
        >
          <div key={3}>
            <input
              key={3}
              type="checkbox"
              name="document3"
              checked={checked.SupportingDocs}
              id="document3"
              readOnly
              // onChange={() => handleSelection("SupportingDocs")}
            />
          </div>
          <FiFile style={iconFontSize} />
          <p className={classes.document__title}>Entry Document</p>
          <p className={classes.document__text}>
            Verification of Alumni Entry and Supporting Documents
          </p>
        </div>
      </div>
      <div key={2} className={classes.buttonContainer}>
        <Button key={2} className={classes.backButton} onClick={handleBack}>
          <HiOutlineArrowSmLeft
            style={{ fontSize: "16px", marginInline: "5px" }}
          />
          Back
        </Button>
        <Button
          key={3}
          onClick={handleNext}
          disabled={!selected}
          className={classes.forwardButton}
        >
          Proceed
          <HiOutlineArrowSmRight
            style={{ fontSize: "16px", marginInline: "5px" }}
          />
        </Button>
      </div>
    </div>
  );
};

export default SelectDocument;
