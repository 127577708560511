import { useContext, useState } from "react";
import { useDispatch } from "react-redux";

import { setGenericModalId } from "../../Redux/features/modalsSlice";
import { Button, TextInput } from "../../UI";
import GenericModal from "../../components/Modals/GenericModal";
import { DigitizerContext } from "../../contexts/digitizer.context";
import { TranscriptManagementContext } from "../../contexts/transcriptManagement.context";

export const RejectModal = ({ orderNumber, refetch, isProficiency }) => {
  const [inputsData, setInputsData] = useState({});
  const dispatch = useDispatch();

  const { flagRequest } = useContext(TranscriptManagementContext);
  const { getFlaggedTranscripts } = useContext(DigitizerContext);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputsData((prev) => ({ ...prev, [name]: value }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const message = `The above student have not paid  ₦${inputsData.amount} ${inputsData.feeName} ${inputsData.semester} of ${inputsData.session}. ${inputsData.desc}`;
    await flagRequest({
      requestOrderNumber: orderNumber,
      message: message,
      isProficiency,
    });
    setInputsData({});
    getFlaggedTranscripts(true);
    refetch();
    dispatch(setGenericModalId(null));
  };

  return (
    <GenericModal id="FinanceRejectTranscript">
      <form onSubmit={handleSubmit}>
        <div className="my-2 flex flex-col gap-2">
          <h4 className=" text-lg font-semibold">
            Reject Proficiency In English Request
          </h4>
          <p>Please fill in reasons for rejetion below</p>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-wrap gap-6 w-full">
            <TextInput
              onChange={handleInputChange}
              required
              placeholder="Fee Name"
              name="feeName"
              value={inputsData.feeName || ""}
            />
            <TextInput
              onChange={handleInputChange}
              required
              placeholder="Amount"
              name="amount"
              value={inputsData.amount || ""}
            />
          </div>
          <div className="flex flex-wrap gap-6">
            <TextInput
              onChange={handleInputChange}
              required
              placeholder="Session"
              name="session"
              value={inputsData.session || ""}
            />
            <TextInput
              onChange={handleInputChange}
              placeholder="Semester"
              name="semester"
              value={inputsData.semester || ""}
            />
            <TextInput
              onChange={handleInputChange}
              placeholder="Please recommend possible solution"
              name="desc"
              value={inputsData.desc || ""}
              multiline
            />
          </div>
        </div>
        <div className="flex justify-center mt-2">
          <Button type="submit">Confirm</Button>
        </div>
      </form>
    </GenericModal>
  );
};
