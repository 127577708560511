import { useContext, useEffect, useState } from "react";
import {
  HiOutlineArrowSmLeft,
  HiOutlineArrowSmRight,
  HiOutlineUpload,
} from "react-icons/hi";

import { Button } from "../../../UI";
import { GeneralContext } from "../../../contexts/general.context";
import { TranscriptRequestContext } from "../../../contexts/transcriptRequest";
import classes from "./DestinationInfo.module.css";

export const DocumentSelect = ({ setCurrentStep }) => {
  const [documentNames, setDocmentNames] = useState({});

  const { uploadFile, transcriptReceiptRequired } = useContext(GeneralContext);
  const { transcriptInfo, setTranscriptInfo, requestType } = useContext(
    TranscriptRequestContext
  );
  //initialize the document object in the transcript info object
  useEffect(() => {
    setTranscriptInfo((prev) => ({
      ...prev,
      document: {
        previousTranscriptRecieptsUrl: "",
        clearanceORpaymentRecieptsUrl: "",
        attachingDocumentUrls: [""],
      },
    }));
    //eslint-disable-next-line
  }, []);

  const handleNextBtn = (e) => {
    e.preventDefault();
    transcriptInfo.document.documentType = 1;
    setCurrentStep((prev) => prev + 1);
  };

  const handleBackBtn = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const onFileChange = async (e) => {
    const { files, id, name } = e.target;
    const { data: documentUrl } = await uploadFile(files[0]);
    if (!documentUrl) return;

    if (id === "attachingDoc") {
      transcriptInfo.document[name] = [documentUrl];
      setDocmentNames((prev) => ({ ...prev, [id]: [files[0].name] }));
      return;
    }
    transcriptInfo.document[name] = documentUrl;
    setDocmentNames((prev) => ({ ...prev, [id]: files[0].name }));
  };

  return (
    <form className={classes.documentSelect} onSubmit={handleNextBtn}>
      <h1>Upload Documents</h1>
      <div className={classes.documentSelect__documentContainer}>
        <h4>
          Official Document<span>*</span>
        </h4>
        <div className={classes.documentSelect__inputContainer}>
          <div className={classes.documentSelect__input}>
            {/* <Select /> */}

            <p>
              Provide Statement of result/ Clearance form/ Payment receipts
              (provide all receipts in a single pdf):
            </p>
            <label htmlFor="officialDoc">
              <HiOutlineUpload /> Upload file
            </label>
            <input
              type="file"
              id="officialDoc"
              onChange={onFileChange}
              name="clearanceORpaymentRecieptsUrl"
              required
            />
            <p>{documentNames?.officialDoc}</p>
          </div>

          {requestType === "Reprint" || transcriptReceiptRequired ? (
            <div className={classes.documentSelect__input}>
              <p>Provide receipt of your last transcript request:</p>
              <label htmlFor="paymentReceipt">
                <HiOutlineUpload /> Upload file
              </label>
              <input
                type="file"
                id="paymentReceipt"
                onChange={onFileChange}
                name="previousTranscriptRecieptsUrl"
                required
              />
              <p>{documentNames?.paymentReceipt}</p>
            </div>
          ) : null}
        </div>
      </div>
      <div className={classes.documentSelect__documentContainer}>
        <h4>Attaching Document</h4>
        <div className={classes.documentSelect__inputContainer}>
          <div className={classes.documentSelect__input}>
            <label htmlFor="attachingDoc">
              <HiOutlineUpload /> Upload file(s)
            </label>
            <input
              type="file"
              id="attachingDoc"
              onChange={onFileChange}
              name="attachingDocumentUrls"
            />
            {documentNames?.attachingDoc?.map((item) => (
              <p key={item}>{item}</p>
            ))}
          </div>
        </div>
      </div>
      <div className="flex gap-8 justify-center">
        <Button onClick={handleBackBtn} variant="invert" className="flex gap-2">
          <HiOutlineArrowSmLeft size={20} />
          Back
        </Button>
        <Button type="submit" className="flex gap-2">
          Next <HiOutlineArrowSmRight size={20} />
        </Button>
      </div>
    </form>
  );
};
