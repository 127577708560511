import { Select as MUISelect } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { forwardRef } from "react";

const Select = forwardRef(
  (
    { id, onChange, name, options, required, value, placeholder, readOnly },
    ref
  ) => {
    return (
      <FormControl fullWidth>
        <InputLabel id={`${id || name}-label`}>{placeholder}</InputLabel>
        <MUISelect
          labelId={`${id || name}-label`}
          id={id || name}
          name={name}
          value={value}
          label={name}
          onChange={onChange}
          required={required}
          readOnly={readOnly}
          ref={ref}
        >
          {options?.map((item) => (
            <MenuItem
              value={item.key}
              key={item.key}
              disabled={item.disabled || false}
            >
              {item.value}
            </MenuItem>
          ))}
        </MUISelect>
      </FormControl>
    );
  }
);

export default Select;
