import { useState } from "react";
import { TabPage } from "../../../components";
import TranscriptAwaitingDispatch from "./TranscriptAwaitingDispatch";
import styles from "./styles.module.css";
import classNames from "classnames";
import ProficiencyAwaitingDispatch from "./ProciencyAwaitingDispatch";

const AwaitingDispatch = () => {
  const [currentTab, setCurrentTab] = useState(1);

  return (
    <TabPage pageTitle="Transcript Management/ Awaiting Dispatch">
      <div className={styles.tab_container}>
        <div
          className={classNames(
            styles.tab_item,
            currentTab === 1 && styles.active
          )}
          onClick={() => setCurrentTab(1)}
        >
          Transcripts
        </div>
        <div
          className={classNames(
            styles.tab_item,
            currentTab === 2 && styles.active
          )}
          onClick={() => setCurrentTab(2)}
        >
          Proficiency
        </div>
      </div>
      {currentTab === 1 && <TranscriptAwaitingDispatch />}
      {currentTab === 2 && <ProficiencyAwaitingDispatch />}
    </TabPage>
  );
};
export default AwaitingDispatch;
