import React, { useState } from "react";
import { Card } from "../../UI";
import { StepperComponent, TabPage } from "../../components";
import DestinationInfo from "./DestinationInfo/DestinationInfo";
import RequestTypeForm from "./RequestTypeForm/RequestTypeForm";
import ShippingOption from "./ShippingOption/ShippingOption";

const TranscriptRequest = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = ["one", "two", "three"];

  return (
    <TabPage pageTitle="Transcript Request">
      <StepperComponent activeStep={activeStep} steps={steps} width={"50%"} />
      <Card className="p-4 md:p-10">
        {activeStep === 0 && <RequestTypeForm handleNext={handleNext} />}
        {activeStep === 1 && (
          <DestinationInfo handleNext={handleNext} handleBack={handleBack} />
        )}
        {activeStep === 2 && <ShippingOption handleBack={handleBack} />}
      </Card>
    </TabPage>
  );
};

export default TranscriptRequest;
