import { useContext, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import PayTranscript from "../../components/PayTranscript/PayTranscript";
import { GeneralContext } from "../../contexts/general.context";
import {
  AlumniVerification,
  AwaitingDigitization,
  AwaitingDispatch,
  AwaitingSigning,
  CertificateRequest,
  CreateCourse,
  Digitize,
  FlaggedRequest,
  Header,
  Home,
  MapCourse,
  PendingRequest,
  ProficiencyInEnglish,
  SideBar,
  TranscriptRequest,
  UndigitizedRequest,
} from "../../features";
import FinanceDashboard from "../../features/Finance/FinanceDashboard";
import { ToggleSidebar } from "../../features/Navigation/SideBar/ToggleSidebar";
import UnprocessedRequest from "../../features/TranscriptManagement/UnprocessedRequest/UnprocessedRequest";
import Upload from "../../features/TranscriptManagement/Upload/Upload";
import ShippingOption from "../../features/TranscriptRequest/ShippingOption/ShippingOption";
import { getItemFromLocalStorage } from "../../utils/helper";
import { NotFound } from "../index";

const DashBoardPage = () => {
  const { isLoggedIn } = useContext(GeneralContext);
  const navigate = useNavigate();
  const role = getItemFromLocalStorage("userRoles");

  useEffect(() => {
    if (isLoggedIn) {
      if (role[0] === "Finance") {
        navigate("/dashboard/finance_pending-requests");
        return;
      }
      if (role[0] === "TranscriptProcessingAdmin") {
        navigate("/dashboard/unprocessed-request");
        return;
      }

      navigate("/dashboard/home");
    }
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="flex h-screen w-full overflow-hidden">
        <SideBar />
        <ToggleSidebar />
        <div className="h-full bg-slate-100 flex-grow flex flex-col min-w-[100px]">
          <Header />
          <Routes>
            <Route path="/home" element={<Home />} />
            {/* Transcript Request */}
            <Route path="/transcript-request" element={<TranscriptRequest />} />
            <Route path="/transcript-request/pay" element={<PayTranscript />} />
            <Route
              path="/alumi-verification"
              element={<AlumniVerification />}
            />
            <Route path="/proficiency" element={<ProficiencyInEnglish />} />
            <Route
              path="/certificate-request"
              element={<CertificateRequest />}
            />
            {/* // */}

            {/* Course */}
            <Route path="/create-course" element={<CreateCourse />} />
            <Route path="/map-course" element={<MapCourse />} />
            {/* // */}

            {/* Request Manangement */}
            <Route path="/pending-request" element={<PendingRequest />} />
            <Route
              path="/awaiting-digitization"
              element={<AwaitingDigitization />}
            />
            <Route path="/awaiting-signing" element={<AwaitingSigning />} />
            <Route path="/awaiting-dispatch" element={<AwaitingDispatch />} />
            <Route path="/upload-transcript" element={<Upload />} />
            <Route
              path="/unprocessed-request"
              element={<UnprocessedRequest />}
            />
            {/* // */}

            {/* Digitizer */}
            <Route
              path="/digitizer_undigitized-requests"
              element={<UndigitizedRequest />}
            />
            <Route path="/digitizer_digitize" element={<Digitize />} />
            <Route
              path="/digitizer_flagged-requests"
              element={<FlaggedRequest />}
            />
            {/* // */}

            {/* Finance */}
            <Route
              path="/finance_pending-requests"
              element={<FinanceDashboard />}
            />
            <Route
              path="/finance_flagged-requests"
              element={<FlaggedRequest />}
            />
            {/* // */}

            {/* Catch all Route */}
            <Route path="*" element={<NotFound />} />
            {/* // */}
          </Routes>
        </div>
      </div>
    </>
  );
};

export default DashBoardPage;
