import React, { useContext } from "react";
import styles from "./RequestTypeForm.module.css";
import Button from "../../../UI/Button/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { EnglishProficiencyContext } from "../../../contexts/englishProficiency";

const RequestTypeForm = ({ handleNext }) => {
  const {
    setCopyType,
    copyType,
    setEnglishProficiencyInfo,
    requestType,
    setRequestType,
  } = useContext(EnglishProficiencyContext);

  const handleCopyTypeSelect = (e) => {
    const { value } = e.target;
    setCopyType(value);
    setEnglishProficiencyInfo({});
  };

  const handleRequestTypeSelect = (e) => {
    const { value } = e.target;
    setRequestType(value);
  };

  return (
    <form>
      <h3 className={styles.heading}>
        This proficiency in english should be sent as a...
      </h3>
      <ul>
        <li className={styles.listItem}>
          <input
            id="option1"
            type="radio"
            name="copyType"
            value="hard_copy"
            className={styles.input}
            onChange={handleCopyTypeSelect}
            checked={copyType === "hard_copy"}
          />
          <label htmlFor="option1">Hard Copy</label>
        </li>
        <li className={styles.listItem}>
          <input
            id="option2"
            type="radio"
            name="copyType"
            value="soft_copy"
            className={styles.input}
            onChange={handleCopyTypeSelect}
            checked={copyType === "soft_copy"}
          />
          <label htmlFor="option1">Soft Copy</label>
        </li>
      </ul>
      <h3 className={styles.heading}>
        How do you want to request for this transcript...
      </h3>
      <ul>
        <li className={styles.listItem}>
          <input
            required
            id="First Time"
            name="requestType"
            type="radio"
            checked={requestType === "First Time"}
            className={styles.input}
            value={"First Time"}
            onChange={handleRequestTypeSelect}
          />
          <label htmlFor="First Time">First time</label>
        </li>
        <li className={styles.listItem}>
          <input
            required
            id="Reprint"
            name="optionv3"
            type="radio"
            checked={requestType === "Reprint"}
            className={styles.input}
            value="Reprint"
            onChange={handleRequestTypeSelect}
          />
          <label htmlFor="Reprint">Reprint</label>
        </li>
      </ul>
      <Button disabled={copyType && requestType ? false : true} onClick={handleNext}>
        Next
        <ArrowForwardIcon style={{ fontSize: "16px", marginInline: "5px" }} />
      </Button>
    </form>
  );
};

export default RequestTypeForm;
