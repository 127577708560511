import React from "react";

import styles from "./Button.module.css";

const Button = ({ type, onClick, children, disabled, className, variant }) => {
  return (
    <button
      className={`${styles.button} ${className} ${
        styles[variant || "primary"]
      }`}
      type={type || "button"}
      onClick={onClick}
      disabled={disabled || false}
    >
      {children}
    </button>
  );
};

export default Button;
