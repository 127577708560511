import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import { editCourseApi, registerCourseApi } from "../api/Writes";
import { removeCourseApi, searchCourseApi } from "../api/Reads";

export const CreateCourseContext = createContext(null);

const CreateCourseProvider = ({ children }) => {
    const [searchedCourses, setSearchedCourses] = useState([]);

    //register course
    const registerCourse = async (param) => {
        try {
            let response = await registerCourseApi(param);

            if (response.success) {
                toast.success("Course created successfully");
            } else {
                toast.error(response.message || "Oops, Something went wrong");
            }
        } catch ({ response }) {
            const { message } = response?.data;
            toast.error(message || "Oops, Something went wrong");
        }
    };

    //edit course
    const editCourse = async (param) => {
        try {
            let response = await editCourseApi(param);

            if (response.success) {
                toast.success("Course updated successfully");
            } else {
                toast.error(response.message || "Oops, Something went wrong");
            }
        } catch ({ response }) {
            const { message } = response?.data;
            toast.error(message || "Oops, Something went wrong");
        }
    };

    //remove course
    const removeCourse = async (courseId) => {
        try {
            let response = await removeCourseApi(courseId);

            // if (response.success) {
            //     toast.success("Course deleted successfully");
            // } else {
            //     toast.error(response.message || "Oops, Something went wrong");
            // }
        } catch ({ response }) {
            const { title } = response?.data;
            toast.error(title || "Oops, Something went wrong");
        }
    };

    //search course
    const searchCourse = async (courseName) => {
        try {
            let response = await searchCourseApi(courseName);
            if (response.success) {
                setSearchedCourses(response);
            } else {
                toast.error("Oops, Something went wrong");
            }
        } catch ({ response }) {
            const { title } = response?.data;
            toast.error(title || "Oops, Something went wrong");
        }
    };

    return (
        <CreateCourseContext.Provider
            value={{
                registerCourse,
                removeCourse,
                editCourse,
                searchedCourses,
                searchCourse,
            }}
        >
            {children}
        </CreateCourseContext.Provider>
    );
};
export default CreateCourseProvider;
