import React from "react";

import classes from "./Table.module.css";

const Table = ({ columns, data, title, id }) => {
  return (
    <div className={classes["table__page-container"]}>
      <h3 className="my-2 text-lg ">{title || ""}</h3>
      <div id={id || ""} style={{ width: "100%" }}>
        <table>
          <thead>
            <tr>
              {columns.map((item, index) => (
                <th key={index}>{item.value}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!data?.length && (
              <tr className={classes.no_data}>
                <td> No Data</td>
              </tr>
            )}
            {data?.map((item, index) => {
              const array = Object.entries(item);
              return (
                <tr key={index}>
                  {array.map(([name, value]) => (
                    <td data-cell={name} key={name} title={name}>
                      {value}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
