import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  confirmationModalId: "",
  genericModalId: "",
  sidebarShown: false,
};

export const modalsSlice = createSlice({
  name: "modals",
  initialState: initialState,
  reducers: {
    setConfirmationModalId: (state, action) => {
      state.confirmationModalId = action.payload;
    },
    setGenericModalId: (state, action) => {
      state.genericModalId = action.payload;
    },
    setSidebarShown: (state, action) => {
      state.sidebarShown = action.payload;
    },
  },
});

export const { setConfirmationModalId, setGenericModalId, setSidebarShown } =
  modalsSlice.actions;
export default modalsSlice.reducer;
