import React, { useContext } from "react";
import { AiOutlineClose } from "react-icons/ai";
import styled from "styled-components";

import Button from "../../UI/Button/Button";
import { GeneralContext } from "../../contexts/general.context";
import { theme } from "../../theme";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
`;

export const Modal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  //   justify-content: center;
  background: #fff;
  width: 50%;
  height: 50%;
  gap: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 10px;
  padding: 20px;
`;

export const Close = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  svg {
    color: #253b80;
    width: 16px;
    cursor: pointer;
  }
`;

function Info({ title, desc, buttontext, onClick, id }) {
  const { modalId, setModal } = useContext(GeneralContext);

  return (
    <>
      {id === modalId && (
        <Container>
          <Modal>
            <Close>
              <AiOutlineClose onClick={() => setModal(0)} size={20} />
            </Close>
            <svg
              style={{ color: "#253b80" }}
              xmlns="http://www.w3.org/2000/svg"
              width="60"
              height="60"
              fill="currentColor"
              className="bi bi-exclamation-octagon"
              viewBox="0 0 16 16"
            >
              <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z" />
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
            </svg>
            <h2>{title}</h2>
            <p>{desc}</p>
            <div className="modal-button">
              <Button onClick={onClick}>{buttontext}</Button>
            </div>
          </Modal>
        </Container>
      )}
    </>
  );
}

export default Info;
