import React, { useContext, useEffect, useState } from "react";

import { Button, Card, TextInput } from "../../UI";
import { TabPage, Table } from "../../components";
import { CreateCourseContext } from "../../contexts/createCourse.context";
import { GeneralContext } from "../../contexts/general.context";
import classes from "./CreateCourse.module.css";
import { CreateCourseForm } from "./CreateCourseForm/CreateCourseForm";

const CreateCourse = () => {
  const [modalShown, setModalShown] = useState(false);
  const [courseData, setCourseData] = useState({});
  const [searchData, setSearchData] = useState("");
  const [tableCourses, setTableCourses] = useState([]);

  const { courses, getCourse } = useContext(GeneralContext);
  const { searchedCourses, searchCourse } = useContext(CreateCourseContext);

  useEffect(() => {
    getCourse();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTableCourses(courses);
  }, [courses]);

  useEffect(() => {
    (async () => {
      if (searchData === "") {
        await getCourse();
        setTableCourses(courses);
      }
    })();
    //eslint-disable-next-line
  }, [searchData]);

  useEffect(() => {
    setTableCourses(searchedCourses);
  }, [searchedCourses]);

  //handle edit
  const handleEdit = (courseId) => {
    setModalShown(true);
    const filter = courses.filter((item) => item.courseId === courseId);
    setCourseData(filter[0]);
  };

  //handle search
  const handleSearch = () => {
    if (searchData === "") return;
    searchCourse(searchData);
  };

  //handle input change
  const handleChange = (e) => {
    setSearchData(e.target.value);
    let newCourses = tableCourses.filter((course) =>
      course.courseName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setTableCourses(newCourses);
  };
  //table data
  const columns = [
    { value: "Course Name" },
    { value: "Course Code" },
    { value: "Unit Load" },
    { value: " " },
  ];
  const data = tableCourses?.map((item) => ({
    "Course Name": item.courseName,
    "Course Code": item.courseCode,
    "Unit Load": item.unitLoad,
    Edit: <button onClick={() => handleEdit(item.courseId)}>Edit</button>,
  }));

  return (
    <TabPage pageTitle="Create Course">
      {modalShown && (
        <CreateCourseForm
          setModalShown={setModalShown}
          courseData={courseData}
          setCourseData={setCourseData}
        />
      )}
      <Card className={classes.card}>
        <div className={classes.searchBar}>
          <TextInput
            placeholder="search course name"
            className={classes.textInput}
            onChange={handleChange}
            value={searchData}
            name="search"
          />
          <Button onClick={handleSearch}>Search</Button>
          <Button
            onClick={() => {
              setModalShown(true);
            }}
          >
            Add
          </Button>
        </div>
        <Table columns={columns} data={data} />
      </Card>
    </TabPage>
  );
};

export default CreateCourse;
