export const formatCurrency = (amount, currency) => {
  const temp = {
    style: "currency",
    currency: currency,
  };

  const currencyFormat = new Intl.NumberFormat("en-US", currency && temp);

  const formattedCurrency = currencyFormat.format(amount);
  return formattedCurrency;
};
