import { forwardRef, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./styles.module.css";
import { setConfirmationModalId } from "../../Redux/features/modalsSlice";
import { TabPage } from "../../components";
import classNames from "classnames";
import ReviewTranscriptTable from "./ReviewTranscriptTable";
import ReviewProficiencyTable from "./ReviewProficiencyTable";

const FinanceDashboard = () => {
  const [menuId, setMenuId] = useState("");
  const [currentTab, setCurrentTab] = useState(1);
  const menuRef = useRef();

  //Handle closing action menu when you click outside of it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target))
        setMenuId("");
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <TabPage pageTitle="Finance/ Pending Requests">
      <div className={styles.tab_container}>
        <div
          className={classNames(
            styles.tab_item,
            currentTab === 1 && styles.active
          )}
          onClick={() => setCurrentTab(1)}
        >
          Transcripts
        </div>
        <div
          className={classNames(
            styles.tab_item,
            currentTab === 2 && styles.active
          )}
          onClick={() => setCurrentTab(2)}
        >
          Proficiency
        </div>
      </div>
      <div className="grid w-full h-full">
        {currentTab === 1 && (
          <ReviewTranscriptTable
            ActionMenu={ActionMenu}
            menuId={menuId}
            setMenuId={setMenuId}
          />
        )}
        {currentTab === 2 && (
          <ReviewProficiencyTable
            ActionMenu={ActionMenu}
            menuId={menuId}
            setMenuId={setMenuId}
          />
        )}
      </div>
    </TabPage>
  );
};

export default FinanceDashboard;

const ActionMenu = forwardRef(
  (
    {
      id,
      menuId,
      setApproveOrderNumber,
      handleView,
      handleReject,
      orderNo,
      hasDocumentToView,
    },
    ref
  ) => {
    const dispatch = useDispatch();

    return menuId === id ? (
      <div
        className="absolute bg-white flex flex-col top-10 -left-10 z-10 min-h-[100px] rounded-sm shadow-sm"
        ref={ref}
      >
        <p
          className="py-2 px-5 text-sm cursor-pointer hover:bg-slate-300"
          onClick={() => {
            dispatch(setConfirmationModalId("FinanceApproveTranscript"));
            setApproveOrderNumber(orderNo);
          }}
        >
          Approve
        </p>
        <p
          className="py-2 px-5 text-sm cursor-pointer hover:bg-slate-300 "
          onClick={() => handleReject(orderNo)}
        >
          Reject
        </p>
        <p
          className={`py-2 px-5 text-sm cursor-pointer hover:bg-slate-300 ${
            !hasDocumentToView && "hidden"
          }`}
          onClick={() => handleView(orderNo)}
        >
          View
        </p>
      </div>
    ) : null;
  }
);
