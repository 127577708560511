import React, { createContext, useState } from "react";
import { toast } from "react-toastify";

import {
  calculateCGPApi,
  getExistingCoursesApi,
  getFlaggedTranscriptsApi,
  getGradeApi,
  getGradesByScoreApi,
  markTranscriptAsResolvedApi,
  verifyStudentApi,
} from "../api/Reads";
import {
  deleteCourseApi,
  saveCGPApi,
  updateCourseApi,
  updateStudentApi,
} from "../api/Writes";

export const DigitizerContext = createContext();

const DigitizerProvider = ({ children }) => {
  const [studentData, setStudentData] = useState({});
  const [studentCourses, setStudentCourses] = useState([]);
  const [userId, setuserId] = useState("");
  const [studentGrade, setstudentGrade] = useState("");
  const [resultSummary, setResultSummary] = useState({});
  const [grade, setGrade] = useState([]);
  const [flaggedTranscripts, setFlaggedTranscripts] = useState();
  const [regNoFromRedirect, setRegNoFromRedirect] = useState("");
  const [matricNoFromVerifyStudent, setMatricNoFromVerifyStudent] = useState();

  //FLAGGED REQUESTS
  //get all transcripts that are flagged
  const getFlaggedTranscripts = async (isProficiency) => {
    try {
      let response = await getFlaggedTranscriptsApi(isProficiency);
      if (response.success) {
        setFlaggedTranscripts(response.data);
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { title } = response?.data;
      toast.error(title || "Oops, Something went wrong");
    }
  };
  //mark transcript as resolved
  const markTranscriptAsResolved = async (orderNo, issuedId, isProficiency) => {
    try {
      let response = await markTranscriptAsResolvedApi(orderNo, issuedId);
      if (response.success) {
        if (isProficiency) {
          toast.success("Proficiency in english successfully resolved");
        } else {
          toast.success("Transcript successfully resolved");
        }
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { title } = response?.data;
      toast.error(title || "Oops, Something went wrong");
    }
  };

  //DIGITIZE
  //verify student
  const verifyStudent = async (matricNo) => {
    setMatricNoFromVerifyStudent(matricNo);
    try {
      let response = await verifyStudentApi(matricNo);
      if (response.success) {
        setStudentData(response.data);
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { message } = response?.data;
      toast.error(message || "Student not found");
    }
  };

  //update student
  const updateStudent = async (param) => {
    try {
      let response = await updateStudentApi(param);

      if (response.success) {
        setuserId(response.data.userId);
        toast.success("Student updated successfully");
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { message } = response?.data;
      toast.error(message || "Oops, Something went wrong");
    }
  };

  //update course
  const updateCourse = async (param) => {
    try {
      let response = await updateCourseApi(param);
      if (response.success) {
        toast.success("Course updated successfully");
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { message } = response?.data;
      toast.error(message || "Oops, Something went wrong");
    }
  };

  //get existing courses
  const getExistingCourses = async (userId) => {
    try {
      let response = await getExistingCoursesApi(userId);
      if (response.success) {
        setStudentCourses(response.data);
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { message } = response?.data;
      toast.error(message || "Oops, something went wrong");
    }
  };

  //delete course
  const deleteCourse = async (courseRegistrationId) => {
    try {
      let response = await deleteCourseApi(courseRegistrationId);
      // if (response.success) {
      //     toast.success("Course deleted successfully");
      // } else {
      //     toast.error("Oops, Something went wrong");
      // }
    } catch ({ response }) {
      const { message } = response?.data;
      toast.error(message || "Oops, something went wrong");
    }
  };

  //get grades by score
  const getGradesByScore = async (gradesystemId, score) => {
    try {
      let response = await getGradesByScoreApi(gradesystemId, score);

      if (response.success) {
        setstudentGrade(response.data.id);
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      if (response) {
        const { message } = response?.data;
        toast.error(message || "Oops, something went wrong");
      } else {
        toast.error("Oops, something went wrong");
      }
    }
  };

  //save CGP
  const saveCGP = async (obj) => {
    try {
      let response = await saveCGPApi(obj);
      if (response.success) {
        toast.success("Scores updated successfully");
      } else {
        toast.error("Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { message } = response?.data;
      toast.error(message || "Oops, something went wrong");
    }
  };

  //calculate CGP
  const calculateCGP = async (userId) => {
    try {
      let response = await calculateCGPApi(userId);
      if (response.success) {
        const { id, name } = response.data.degreeClass;
        setResultSummary({
          ...response.data,
          degreeClassId: id,
          degreeClassName: name,
        });
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { message } = response?.data;
      toast.error(message || "Oops, something went wrong");
    }
  };

  //get grade with grade system Id
  const getGrade = async (gradesystemId) => {
    try {
      let response = await getGradeApi(gradesystemId);
      if (response.success) {
        let items = response.data.map((item) => {
          item.key = item.id;
          item.value = item.grade;
          return item;
        });
        setGrade(items);
      } else {
        toast.error(response.message || "Oops, Something went wrong");
      }
    } catch ({ response }) {
      const { message } = response?.data;
      toast.error(message || "Oops, something went wrong");
    }
  };

  return (
    <DigitizerContext.Provider
      value={{
        studentData,
        setStudentData,
        verifyStudent,
        studentCourses,
        setStudentCourses,
        updateStudent,
        updateCourse,
        getExistingCourses,
        userId,
        setuserId,
        studentGrade,
        getGradesByScore,
        deleteCourse,
        resultSummary,
        calculateCGP,
        saveCGP,
        grade,
        getGrade,
        flaggedTranscripts,
        getFlaggedTranscripts,
        markTranscriptAsResolved,
        regNoFromRedirect,
        setRegNoFromRedirect,
        matricNoFromVerifyStudent,
      }}
    >
      {children}
    </DigitizerContext.Provider>
  );
};
export default DigitizerProvider;
