import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { PaymentGateway } from "../../../../components";
import { VerificationRequestContext } from "../../../../contexts/verificationRequest";

const PaymentOption = ({ handleBack }) => {
  const user = JSON.parse(localStorage.user);

  const navigate = useNavigate();

  const { verificationRequest, saveVerificationRequest } = useContext(
    VerificationRequestContext
  );

  const config = {
    email: user.email,
    amount: 1000,
  };

  const handleSubmission = (referenceNo) => {
    verificationRequest.userId = user.id;
    saveVerificationRequest(verificationRequest);
    navigate("/dashboard");
  };

  return (
    <PaymentGateway
      onPayment={handleSubmission}
      config={config}
      onPrevious={handleBack}
    />
  );
};

export default PaymentOption;
