import { useState } from "react";
import Form from "./Form";
// import Modal from "./Modal";
import ProvidedInformation from "./ProvidedInfo";
import { DocumentSelect } from "./DocumentSelect";

const DestinationInfo = ({ handleNext, handleBack }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <>
      {currentStep === 0 && (
        <Form onSubmit={handleNextStep} handleBack={handleBack} />
      )}
      {currentStep === 1 && (
        <DocumentSelect
          handleNext={handleNextStep}
          handleBack={handlePreviousStep}
        />
      )}
      {currentStep >= 2 && (
        <ProvidedInformation
          onClick={handleNext}
          setCurrentStep={handlePreviousStep}
        />
      )}
    </>
  );
};

export default DestinationInfo;
