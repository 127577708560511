import { TabPage } from "../../components";
import classes from "./Home.module.css";
import StudentDashboard from "./StudentDashboard";
import { SuperFinance } from "./SuperFinance";

const Home = () => {
  const role = JSON.parse(localStorage.userRoles);

  const Content = {
    Student: <StudentDashboard />,
    Busary: <SuperFinance />,
    Admin: <Test />,
    SuperAdmin: <Test />,
    ResultDigitizer: <Test />,
    TranscriptProcessingAdmin: <Test />,
    Reviewer: <Test />,
    Approver: <Test />,
  };

  return <TabPage pageTitle="Dash Board">{Content[role[0]]}</TabPage>;
};

export default Home;

const Test = () => (
  <div className={classes.cardContainer}>
    <div className={classes.card}>
      <p className={classes.card__title}>Total transcript requests/Month</p>
      <h4 className={classes.card__value}>4,867</h4>
    </div>
    <div className={classes.card}>
      <p className={classes.card__title}>Acrued Revenue/Month </p>
      <h4 className={classes.card__value}>N2,343,234</h4>
    </div>
    <div className={classes.card}>
      <p className={classes.card__title}>Pending Dispatch</p>
      <h4 className={classes.card__value}>3,456</h4>
    </div>
    <div className={classes.card}>
      <p className={classes.card__title}>Average Request to delivery time</p>
      <h4 className={classes.card__value}>1,411</h4>
    </div>
  </div>
);
