import React, { useContext, useEffect, useState } from "react";
import TextInputNew from "../../../UI/TextInput/TextInput";
import classes from "./DestinationInfo.module.css";
import { HiOutlineArrowSmLeft, HiOutlineArrowSmRight } from "react-icons/hi";
import Button from "../../../UI/Button/Button";
import { GeneralContext } from "../../../contexts/general.context";
import { EnglishProficiencyContext } from "../../../contexts/englishProficiency";
import { isValidPhoneNumber } from "libphonenumber-js";
import { toast } from "react-toastify";
import InputPhone from "../../../UI/PhoneInput/PhoneInput";
import { Select, TextInput } from "../../../UI";

const forwardIcon = (
  <HiOutlineArrowSmRight style={{ fontSize: "16px", marginInline: "5px" }} />
);

const Form = ({ onSubmit, handleBack, handleNext }) => {
  const { countries, getCountries, getStates, getCities, states, cities } =
    useContext(GeneralContext);
  const { englishProficiencyInfo, setEnglishProficiencyInfo, copyType } =
    useContext(EnglishProficiencyContext);
  const [stateCityRequired, setStateCityRequired] = useState(true);

  useEffect(() => {
    getCountries();
    // eslint-disable-next-line
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "zipCode") {
      setEnglishProficiencyInfo((prev) => ({ ...prev, [name]: String(value) }));
    } else {
      setEnglishProficiencyInfo((prev) => ({ ...prev, [name]: value }));
    }
    switch (name) {
      case "countryId":
        getStates(value);
        if (Number(value) === 160) {
          setStateCityRequired(true);
        } else {
          setStateCityRequired(false);
        }
        break;
      case "stateId":
        getCities(value);
        break;
      default:
        return;
    }
  };

  const validatePhoneNumber = (phoneNumber) => {
    if (
      !englishProficiencyInfo.destinationContactNumber ||
      !isValidPhoneNumber(phoneNumber)
    ) {
      toast.error("Please enter a valid number");
      return false;
    }
    return true;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (copyType === "hard_copy") {
      if (validatePhoneNumber(englishProficiencyInfo?.destinationContactNumber))
        onSubmit((prevStep) => prevStep + 1);
    } else {
      onSubmit((prevStep) => prevStep + 1);
    }
  };

  return (
    <>
      <p className={classes.form__heading}>Destination Information</p>
      {copyType === "hard_copy" ? (
        <form onSubmit={submitHandler} className={classes.form__doubleColumn}>
          <div className={classes.form__columnDirection}>
            <TextInputNew
              placeholder={"Address"}
              inputClassName={classes.form__fullWidth}
              type={"address"}
              name="address1"
              value={
                englishProficiencyInfo?.address1
                  ? englishProficiencyInfo?.address1
                  : ""
              }
              onChange={handleInputChange}
              required
            />
            <div className={classes.form__doubleColumn}>
              <TextInputNew
                placeholder={"Destination Email"}
                inputClassName={classes.form__fullWidth}
                type={"email"}
                name="destinationEmail"
                value={
                  englishProficiencyInfo?.destinationEmail
                    ? englishProficiencyInfo?.destinationEmail
                    : ""
                }
                onChange={handleInputChange}
                required
              />
              <InputPhone
                name="destinationContactNumber"
                onChange={handleInputChange}
                value={
                  englishProficiencyInfo?.destinationContactNumber
                    ? englishProficiencyInfo?.destinationContactNumber
                    : ""
                }
                required
              />
            </div>
            <div className={classes.form__doubleColumn}>
              <Select
                inputClassName={classes.form__halfWidth}
                placeholder="Country"
                options={countries}
                name="countryId"
                onChange={handleInputChange}
                required
                value={
                  englishProficiencyInfo?.countryId
                    ? englishProficiencyInfo?.countryId
                    : ""
                }
              />
              <Select
                inputClassName={classes.form__halfWidth}
                placeholder="State"
                options={states}
                name="stateId"
                onChange={handleInputChange}
                required
                value={
                  englishProficiencyInfo?.stateId
                    ? englishProficiencyInfo?.stateId
                    : ""
                }
              />
            </div>
            <div className={classes.form__doubleColumn}>
              <Select
                inputClassName={classes.form__halfWidth}
                placeholder="City"
                options={cities}
                name="cityId"
                onChange={handleInputChange}
                required
                value={
                  englishProficiencyInfo?.cityId
                    ? englishProficiencyInfo?.cityId
                    : ""
                }
              />
              <TextInput
                type="number"
                placeholder="ZIP/Postal Code"
                inputClassName={classes.form__halfWidth}
                name="postalCode"
                value={
                  englishProficiencyInfo?.postalCode
                    ? englishProficiencyInfo?.postalCode
                    : ""
                }
                onChange={handleInputChange}
                required
              />
            </div>
            <div className={classes.buttonContainer}>
              <Button className={classes.backButton} onClick={handleBack}>
                <HiOutlineArrowSmLeft
                  style={{
                    fontSize: "16px",
                    marginInline: "5px",
                  }}
                />
                Back
              </Button>
              <Button type={"submit"} onClick={handleNext}>
                Next {forwardIcon}
              </Button>
            </div>
          </div>
        </form>
      ) : (
        <form onSubmit={submitHandler} className={classes.form__doubleColumn}>
          <div className={classes.form__columnDirection}>
            <TextInputNew
              placeholder={"Destination Email"}
              inputClassName={classes.form__fullWidth}
              type={"email"}
              name="destinationEmail"
              value={
                englishProficiencyInfo?.destinationEmail
                  ? englishProficiencyInfo?.destinationEmail
                  : ""
              }
              onChange={handleInputChange}
              required
            />
            <div className={classes.buttonContainer}>
              <Button className={classes.backButton} onClick={handleBack}>
                <HiOutlineArrowSmLeft
                  style={{
                    fontSize: "16px",
                    marginInline: "5px",
                  }}
                />
                Back
              </Button>
              <Button type={"submit"} onClick={handleNext}>
                Next {forwardIcon}
              </Button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default Form;
