import React from "react";
import { Step, StepLabel, Stepper } from "@mui/material";
import styles from "./ProficiencyInEnglish.module.css";


const StepperContainer = ({ activeStep }) => {
  const steps = ["one", "two", "three"];

  return (
    <div className={styles.stepper__container}>
      <Stepper
        sx={{
          width: "50%",
          margin: "25px auto",
          "& .MuiStepIcon-text ,.MuiStepLabel-label": {
            fontSize: "14px",
          },
          "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root": {
            fontSize: "20px",
          },
          "& .css-z7uhs0-MuiStepConnector-line": {
            borderTopWidth: "3px",
          },

          "& .css-1bw0nnu-MuiStep-root": {
            paddingInline: "3px",
          },
          "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed,.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active":
            {
              color: "#146d17",
            },
          "& .css-vnkopk-MuiStepLabel-iconContainer": {
            paddingInline: "2px",
          },
          "& .MuiStepLabel-label": {
            display: "none",
          },
        }}
        activeStep={activeStep}
      >
        {steps.map((label) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
};

export default StepperContainer;
