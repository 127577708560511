import { AddAPhoto } from "@mui/icons-material";
import React, { useContext, useState } from "react";

import { GeneralContext } from "../../contexts/general.context";
import classes from "./UploadFile.module.css";

const UploadFile = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const { uploadFile } = useContext(GeneralContext);

  const onFileChange = async (e) => {
    const { files } = e.target;
    const file = await uploadFile(files[0]);
    setSelectedFile(file);
  };
  return (
    <div className={classes.uploadPhoto}>
      <label htmlFor="upload-photo">
        {selectedFile === null || selectedFile === undefined ? (
          <>
            <AddAPhoto />
            <span>Upload Files</span>
          </>
        ) : (
          <img src={selectedFile} className={classes.passport} alt="passport" />
        )}
      </label>
      <input
        name="imageUrl"
        id="upload-photo"
        type="file"
        accept="image/png, image/jpeg"
        onChange={onFileChange}
        required={selectedFile ? false : true}
      />
    </div>
  );
};

export default UploadFile;
