import React, { createContext, useState } from "react";
import { toast } from "react-toastify";
import { saveVerificationRequestApi } from "../api/Writes";
import { getVerificationBodyApi } from "../api/Reads";

export const VerificationRequestContext = createContext(null);

const VerificationRequestProvider = ({ children }) => {
    const [verificationBody, setVerificationBody] = useState([]);
    const [verificationRequest, setVerificationRequest] = useState({});

    //  get verification body
    const getVerificationBody = async () => {
        try {
            let response = await getVerificationBodyApi();
            if (response.success) {
                let items = response.data.map((item) => {
                    item.key = item.id;
                    item.value = item.name;
                    return item;
                });
                items = [{ key: 0, value: "select", disabled: true }, ...items];
                setVerificationBody(items);
            } else {
                toast.error("Opps, something went wrong");
            }
        } catch ({ response }) {
            const { message } = response?.data;
            toast.error(message || "Opps, something went wrong");
        }
    };
    //  save verification
    const saveVerificationRequest = async (param) => {
        try {
            let response = await saveVerificationRequestApi(param);
            if (response.success) {
                toast.success("Verification request created successfully");
                setVerificationRequest(response.data);
            } else {
                toast.error("Opps, something went wrong");
            }
        } catch ({ response }) {
            const { message } = response?.data;
            toast.error(message || "Opps, something went wrong");
        }
    };

    return (
        <VerificationRequestContext.Provider
            value={{
                getVerificationBody,
                verificationBody,
                setVerificationBody,
                saveVerificationRequest,
                verificationRequest,
                setVerificationRequest,
            }}
        >
            {children}
        </VerificationRequestContext.Provider>
    );
};
export default VerificationRequestProvider;
