import { toast } from "react-toastify";

import { transcriptApi } from "../transcriptCore";

const transcriptRequestApi = transcriptApi.injectEndpoints({
  endpoints: (builder) => ({
    initializePayment: builder.query({
      query: ({ RequestOrderNumber, feeType, resultType, couriersType }) => {
        let url;
        resultType && couriersType
          ? (url = `RequestOrderNumber=${RequestOrderNumber}&feeType=${feeType}&resultType=${resultType}&couriersType=${couriersType}`)
          : (url = `RequestOrderNumber=${RequestOrderNumber}&feeType=${feeType}`);

        return { url: `api/v1/Auth/InitialPayment?${url}`, method: "GET" };
      },
    }),
    confirmPaymnet: builder.mutation({
      query: (payload) => ({
        url: "api/v1/Auth/ConfirmPayment",
        method: "POST",
        body: payload,
      }),
    }),
    createTranscript: builder.mutation({
      query: (payload) => ({
        url: "api/v1/Auth/createTranscript",
        method: "POST",
        body: payload,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect

        try {
          const { data } = await queryFulfilled;
          // `onSuccess`
          toast.success("Transcript created successfully!");
        } catch (err) {
          // `onError`

          toast.error(err || "Error creating transcript!");
        }
      },
    }),
    uploadPaymentReceipt: builder.query({
      query: (arg) => ({
        url: `api/v1/Transcript/ReUploadTranscriptDocuments`,
        method: "GET",
        params: { ...arg },
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          toast.success("Receipt Uploaded successfully!");
        } catch (err) {
          toast.error(err || "Error uploading receipt");
        }
      },
    }),
  }),
});

export const { useCreateTranscriptMutation, useLazyUploadPaymentReceiptQuery } =
  transcriptRequestApi;
