import { createPortal } from "react-dom";
import { HiX } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";

import { setGenericModalId } from "../../Redux/features/modalsSlice";
import { Button } from "../../UI";

const GenericModal = ({ children, onClick, id, buttonText }) => {
  const element = document.getElementById("modal");

  const dispatch = useDispatch();
  const { genericModalId } = useSelector((state) => state.modals);

  const handleCancel = () => {
    dispatch(setGenericModalId(null));
  };

  return (
    <>
      {createPortal(
        <>
          {genericModalId === id ? (
            <>
              <div
                className="fixed inset-0 bg-slate-900/50 z-20 grid place-content-center p-8"
                onClick={handleCancel}
              >
                <div
                  className="relative flex flex-col justify-between gap-2 bg-white overflow-hidden transition-all duration-[0.6s] rounded-md p-8 overflow-y-auto"
                  onClick={(e) => e.stopPropagation()}
                >
                  <HiX
                    className="absolute top-4 right-4 cursor-pointer"
                    onClick={handleCancel}
                  />
                  {children}
                  {/* <div className="flex gap-8 justify-end mt-2">
                    <Button onClick={onClick}>{buttonText || "Confirm"}</Button>
                    <Button onClick={handleCancel} variant="invert">
                      Cancel
                    </Button>
                  </div> */}
                </div>
              </div>
            </>
          ) : null}
        </>,
        element
      )}
    </>
  );
};

export default GenericModal;
