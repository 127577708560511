import React, { useRef, useState } from "react";
import {
  useGetAwaitingFinanceApprovalTranscriptsQuery,
  useLazyMarkTranscriptAsProcessingForReviewQuery,
} from "../../Redux/services/endpoints/finance";
import { Table } from "../../components";
import { HiDotsVertical } from "react-icons/hi";
import { useDispatch } from "react-redux";
import {
  setConfirmationModalId,
  setGenericModalId,
} from "../../Redux/features/modalsSlice";
import { ImSpinner3 } from "react-icons/im";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import { RejectModal } from "./RejectModal";
import { ViewModal } from "./ViewModal";

const ReviewTranscriptTable = ({ ActionMenu, menuId, setMenuId }) => {
  const { data, isLoading, isError, refetch } =
    useGetAwaitingFinanceApprovalTranscriptsQuery();
  const [orderNumber, setOrderNumber] = useState();
  const [approveOrderNumber, setApproveOrderNumber] = useState();
  const [transcriptUrls, setTranscriptUrls] = useState({});
  const dispatch = useDispatch();
  const menuRef = useRef();

  const [trigger] = useLazyMarkTranscriptAsProcessingForReviewQuery();

  const handleApprove = async () => {
    await trigger({ OrderNumber: approveOrderNumber, isProficiency: false });
    dispatch(setConfirmationModalId(null));
    refetch();
  };

  const handleView = (orderNo) => {
    const temp = data?.data?.find(
      (item) => item.requestOrderNumber === orderNo
    );

    try {
      const {
        transcriptDocument: [
          {
            clearanceORpaymentRecieptsUrl = "",
            previousTranscriptRecieptsUrl = "",
          },
        ],
      } = temp;

      setTranscriptUrls({
        clearanceORpaymentRecieptsUrl,
        previousTranscriptRecieptsUrl,
      });
      dispatch(setGenericModalId("FinanceViewTranscript"));
    } catch (err) {
      console.log("Something went wrong");
    }
  };

  const handleReject = (orderNo) => {
    setOrderNumber(orderNo);
    dispatch(setGenericModalId("FinanceRejectTranscript"));
  };

  const columns = [
    { value: "Serial ID" },
    { value: "Order Number" },
    { value: "Full Name" },
    { value: "Request Date" },
    { value: "Matric No." },
    { value: "Shipping Address" },
    { value: "Destination Email" },
    { value: "Actions" },
  ];

  const rows = data?.data?.map((item, index) => ({
    "Serial ID": index + 1,
    "Order Number": item.requestOrderNumber,
    "Full Name": item.student.user.fullName,
    "Request Date": item.createdAt.split("T")[0],
    "Matric No.": item.regNumber,
    "Shipping Address": item.address1,
    "Destination Email": item.destinationEmail,
    "Action Modal": (
      <>
        <HiDotsVertical
          className="cursor-pointer"
          onClick={() => setMenuId("FinancePending" + item.requestOrderNumber)}
        />
        <ActionMenu
          id={"FinancePending" + item.requestOrderNumber}
          menuId={menuId}
          ref={menuRef}
          orderNo={item.requestOrderNumber}
          handleReject={handleReject}
          handleView={handleView}
          setApproveOrderNumber={setApproveOrderNumber}
          hasDocumentToView={item?.transcriptDocument?.length > 0}
        />
      </>
    ),
  }));

  return (
    <>
      {isLoading ? (
        <ImSpinner3 className="animate-spin place-self-center" size={32} />
      ) : isError ? (
        <p className="place-self-center">Error loading data. Pease try again</p>
      ) : (
        <Table columns={columns} data={rows} title="Transcripts" />
      )}

      <ConfirmationModal
        title="Approve Transcript"
        description="Are you sure you want to approve this transcript?"
        onClick={handleApprove}
        id="FinanceApproveTranscript"
      />
      <RejectModal orderNumber={orderNumber} refetch={refetch} />
      <ViewModal transcriptUrls={transcriptUrls} />
    </>
  );
};

export default ReviewTranscriptTable;
