import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

import { GeneralContext } from "../../contexts/general.context";

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn } = useContext(GeneralContext);

  if (!isLoggedIn) return <Navigate to="/login" />;

  return children;
};

export default ProtectedRoute;
