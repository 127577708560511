import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

function InputPhone({ onChange, value, name }) {
  const handleChange = (value, country, e, formattedValue) => {
    onChange(e);
  };

  const inputStyle = {
    width: "100%",
    padding: "14.5px 14px 14.5px 55px",
  };
  const inputProps = {
    maxLength: "15",
    name: `${name}`,
    required: true,
  };

  return (
    <PhoneInput
      specialLabel="Phone *"
      value={value}
      onChange={handleChange}
      countryCodeEditable={false}
      country="ng"
      inputProps={inputProps}
      inputStyle={inputStyle}
      autoFormat={false}
      enableSearch={true}
    />
  );
}
export default InputPhone;
