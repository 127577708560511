import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { HiDotsVertical } from "react-icons/hi";
import { ImSpinner3 } from "react-icons/im";
import { useLazyPreviewManualResultQuery } from "../../../Redux/services/endpoints/transcriptManagement";
import { Table } from "../../../components";
import { TranscriptManagementContext } from "../../../contexts/transcriptManagement.context";
import PreviewModal from "../PendingRequest/PreviewModal/PreviewModal";
import { HardCopyModal } from "./HardCopyModal";

const TranscriptAwaitingDispatch = () => {
  const [orderNumber, setOrderNumber] = useState("");
  const [hardCopyModal, setHardCopyModal] = useState(false);
  const [modalIsShown, setModalIsShown] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [menuId, setMenuId] = useState("");

  const menuRef = useRef();

  const [trigger, { data: transcriptUrl }] = useLazyPreviewManualResultQuery();

  const {
    readyForDispatchTranscripts,
    getReadyForDispatchTranscripts,
    dispatchSoftCopy,
  } = useContext(TranscriptManagementContext);

  useEffect(() => {
    getReadyForDispatchTranscripts();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (readyForDispatchTranscripts) setIsLoading(false);
  }, [readyForDispatchTranscripts]);

  useEffect(() => {
    if (transcriptUrl) {
      const { data } = transcriptUrl;
      window.open(data, "_blank");
    }
  }, [transcriptUrl]);

  //Handle closing action menu when you click outside of it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target))
        setMenuId("");
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handlePreviewButtonClick = (orderNo) => {
    trigger(orderNo);
  };

  const handleSoftCopy = async (orderNo) => {
    await dispatchSoftCopy(orderNo, false);
    getReadyForDispatchTranscripts();
  };

  const handleHardCopy = (orderNo) => {
    setOrderNumber(orderNo);
    setHardCopyModal(true);
  };

  const columns = [
    { value: "Serial ID" },
    { value: "Order Number" },
    { value: "Full Name" },
    { value: "Request Date" },
    { value: "Matric No." },
    { value: "Shipping Address" },
    { value: "Destination Email" },
    { value: "Actions" },
  ];

  const data = readyForDispatchTranscripts?.map((item, index) => ({
    "Serial ID": index + 1,
    "Order Number": item.requestOrderNumber,
    "Full Name": item.student.user.fullName,
    "Request Date": item.createdAt.split("T")[0],
    "Matric No.": item.regNumber,
    "Shipping Address": item.address1,
    "Destination Email": item.destinationEmail,
    "Action Modal": (
      <>
        <HiDotsVertical
          className="cursor-pointer"
          onClick={() => setMenuId("Dispatch" + item.requestOrderNumber)}
        />
        <ActionMenu
          id={"Dispatch" + item.requestOrderNumber}
          menuId={menuId}
          ref={menuRef}
          handlePreviewButtonClick={handlePreviewButtonClick}
          handleHardCopy={handleHardCopy}
          handleSoftCopy={handleSoftCopy}
          orderNo={item.requestOrderNumber}
          resultType={item.resultType}
        />
      </>
    ),
  }));

  return (
    <>
      {" "}
      {hardCopyModal && (
        <HardCopyModal
          setModal={setHardCopyModal}
          orderNo={orderNumber}
          setOrderNumber={setOrderNumber}
        />
      )}
      {modalIsShown && (
        <PreviewModal
          setPreviewModalIsShown={setModalIsShown}
          orderNumber={orderNumber}
          setOrderNumber={setOrderNumber}
          data={readyForDispatchTranscripts}
          dispatchCopy
        />
      )}
      <div className="grid w-full h-full">
        {isLoading ? (
          <ImSpinner3 className="animate-spin place-self-center" size={32} />
        ) : (
          <Table columns={columns} data={data} />
        )}
      </div>
    </>
  );
};

export default TranscriptAwaitingDispatch;

const ActionMenu = forwardRef(
  (
    {
      id,
      menuId,
      handleHardCopy,
      handleSoftCopy,
      handlePreviewButtonClick,
      orderNo,
      resultType,
    },
    ref
  ) => {
    return menuId === id ? (
      <div
        className="absolute bg-white flex flex-col top-10 -left-10 z-10 min-h-[100px] rounded-sm shadow-sm"
        ref={ref}
      >
        <p
          className="py-2 px-5 text-sm cursor-pointer hover:bg-slate-300"
          onClick={() => handlePreviewButtonClick(orderNo)}
        >
          Preview
        </p>
        <p
          className="py-2 px-5 text-sm cursor-pointer hover:bg-slate-300 "
          onClick={() => handleSoftCopy(orderNo)}
        >
          Dispatch Soft Copy
        </p>
        {Number(resultType) === 1 ? (
          <p
            className="py-2 px-5 text-sm cursor-pointer hover:bg-slate-300 "
            onClick={() => handleHardCopy(orderNo)}
          >
            Dispatch Hard Copy
          </p>
        ) : null}
      </div>
    ) : null;
  }
);
