import React, { useContext } from "react";
import { HiOutlineArrowSmLeft, HiOutlineArrowSmRight } from "react-icons/hi";

import { Button } from "../../UI";
import { GeneralContext } from "../../contexts/general.context";
import classes from "./CurrencyModal.module.css";

export const forwardIcon = (
  <HiOutlineArrowSmRight style={{ fontSize: "16px", marginInline: "5px" }} />
);

const CurrencyModal = ({ handleNext, handleBack }) => {
  const { setCurrency } = useContext(GeneralContext);

  const submitHandler = (e) => {
    e.preventDefault();
    handleNext((prev) => prev + 1);
  };

  return (
    <>
      <form className={classes.container} onSubmit={submitHandler}>
        <h3>What currency are you paying in?</h3>
        <ul>
          <li className={classes.listItem}>
            <input
              type="radio"
              name="option"
              id="option1"
              className={classes.input}
              onChange={() => setCurrency("naira")}
              required
            />
            <label htmlFor="option1">Naira</label>
          </li>
        </ul>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.backButton}
            onClick={handleBack}
            variant="invert"
          >
            <HiOutlineArrowSmLeft
              style={{
                fontSize: "16px",
                marginInline: "5px",
              }}
            />
            Back
          </Button>
          <Button type="submit">Proceed {forwardIcon}</Button>
        </div>
      </form>
    </>
  );
};
export default CurrencyModal;
