import { useContext, useEffect, useState } from "react";
import { ImSpinner3 } from "react-icons/im";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Button, Select } from "../../UI";
import TextInputNew from "../../UI/TextInput/TextInput";
import Image from "../../asset/ConfirmAccountImage.webp";
import Info from "../../components/Modals/Info";
import { GeneralContext } from "../../contexts/general.context";

const ConfirmAccountPage = () => {
  const [searchParam, setSearchParam] = useState({});
  const [isPending, setIsPending] = useState(false);

  const {
    programmes,
    getProgramme,
    setModal,
    setProgramme,
    searchStudent,
    setRegno,
    isLoggedIn,
  } = useContext(GeneralContext);

  const navigate = useNavigate();

  useEffect(() => {
    getProgramme();
    setModal(0);
    if (isLoggedIn) {
      navigate("/dashboard/transcriptRequest");
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  const validateInput = () => {
    if (!searchParam.programmetype) {
      toast.error("Select a valid Option for each input");
      return false;
    }
    return true;
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setSearchParam((prev) => ({ ...prev, [name]: value }));
    if (name === "programmetype") setProgramme({ key: value });
    if (name === "regNumber") setRegno(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateInput()) {
      setIsPending(true);
      await searchStudent(searchParam);
      setIsPending(false);
    }
  };

  return (
    <main className="grid grid-cols-1 md:grid-cols-2 h-screen">
      <div className="hidden md:block">
        <img
          src={Image}
          alt="student reading"
          className="h-full object-cover"
        />
      </div>
      <div className="grid content-center p-8">
        <form
          className="relative max-w-screen-sm w-full sm:w-4/5 mx-auto grid gap-4"
          onSubmit={handleSubmit}
        >
          <Link
            to="/login"
            className="absolute -top-4 right-0 underline text-blue"
          >
            Login
          </Link>
          <h2 className="font-semibold text-2xl">Confirm Matric No.</h2>
          <TextInputNew
            name="regNumber"
            placeholder="Enter your Reg Number"
            onChange={handleInput}
            value={searchParam.regNumber ? searchParam.regNumber : ""}
            required
          />
          <Select
            placeholder="Select a Programme"
            name="programmetype"
            value={searchParam.programmetype ? searchParam.programmetype : ""}
            options={programmes}
            onChange={handleInput}
            required
          />
          <Button type="submit">
            {isPending ? (
              <ImSpinner3 className="animate-spin" size={22} />
            ) : (
              <span>Confirm</span>
            )}
          </Button>
          <div className="grid gap-4">
            <p className="flex flex-wrap gap-2">
              First time requesting?
              <span
                onClick={() => {
                  navigate("/createProfile");
                }}
                className="text-blue underline cursor-pointer"
              >
                Get Started Here
              </span>
            </p>
            <p className="text-blue underline text-sm cursor-pointer">
              Forgot Password?
            </p>
          </div>
        </form>
      </div>

      <Info
        title="User not found!"
        desc="Are you a new user?, we will need your details to process the request"
        buttontext="create profile"
        onClick={() => {
          navigate("/createProfile");
        }}
        id={1}
      />
    </main>
  );
};

export default ConfirmAccountPage;
