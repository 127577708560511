import { toast } from "react-toastify";

import { transcriptApi } from "../transcriptCore";

const transcriptManagementApi = transcriptApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadManualResult: builder.query({
      query: ({ RequestOrderNumber, resultUrl, isProficiency }) => ({
        url: `api/v1/Transcript/UploadManualResult?RequestOrderNumber=${RequestOrderNumber}&resultUrl=${resultUrl}&isProficiency=${isProficiency}`,
        method: "GET",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          // `onSuccess`
          toast.success("Transcript uploaded successfully!");
        } catch (err) {
          // `onError`
          toast.error(err || "Error uploading transcript!");
        }
      },
    }),
    previewManualResult: builder.query({
      query: (RequestOrderNumber) => ({
        url: `api/v1/Transcript/PreviewManualResult?RequestOrderNumber=${RequestOrderNumber}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyUploadManualResultQuery,
  useUploadManualResultQuery,
  useLazyPreviewManualResultQuery,
} = transcriptManagementApi;
