import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React, { useContext, useEffect } from "react";
import { toast } from "react-toastify";

import { Button, Select, TextInput } from "../../../UI";
import { GeneralContext } from "../../../contexts/general.context";
import { MapCourseContext } from "../../../contexts/mapCourse.context";
import classes from "../MapCourse.module.css";

export function CreateCourseForm({ setModalShown, courseData, setCourseData }) {
  const {
    getCourse,
    courses,
    faculties,
    getFaculty,
    departments,
    getDepartment,
    levels,
    getLevel,
    getProgramme,
    programmes,
  } = useContext(GeneralContext);

  const { mapCourse, reMapCourse, getMappedCourses } =
    useContext(MapCourseContext);

  //handle input change
  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setCourseData((prev) => ({ ...prev, [name]: value }));

    switch (name) {
      case "programmeId":
        getFaculty(value);
        break;
      case "facultyId":
        getDepartment(value);
        break;
      case "courseId":
        let course = courses.filter((c) => c.courseId === parseInt(value));
        if (course.length) {
          setCourseData((prev) => ({
            ...prev,
            unitLoad: course[0].unitLoad,
          }));
        }
        break;
      default:
        return;
    }
  };

  //handle getting individual dropdown data on component mount
  useEffect(() => {
    getLevel();
    getCourse();
    getProgramme();

    if (courseData.programmedId) {
      getFaculty(courseData.programmedId);
    }
    if (courseData.facultyId) {
      getDepartment(courseData.facultyId);
    }
    //eslint-disable-next-line
  }, []);

  //handle validation of form inputs on form submission
  const validateInput = () => {
    if (!courseData.facultyId || !courseData.departmentId) {
      toast.error("Please select a valid option for each field");
      return false;
    }
    return true;
  };

  //handle submission of course mapping form
  const submitHandler = async (e) => {
    e.preventDefault();
    if (!validateInput()) return;
    delete courseData.programmedId;

    if (courseData.id) {
      await reMapCourse(courseData);
    } else {
      await mapCourse(courseData);
    }

    getMappedCourses();
    setCourseData({});
    setModalShown(false);
  };

  //handle closing of course mapping form
  const closeHandler = () => {
    setCourseData({});
    setModalShown(false);
  };

  return (
    <>
      <div className={classes.modal}>
        <HighlightOffIcon
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            color: "#146d17",
            cursor: "pointer",
            fontSize: "20px",
          }}
          onClick={closeHandler}
        />
        <h2>Enter course details</h2>
        <form onSubmit={submitHandler}>
          <Select
            name="courseId"
            options={courses}
            onChange={inputChangeHandler}
            value={courseData.courseId || 0}
            placeholder="Course"
          />

          <TextInput
            name="unitLoad"
            placeholder="unit"
            onChange={inputChangeHandler}
            value={courseData.unitLoad || ""}
            type="number"
            readOnly
          />
          <Select
            placeholder="Programme"
            name="programmeId"
            options={programmes}
            onChange={inputChangeHandler}
            value={courseData.programmeId || 0}
          />
          <Select
            placeholder="Faculty"
            name="facultyId"
            options={faculties}
            onChange={inputChangeHandler}
            value={courseData.facultyId || 0}
          />
          <Select
            placeholder="Department"
            name="departmentId"
            options={departments}
            onChange={inputChangeHandler}
            value={courseData.departmentId || 0}
          />

          <Select
            placeholder="Level"
            name="levelId"
            options={levels}
            onChange={inputChangeHandler}
            value={courseData.levelId || 0}
          />
          <Button type="submit">Map Course</Button>
        </form>
      </div>
      <div className={classes.overlay}></div>
    </>
  );
}
