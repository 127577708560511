import GenericModal from "../../components/Modals/GenericModal";

export const ViewModal = ({ transcriptUrls }) => {
  return (
    <GenericModal id="FinanceViewTranscript">
      <div className="grid gap-2 my-4">
        <p className="flex gap-4 items-center font-semibold">
          Official Document
          <a
            href={transcriptUrls?.clearanceORpaymentRecieptsUrl || "#"}
            target="blank"
            className="ursor-pointer text-sm font-normal border border-zinc-700 rounded-sm px-2 py-1 bg-slate-600/30"
          >
            View
          </a>
        </p>
        {transcriptUrls?.previousTranscriptRecieptsUrl && (
          <p className="flex gap-4 items-center font-semibold">
            Transcript Payment Receipt
            <a
              href={transcriptUrls?.previousTranscriptRecieptsUrl || "#"}
              target="blank"
              className="cursor-pointer text-sm font-normal border border-zinc-700 rounded-sm px-2 py-1 bg-slate-600/30"
            >
              View
            </a>
          </p>
        )}
        {/* {transcriptUrls?.attachingDocumentUrlArray && (
          <div>
            <p className="flex gap-4 items-center font-semibold">
              Attaching Document(s):
            </p>
            {transcriptUrls?.attachingDocumentUrlArray?.map((item, index) => (
              <p className="flex gap-4 items-center">
                Document {index + 1}
                <a
                  href={item || "#"}
                  target="blank"
                  className="cursor-pointer text-blue-800 underline text-sm"
                >
                  View
                </a>
              </p>
            ))}
          </div>
        )} */}
      </div>
    </GenericModal>
  );
};
