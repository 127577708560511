import { CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Button, Select, TextInput } from "../../../../UI";
import UploadFile from "../../../../components/UploadFile/UploadFile";
import { DigitizerContext } from "../../../../contexts/digitizer.context";
import { GeneralContext } from "../../../../contexts/general.context";
import { Months } from "../../../../utils/helper";
import classes from "./AlumniData.module.css";

const AlumniData = ({ handleBack, handleNext }) => {
  //local state
  const [userData, setUserData] = useState({});
  const [filteredGradYear, setFilteredGradYear] = useState([
    { key: 0, value: "Select Session", disabled: true },
  ]);
  const [courseDuration, setCourseDuration] = useState("");
  const [isPending, setIsPending] = useState(false);

  //global states and api functions from context
  const {
    getCountries,
    countries,
    getStates,
    states,
    cities,
    getCities,
    faculties,
    getFaculty,
    departments,
    getDepartment,
    programmes,
    getProgramme,
    sessions,
    getSession,
    selectedFile,
    setSelectedFile,
    titles,
    getTitle,
    genders,
    getGender,
  } = useContext(GeneralContext);

  const { updateStudent, studentData, matricNoFromVerifyStudent } =
    useContext(DigitizerContext);

  //calling api functions when component mounts to get dropdown options from context
  useEffect(() => {
    getCountries();
    getProgramme();
    getSession();
    getGender();
    getTitle();
    // eslint-disable-next-line
  }, []);

  //updating the students data(if already in existence) to the local state after verifying student and
  //setting the selectedFile with the studnets imageurl if he/she has a imageurl already
  useEffect(() => {
    setUserData(studentData);
    if (studentData.imageUrl) setSelectedFile(studentData.imageUrl);
    if (studentData.countryId) getStates(studentData.countryId);
    if (studentData.statesId) getCities(studentData.statesId);
    if (studentData.programmeId) getFaculty(studentData.programmeId);
    if (studentData.facultyId) {
      (async () => {
        const duration = await getDepartment(studentData.facultyId);
        setCourseDuration(duration);
      })();
    }
    if (studentData.admissionYear) filterYear(studentData.admissionYear);
    // eslint-disable-next-line
  }, [studentData]);

  //updating the students data with the his/her image url when the selectedFile state changes
  useEffect(() => {
    setUserData((prev) => ({
      ...prev,
      imageUrl: selectedFile,
    }));
    // eslint-disable-next-line
  }, [selectedFile]);

  //handle updating students data on input change
  const handleChange = async (e) => {
    const { name, value } = e.target;
    if (name === "dateOfBirth") {
      // console.log(value);
    }
    setUserData((prev) => ({ ...prev, [name]: value }));
    switch (name) {
      case "programmeId":
        getFaculty(value);
        break;
      case "countryId":
        getStates(value);
        break;
      case "statesId":
        getCities(value);
        break;
      case "facultyId":
        const duration = await getDepartment(value);
        setCourseDuration(duration);
        break;
      case "admissionYear":
        filterYear(value);
        break;

      default:
        return;
    }
  };

  //filter session with admission year and course duration to get graduation year array
  const filterYear = (value) => {
    const years = sessions.filter((year) => {
      const x = sessions.filter((y) => y.key === parseInt(value));
      const admissionYear = parseInt(x[0].value.split("/")[1]);
      const currentYear = parseInt(year.value.split("/")[1]);
      return (
        currentYear > admissionYear &&
        currentYear - admissionYear >= courseDuration
      );
    });
    setFilteredGradYear((prev) => [...prev, ...years]);
  };

  //handle validate of drop down inputs to amke sure its not default/placeholder value
  const validateInput = () => {
    if (
      !userData.gender ||
      !userData.title ||
      !userData.admissionYear ||
      !userData.admissionMonth ||
      !userData.graduatingYear ||
      !userData.graduatingMonth ||
      !userData.facultyId ||
      !userData.departmentId ||
      !userData.programmeId ||
      !userData.countryId ||
      !userData.statesId ||
      !userData.cityId
    ) {
      toast.error("Please select a valid option for each field ");
      return false;
    }
    return true;
  };

  //handle submit
  const onSubmit = async (e) => {
    e.preventDefault();
    if (validateInput()) {
      setIsPending(true);
      await updateStudent(userData);
      setIsPending(false);
      handleNext();
    } else {
      return;
    }
  };

  return (
    <div>
      <p> Alumni Data Verification</p>
      <form className={classes.double_column} onSubmit={onSubmit}>
        <div>
          <TextInput
            placeholder={"surname"}
            name={"lastName"}
            required
            onChange={handleChange}
            value={userData.lastName ? userData.lastName : ""}
          />

          <TextInput
            required
            placeholder={"first name"}
            name={"firstName"}
            onChange={handleChange}
            value={userData.firstName ? userData.firstName : ""}
          />

          <TextInput
            placeholder={"other name"}
            name={"otherName"}
            onChange={handleChange}
            value={userData.otherName ? userData.otherName : ""}
          />
          <div className={classes.double_column}>
            <Select
              placeholder="Gender"
              options={[
                {
                  key: "0",
                  value: "Gender",
                  // selected: true,
                  disabled: true,
                },
                ...genders,
              ]}
              required
              name={"gender"}
              onChange={handleChange}
              value={userData.gender ? userData.gender : 0}
            />
            <Select
              placeholder="Title"
              options={[
                {
                  key: "0",
                  value: "Title",
                  // selected: true,
                  disabled: true,
                },
                ...titles,
              ]}
              required
              name={"title"}
              onChange={handleChange}
              value={userData.title ? userData.title : 0}
            />
          </div>
          <div className={classes.double_column}>
            <TextInput
              placeholder={"date of birth"}
              required
              name={"dateOfBirth"}
              onChange={handleChange}
              onFocus={(e) => {
                e.target.type = "date";
              }}
              onBlur={(e) => {
                e.target.type = "text";
              }}
              value={
                userData.dateOfBirth ? userData.dateOfBirth.split("T")[0] : ""
              }
            />

            <Select
              inputClassName={classes.form__halfWidth}
              placeholder="Nationality"
              options={countries}
              name="countryId"
              onChange={handleChange}
              required
              value={userData.countryId ? userData.countryId : 0}
            />
          </div>
          <div className={classes.double_column}>
            <Select
              inputClassName={classes.form__halfWidth}
              placeholder="State of origin"
              options={states}
              name="statesId"
              onChange={handleChange}
              required
              value={userData.statesId ? userData.statesId : 0}
            />
            <Select
              inputClassName={classes.form__halfWidth}
              placeholder="City"
              options={cities}
              name="cityId"
              onChange={handleChange}
              required
              value={userData.cityId ? userData.cityId : 0}
            />
          </div>
        </div>
        <div>
          <TextInput
            placeholder={"matric number"}
            required
            name={"regNumber"}
            onChange={handleChange}
            value={
              userData.regNumber ?? matricNoFromVerifyStudent
                ? userData.regNumber ?? matricNoFromVerifyStudent
                : ""
            }
          />
          <div className={classes.double_column}>
            <div>
              <Select
                placeholder="Programme"
                options={programmes}
                required
                name={"programmeId"}
                onChange={handleChange}
                value={userData.programmeId ? userData.programmeId : 0}
              />
              <Select
                placeholder="Faculty"
                options={faculties}
                required
                name={"facultyId"}
                onChange={handleChange}
                value={userData.facultyId ? userData.facultyId : 0}
              />
              <Select
                placeholder="Department"
                options={departments}
                required
                name={"departmentId"}
                onChange={handleChange}
                value={userData.departmentId ? userData.departmentId : 0}
              />
            </div>
            <UploadFile />
          </div>
          <div className={classes.double_column}>
            <Select
              placeholder="Admission Year"
              options={sessions}
              required
              name={"admissionYear"}
              onChange={handleChange}
              value={userData.admissionYear ? userData.admissionYear : 0}
            />
            <Select
              placeholder="Admission Month"
              options={Months}
              required
              name={"admissionMonth"}
              onChange={handleChange}
              value={userData.admissionMonth ? userData.admissionMonth : 0}
            />
          </div>
          <div className={classes.double_column}>
            <Select
              placeholder="Graduation Year"
              options={filteredGradYear}
              required
              name={"graduatingYear"}
              onChange={handleChange}
              value={userData.graduatingYear ? userData.graduatingYear : 0}
            />
            <Select
              placeholder="Graduation Month"
              options={Months}
              required
              name={"graduatingMonth"}
              onChange={handleChange}
              value={userData.graduatingMonth ? userData.graduatingMonth : 0}
            />
          </div>

          <div style={{ display: "flex", gap: "10px" }}>
            <Button onClick={handleBack}>Back</Button>
            <Button type="submit">
              {isPending ? <CircularProgress /> : <span>Next </span>}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AlumniData;
