import React, { useContext, useEffect, useState } from "react";
import { ImSpinner3 } from "react-icons/im";
import { useDispatch } from "react-redux";

import { setGenericModalId } from "../../../Redux/features/modalsSlice";
import { Table } from "../../../components";
import GenericModal from "../../../components/Modals/GenericModal";
import { DigitizerContext } from "../../../contexts/digitizer.context";
import { RejectModal } from "../../Finance/RejectModal";

const TranscriptFlaggedRequest = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [reason, setReason] = useState([]);
  const [orderNo, setOrderNo] = useState();
  const [receiptUrl, setReceiptUrl] = useState();

  const dispatch = useDispatch();

  const {
    flaggedTranscripts,
    getFlaggedTranscripts,
    // markTranscriptAsResolved,
  } = useContext(DigitizerContext);

  useEffect(() => {
    getFlaggedTranscripts();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (flaggedTranscripts) setIsLoading(false);
  }, [flaggedTranscripts]);

  // const handleResolveButtonClick = async (orderNo, issueId) => {
  //   await markTranscriptAsResolved(orderNo, issueId);
  //   getFlaggedTranscripts();
  // };
  const handleRejectButtonClick = (orderNo) => {
    setOrderNo(orderNo);
    dispatch(setGenericModalId("FinanceRejectTranscript"));
  };

  const handleViewReason = (arg, orderNo, receiptUrl) => {
    const temp = arg.map((item) => ({
      date: item.createdAt,
      reason: item.reason,
      id: item.id,
    }));
    setReceiptUrl(receiptUrl);
    setOrderNo(orderNo);
    setReason(temp);
    dispatch(setGenericModalId("FlaggedTranscriptReason"));
  };

  const columns = [
    { value: "Serial ID" },
    { value: "Order Number" },
    { value: "Full Name" },
    { value: "Request Date" },
    { value: "Matric No." },
    { value: "Shipping Address" },
    { value: "Destination Email" },
    { value: "Reason" },
    { value: "Actions" },
  ];

  const data = flaggedTranscripts?.map((item, index) => ({
    "Serial ID": index + 1,
    "Order Number": item.requestOrderNumber,
    "Full Name": item.student.user.fullName,
    "Request Date": item.createdAt.split("T")[0],
    "Matric No.": item.regNumber,
    "Shipping Address": item.address1,
    "Destination Email": item.destinationEmail,
    Reason: (
      <p
        className="text-blue-600 underline cursor-pointer text-sm"
        onClick={() =>
          handleViewReason(
            item.audits,
            item.requestOrderNumber,
            item.transcriptDocument[0]?.clearanceORpaymentRecieptsUrl
          )
        }
      >
        View reason
      </p>
    ),
    // Resolve: (
    //   <button
    //     onClick={() =>
    //       handleResolveButtonClick(item.requestOrderNumber, item.audits[0].id)
    //     }
    //   >
    //     Resolve
    //   </button>
    // ),
    Reject: (
      <button onClick={() => handleRejectButtonClick(item.requestOrderNumber)}>
        Reject
      </button>
    ),
  }));

  return (
    <div className="grid w-full h-full">
      {isLoading ? (
        <ImSpinner3 className="animate-spin place-self-center" size={32} />
      ) : (
        <Table columns={columns} data={data} />
      )}
      <ReasonModal reason={reason} orderNo={orderNo} receiptUrl={receiptUrl} />
      <RejectModal refetch={() => {}} orderNumber={orderNo} />
    </div>
  );
};
export default TranscriptFlaggedRequest;

const ReasonModal = ({ reason, orderNo, receiptUrl }) => {
  const dispatch = useDispatch();
  const { getFlaggedTranscripts, markTranscriptAsResolved } =
    useContext(DigitizerContext);

  const handleResolveButtonClick = async (issueId) => {
    dispatch(setGenericModalId(null));
    await markTranscriptAsResolved(orderNo, issueId);
    getFlaggedTranscripts();
  };

  return (
    <GenericModal id="FlaggedTranscriptReason">
      <div className="my-4 max-w-[400px]">
        <a
          href={receiptUrl}
          className="text-blue-600 underline cursor-pointer text-sm"
          target="blank"
        >
          View Payment Receipt
        </a>
        {reason?.map((item) => (
          <div className="my-5">
            <p>
              {item.date.split("T")[0]}: {item.reason}
              <p
                className="cursor-pointer text-sm font-normal border border-zinc-700 rounded-sm px-2 py-1 bg-slate-600/30 w-fit"
                onClick={() => handleResolveButtonClick(item.id)}
              >
                Resolve
              </p>
            </p>
          </div>
        ))}
      </div>
    </GenericModal>
  );
};
