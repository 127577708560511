import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { PaymentGateway } from "../../../components";
import { GeneralContext } from "../../../contexts/general.context";
import { TranscriptRequestContext } from "../../../contexts/transcriptRequest";

export const PaymentModal = ({ onClose, modal }) => {
  const navigate = useNavigate();

  const {
    setSelectedFile,
    initializePaymentSchema,
    setInitializePaymentSchema,
  } = useContext(GeneralContext);

  const {
    transcriptInfo,
    setTranscriptInfo,

    setTranscriptOption,
    setCopyType,
    setRequestType,
    setTranscriptOrderNo,
  } = useContext(TranscriptRequestContext);

  const config = {
    email: transcriptInfo.destinationEmail,
  };

  const closeHandler = () => {
    onClose((prevStep) => prevStep - 1);
  };

  const handleSubmission = () => {
    navigate("/dashboard/home");

    //resetting transcript state logic
    setTranscriptInfo({});
    setTranscriptOption();
    setCopyType();
    setRequestType();
    setTranscriptOrderNo("");
    setSelectedFile("");
    setInitializePaymentSchema({});
  };

  return (
    <>
      <PaymentGateway
        onPayment={handleSubmission}
        onPrevious={closeHandler}
        config={config}
        modal={modal}
        initializePaymentObject={initializePaymentSchema}
      />
    </>
  );
};
