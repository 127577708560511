import { createApi } from "@reduxjs/toolkit/query/react";

import { baseURL } from "../../utils/globalConstant";
import { axiosBaseQuery } from "./axiosBaseQuery";

export const transcriptApi = createApi({
  reducerPath: "transcriptApi",
  baseQuery: axiosBaseQuery({
    baseUrl: baseURL,
  }),
  endpoints: (builder) => ({
    refreshTokens: builder.mutation({
      query: (payload) => ({
        url: "api/Auth/RefreshJWT",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useRefreshTokensMutation } = transcriptApi;

// baseQuery: fetchBaseQuery({
//   baseUrl: baseURL,
//   prepareHeaders: (headers) => {
//     const accessToken = getItemFromLocalStorage("accessToken");
//     if (accessToken) headers.set("Authorization", `Bearer ${accessToken}`);
//     return headers;
//   },
// }),
