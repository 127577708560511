import React, { useContext, useEffect, useState } from "react";
import { HiOutlineArrowSmLeft, HiOutlineArrowSmRight } from "react-icons/hi";

import { Button } from "../../../../UI";
import { DigitizerContext } from "../../../../contexts/digitizer.context";
import { GeneralContext } from "../../../../contexts/general.context";
import { CourseForm } from "./CourseForm";
import classes from "./Courses.module.css";

const Courses = ({ handleNext, handleBack, courseData, setCourseData }) => {
  //local states
  const [existingCourses, setExistingCourses] = useState([]);
  const [modalShown, setModalShown] = useState(false);

  //global states and api function sfrom context
  const {
    sessions,
    getSession,
    levels,
    getLevel,
    semesters,
    getSemester,
    gradeSystems,
    getGradeSystem,
    grades,
    getGrades,
  } = useContext(GeneralContext);

  const { userId, deleteCourse, studentCourses, getExistingCourses } =
    useContext(DigitizerContext);

  //getting existing courses with returned userId after successful update or creation of student
  useEffect(() => {
    getExistingCourses(userId);
    // eslint-disable-next-line
  }, [userId]);

  //setting the existing courses in the component when studentCourses is updated
  useEffect(() => {
    setExistingCourses(studentCourses);
    // eslint-disable-next-line
  }, [studentCourses]);

  //
  useEffect(() => {
    getSession();
    getLevel();
    getGradeSystem();
    getSemester();
    getGrades();

    // eslint-disable-next-line
  }, []);

  //handle edit
  const handleEdit = (courseRegistrationId) => {
    setModalShown(true);
    const filter = existingCourses.filter(
      (item) => item.courseRegistrationId === courseRegistrationId
    );
    setCourseData(filter[0]);
  };

  //handle delete
  const handleDelete = async (courseRegistrationId) => {
    await deleteCourse(courseRegistrationId);
    getExistingCourses(userId);
  };

  return (
    <div>
      {modalShown && (
        <CourseForm
          setModalShown={setModalShown}
          courseData={courseData}
          setCourseData={setCourseData}
        />
      )}
      <div className={classes.header}>
        <h2>Courses</h2>
        <Button
          onClick={() => {
            setModalShown(true);
          }}
        >
          Add
        </Button>
      </div>
      <table className={classes.courseTable}>
        <thead>
          <tr>
            <th>Academic Session</th>
            <th>Level of study</th>
            <th>Semester</th>
            <th>Course</th>
            <th>Grade</th>
            <th>Unit</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {existingCourses?.map((item) => (
            <tr key={item.courseRegistrationId}>
              <td>
                {
                  sessions.filter(
                    (session) => session.key === item.sessionId
                  )[0]?.value
                }
              </td>
              <td>
                {levels.filter((level) => level.key === item.levelId)[0]?.value}
              </td>
              <td>
                {
                  semesters.filter(
                    (semester) => semester.key === item.semesterId
                  )[0]?.value
                }
              </td>
              <td>{item.courseMapping.course.courseName}</td>
              <td>
                {grades.filter((grade) => grade.key === item.gradeId)[0]?.value}
              </td>
              <td>
                {
                  gradeSystems.filter(
                    (gradeSystem) => gradeSystem.key === item.gradeSystemId
                  )[0]?.value
                }
              </td>
              <td onClick={() => handleEdit(item.courseRegistrationId)}>
                Edit
              </td>
              <td onClick={() => handleDelete(item.courseRegistrationId)}>
                Delete
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={classes.buttonContainer}>
        <Button className={classes.backButton} onClick={handleBack}>
          <HiOutlineArrowSmLeft
            style={{
              fontSize: "16px",
              marginInline: "5px",
            }}
          />
          Back
        </Button>
        <Button onClick={handleNext}>
          Next
          <HiOutlineArrowSmRight
            style={{ fontSize: "16px", marginInline: "5px" }}
          />
        </Button>
      </div>
    </div>
  );
};

export default Courses;
