import TextField from "@mui/material/TextField";
import { forwardRef } from "react";

const TextInput = forwardRef(
  (
    {
      id,
      type,
      value,
      onChange,
      name,
      placeholder,
      required,
      pattern,
      title,
      onFocus,
      onBlur,
      maxLength,
      readOnly,
      multiline,
      helperText,
    },
    ref
  ) => {
    return (
      <TextField
        fullWidth
        id={id || name}
        name={name}
        label={placeholder}
        type={type || "text"}
        required={required}
        onChange={onChange}
        value={value}
        title={title}
        onFocus={onFocus}
        onBlur={onBlur}
        multiline={multiline || false}
        inputRef={ref}
        aria-readonly={readOnly}
        aria-valuemax={maxLength}
        helperText={helperText}
        inputProps={{ pattern: pattern }}
      />
    );
  }
);

export default TextInput;
