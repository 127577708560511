import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { GeneralContext } from "../../contexts/general.context";
import PaymentGateway from "../PaymentGateway/PaymentGateway";

export const PaymentModal = ({ onClose, modal }) => {
  const navigate = useNavigate();

  const { initializePaymentSchema, setInitializePaymentSchema } =
    useContext(GeneralContext);

  const closeHandler = () => {
    onClose((prevStep) => prevStep - 1);
  };

  const handleSubmission = () => {
    navigate("/dashboard/home");

    //resetting transcript state logic

    setInitializePaymentSchema({});
  };

  return (
    <>
      <PaymentGateway
        onPayment={handleSubmission}
        onPrevious={closeHandler}
        modal={modal}
        initializePaymentObject={initializePaymentSchema}
      />
    </>
  );
};
