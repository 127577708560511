import { useEffect, useState } from "react";
import { ImSpinner3 } from "react-icons/im";
import { useReactToPrint } from "react-to-print";

import {
  useGetFinanceTranscriptsSummaryQuery,
  useLazyGetAuditTrailTableTranscriptsQuery,
  useLazyGetTransactionTableTranscriptsQuery,
} from "../../Redux/services/endpoints/finance";
import { Table } from "../../components";
import classes from "./Home.module.css";

const ResultRequestType = {
  1: "First Time",
  2: "Reprint",
};

export const SuperFinance = () => {
  const [transactionFilter, setTransactionFilter] = useState({});
  const [auditTrailFilter, setAuditTrailFilter] = useState({});

  const { data, isLoading, isError } = useGetFinanceTranscriptsSummaryQuery();

  const [
    filterTransactions,
    {
      data: transactionData,
      isLoading: transactionLoading,
      isError: transactionError,
    },
  ] = useLazyGetTransactionTableTranscriptsQuery(true);
  const [
    filterAuditTrail,
    {
      data: auditTrailData,
      isLoading: auditTrailLoading,
      isError: auditTrailError,
    },
  ] = useLazyGetAuditTrailTableTranscriptsQuery(true);

  useEffect(() => {
    filterAuditTrail(auditTrailFilter);
    filterTransactions(transactionFilter);
    //eslint-disable-next-line
  }, []);

  //Transacation
  const handleTransactionFilterClear = () => {
    setTransactionFilter({});
    filterTransactions({});
  };
  const handleTransactionFilterSearch = () => {
    filterTransactions(transactionFilter);
  };
  const handleTransactionFilterInput = (e) => {
    const { name, value } = e.target;
    setTransactionFilter((prev) => ({ ...prev, [name]: value }));
  };

  //Audit trail
  const handleAuditTrailFilterSearch = () => {
    filterAuditTrail(auditTrailFilter);
  };
  const handleAuditTrailFilterClear = () => {
    setAuditTrailFilter({});
    filterAuditTrail({});
  };
  const handleAuditTrailFilterInput = (e) => {
    const { name, value } = e.target;

    setAuditTrailFilter((prev) => ({ ...prev, [name]: value }));
  };

  const handlePrint = useReactToPrint({
    content: () => document.getElementById("printable-table"),
    documentTitle: "Transaction Report",
  });

  const transactionColumns = [
    { value: "Serial ID" },
    { value: "Transcript Order Number" },
    { value: "Student Name" },
    { value: "Matric No." },
    { value: "Request Date" },
    { value: "Amount" },
    { value: "Request type" },
    { value: "Reference" },
  ];

  const transaction = transactionData?.data?.records?.map((item, index) => ({
    "Serial ID": index + 1,
    "Transcript Order Number": item.requestOrderNumber,
    "Student Name": item.fullName,
    "Matric No.": item.regNumber,
    "Request Date": item.createdAt.split("T")[0],
    Amount: item.amount,
    "Request type": ResultRequestType[item.resultRequestType],
    Reference: item.referenceNo,
  }));

  const auditTrailColumns = [
    { value: "Serial ID" },
    { value: "Transcript Order Number" },
    { value: "Student Name" },
    { value: "Approver Name" },
    { value: "Request Date" },
  ];

  const auditTrail = auditTrailData?.data?.records?.map((item, index) => ({
    "Serial ID": index + 1,
    "Transcript Order Number": item.requestOrderNumber,
    "Student Name": item.transcriptRequest.student.user.fullName,
    "Approver Name": item.performedBy,
    "Request Date": item.transcriptRequest.createdAt.split("T")[0],
  }));

  return (
    <div className={`${isLoading ? "grid" : "flex flex-col gap-10"} w-full`}>
      {isLoading || transactionLoading || auditTrailLoading ? (
        <ImSpinner3 className="animate-spin place-self-center" size={32} />
      ) : isError || transactionError || auditTrailError ? (
        <p>Something went wrong please try agaain</p>
      ) : (
        <>
          <div>
            <div className={classes.cardContainer}>
              <div className={classes.card}>
                <p className={classes.card__title}>
                  Total transcript requests processed
                </p>
                <h4>{data?.data.totalTranscriptsProcessed}</h4>
              </div>
              <div className={classes.card}>
                <p className={classes.card__title}>
                  Total transcript request payment received
                </p>
                <h4>₦{data?.data.totalPaymentsReceived}</h4>
              </div>
              <div className={classes.card}>
                <p className={classes.card__title}>First Time Transcripts</p>
                <h4>Amount: {data?.data.totalFirstTimePaymentsReceived}</h4>
                <h4>
                  Total Count: {data?.data.totalFirstTimeTranscriptsProcessed}
                </h4>
              </div>
              <div className={classes.card}>
                <p className={classes.card__title}>Reprinted Transcripts</p>
                <h4>Amount: {data?.data.totalReprintPaymentsReceived}</h4>
                <h4>
                  Total Count: {data?.data.totalReprintTranscriptsProcessed}
                </h4>
              </div>
            </div>
          </div>
          <div className="overflow-y-auto flex-shrink min-h-0">
            <form className="flex flex-col gap-3 bg-white rounded-md p-4 w-fit">
              <h2>Filter Transactions</h2>
              <div className="flex gap-2">
                <div className="flex flex-col gap-1">
                  <label htmlFor="transacStart">Select start date</label>
                  <input
                    className="p-2 rounded-sm border border-black"
                    type="date"
                    value={transactionFilter?.StartDate || ""}
                    name="StartDate"
                    id="transacStart"
                    onChange={handleTransactionFilterInput}
                    required
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="transacEnd">Select end date</label>
                  <input
                    className="p-2 rounded-sm border border-black"
                    type="date"
                    value={transactionFilter?.EndDate || ""}
                    name="EndDate"
                    id="transacEnd"
                    onChange={handleTransactionFilterInput}
                    required
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="resultType">Select Result Type</label>
                  <select
                    className="p-2 rounded-sm border border-black"
                    name="ResultRequestType"
                    id="resultType"
                    value={transactionFilter?.ResultRequestType || 0}
                    onChange={handleTransactionFilterInput}
                  >
                    <option value="0" disabled>
                      Select result type
                    </option>
                    <option value="1">First Time</option>
                    <option value="2">Reprint</option>
                  </select>
                </div>
              </div>
              <div className="flex gap-3">
                <button
                  className="border bg-blue-700 p-2 rounded-md text-white"
                  type="button"
                  onClick={handleTransactionFilterSearch}
                >
                  Filter
                </button>
                <button
                  className="border border-blue-700 p-2 rounded-md"
                  type="button"
                  onClick={handleTransactionFilterClear}
                >
                  Clear
                </button>
              </div>
            </form>
            <div className="flex flex-col mt-4 ">
              <button
                className="ml-auto border bg-blue-700 p-2 rounded-md text-white"
                onClick={handlePrint}
              >
                Print Table
              </button>
              <Table
                id="printable-table"
                columns={transactionColumns}
                data={transaction}
                title="Transactions"
              />
            </div>
          </div>
          <div className="overflow-y-auto flex-shrink min-h-0">
            <form className="flex flex-col gap-3 bg-white rounded-md p-4 w-fit">
              <h2>Filter Transactions</h2>
              <div className="flex gap-2">
                <div className="flex flex-col gap-1">
                  <label htmlFor="StudentName">Enter Student name</label>
                  <input
                    className="p-2 rounded-sm border border-black"
                    type="text"
                    value={auditTrailFilter?.StudentName || ""}
                    name="StudentName"
                    id="StudentName"
                    onChange={handleAuditTrailFilterInput}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="RequestOrderNo">
                    Enter Request Order Number
                  </label>
                  <input
                    className="p-2 rounded-sm border border-black"
                    type="text"
                    value={auditTrailFilter?.RequestOrderNo || ""}
                    name="RequestOrderNo"
                    id="RequestOrderNo"
                    onChange={handleAuditTrailFilterInput}
                  />
                </div>
              </div>
              <div className="flex gap-3">
                <button
                  className="border bg-blue-700 p-2 rounded-md text-white"
                  type="button"
                  onClick={handleAuditTrailFilterSearch}
                >
                  Filter
                </button>
                <button
                  className="border border-blue-700 p-2 rounded-md"
                  type="button"
                  onClick={handleAuditTrailFilterClear}
                >
                  Clear
                </button>
              </div>
            </form>
            <Table
              columns={auditTrailColumns}
              data={auditTrail}
              title="Audit Trail"
            />
          </div>
        </>
      )}
    </div>
  );
};
